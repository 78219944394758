import React, { Component } from "react";
import PropTypes from "prop-types";

import "../styles/sidebar.css";

class IconConferenceLock extends Component {
    render() {
        const { globalLock, onClick, label } = this.props;
        return (
            <span
                className={`conference-control-action__lock-inline ${
                    globalLock
                        ? "conference-control-action__lock-inline--on"
                        : "conference-control-action__lock-inline--off"
                }`}
                onClick={onClick}
                role="button"
                aria-label={label}
                tabIndex="0"
            >
                <svg
                    version="1.1"
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 24 24"
                    xmlSpace="preserve"
                >
                    <g>
                        <path
                            d="M18.5,11.2h-13V5.5C5.5,2.5,8,0,11,0h2c3,0,5.5,2.5,5.5,5.5V11.2z M6.5,10.2h11V6.5C17.5,3.5,15,1,12,1l0,0
                C9,1,6.5,3.5,6.5,6.5V10.2z"
                        />
                    </g>
                    <path
                        d="M20.1,9H3.9C2.9,9,2,9.8,2,10.9v11.2c0,1,0.9,1.9,1.9,1.9h16.2c1.1,0,1.9-0.8,1.9-1.9V10.9
            C22,9.8,21.1,9,20.1,9z M13,16.7V20c0,0.5-0.5,1-1,1s-1-0.5-1-1v-3.3c-0.6-0.3-1-1-1-1.7c0-1.1,0.9-2,2-2s2,0.9,2,2
            C14,15.7,13.6,16.4,13,16.7z"
                    />
                    <path d="M12.5,0h-1C8.5,0,6,2.5,6,5.5V12h12V5.5C18,2.5,15.5,0,12.5,0z M16,10H8V6c0-2.2,1.8-4,4-4s4,1.8,4,4V10z" />
                </svg>
            </span>
        );
    }
}

IconConferenceLock.propTypes = {
    globalLock: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
};

export default IconConferenceLock;
