import React from "react";
import PropTypes from "prop-types";

import "./selectContent.css";

const SelectContent = ({ show, selectLabel, selectOnChange, selectContent }) =>
    show ? (
        <div className="inputblock__child">
            <div className="select-content__label">{selectLabel}</div>
            <select onChange={selectOnChange}>{selectContent}</select>
        </div>
    ) : null;

SelectContent.defaultProps = {
    show: true,
};

SelectContent.propTypes = {
    show: PropTypes.bool,
    selectLabel: PropTypes.string.isRequired,
    selectOnChange: PropTypes.func.isRequired,
    selectContent: PropTypes.array.isRequired,
};

export default SelectContent;
