import React from "react";
import { connect } from "react-redux";
import Participant from "../../components/Participant";
import DropDownMenu from "./DropDownMenu";
import DropDownMenuEntryRename from "./DropDownMenuEntryRename";
import DropDownMenuEntryMakePresenter from "./DropDownMenuEntryMakePresenter";

const mapStateToProps = (state, ownProps) => ({
    host: ownProps.p.host,
    presenting: ownProps.p.presenting,
    onHold: false,
    mute: false,
    talking: false,
    titleText: ownProps.p.name,
    presentationActive:
        state.webRtc.remoteStream === "ready" || (state.webRtc.localStream === "ready" && state.webRtc.captureStarted),
    showPresenterSymbol: state.login.allowScreenshare && ownProps.p.canScreenshare,
    dropdownmenu:
        state.login.isPresenter ||
        (!(ownProps.p.host && ownProps.p.presenting) && ownProps.p.uid === state.login.uid) ? (
            <DropDownMenu
                children={[
                    ownProps.p.uid === state.login.uid ? (
                        <DropDownMenuEntryRename
                            key="rename"
                            selectedUserName={ownProps.p.name}
                            selectedUID={ownProps.p.uid}
                        />
                    ) : null,
                    state.login.allowScreenshare &&
                    state.login.isPresenter &&
                    !ownProps.p.presenting &&
                    ownProps.p.canScreenshare ? (
                        <DropDownMenuEntryMakePresenter
                            key="makepresenter"
                            selectedUserName={ownProps.p.name}
                            selectedUID={ownProps.p.uid}
                        />
                    ) : null,
                ]}
                direction="down"
            />
        ) : (
            ""
        ),
});

const ParticipantWeb = connect(mapStateToProps)(Participant);

export default ParticipantWeb;
