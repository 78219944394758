import { connect } from "react-redux";
import StartSharingButton from "../../components/StartSharingButton";
import { startSharing } from "../../actions";

const mapStateToProps = (state, ownProps) => ({
    greyOut: ownProps.greyOut,
    title: ownProps.title,
    sessionId: state.login.sessionId,
    type: ownProps.type,
});

const mapDispatchToProps = {
    onClick: (sessionId, type) => startSharing(sessionId, type),
};

const StartSharingDefault = connect(mapStateToProps, mapDispatchToProps)(StartSharingButton);

export default StartSharingDefault;
