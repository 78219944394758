import React from "react";
import PropTypes from "prop-types";

const DropDownMenuEntry = ({ text, mouseDown, showOverlay }) => (
    <div className="dropdown-content-entry" onMouseDown={showOverlay ? null : mouseDown}>
        {showOverlay ? (
            <div className="dropdown-content-entry__grey-out">
                <div className="dropdown-content-entry__grey-out-image" />
            </div>
        ) : null}
        <div className="dropdown-content-entry__text">{text}</div>
    </div>
);

DropDownMenuEntry.defaultProps = {
    showOverlay: false,
};

DropDownMenuEntry.propTypes = {
    text: PropTypes.string.isRequired,
    mouseDown: PropTypes.func.isRequired,
    showOverlay: PropTypes.bool,
};

export default DropDownMenuEntry;
