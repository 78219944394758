import { connect } from "react-redux";
import VideoView from "../../components/VideoView";
import streamContainer from "../../lib/StreamContainer";

const mapStateToProps = (state) => ({
    show: state.webRtc.localStream === "ready",
    className: "screenPreview",
    mediaStream: streamContainer.localStream,
});

const LocalVideoView = connect(mapStateToProps)(VideoView);

export default LocalVideoView;
