/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { LOGIN_BOX } from "../../constants/modalConstants";

import Modal from "../../components/modal/Modal";
import SimpleDecissionButtons from "../basics/buttons/SimpleDecissionButtons";
import { writeToSocket, resetLogin, setLoginError, setModalVisibility, setPIN, setPinVisibility } from "../../actions";
import { makeLoginJson, canScreenshare, canReceiveWebRtc, acceptBlankDownloadAppText } from "../../lib/utils";
import { translate } from "../../lib/Translator";

import "../../styles/layout.css";
import "../../styles/main.css";
import styles from "./loginBox.module.css";

const LoginBoxComponent = ({
    show,
    title,
    errorMsg,
    isOperator,
    isInfo,
    participantName,
    login,
    loginNameText,
    modPinText,
    partPinText,
    modPinInfoText,
    partPinInfoText,
    abortText,
    saveText,
    errorCode,
    pinShown,
    noScreenshareText1,
    noScreenshareText2,
    pin,
    onClose,
    doLogin,
    onClickPinVisiblity,
}) => {
    const showNoScreenshareWarning = !canScreenshare() || !canReceiveWebRtc();
    let pinInput = null;
    let nameInput = null;
    if (login) {
        return null;
    }
    const error = errorMsg;
    if (error !== null && document.getElementById("start") !== null) {
        document.getElementById("start").removeAttribute("disabled");
    }
    return (
        <Modal
            show={show}
            onClose={onClose}
            title={title}
            contentChildren={
                <div>
                    <form
                        onSubmit={(e) => {
                            document.getElementById("start").setAttribute("disabled", "disabled");
                            e.preventDefault();
                            let name = participantName;
                            if (nameInput) {
                                name = nameInput.value.trim();
                                if (name !== "") name = nameInput.value;
                            }
                            doLogin(pinInput.value, isOperator, name);
                            return false;
                        }}
                        ref={(form) => form}
                    >
                        {showNoScreenshareWarning ? (
                            <div className="messagebox warning pre-line">
                                {noScreenshareText1} {acceptBlankDownloadAppText()} {noScreenshareText2}
                            </div>
                        ) : null}
                        <div
                            className={`messagebox ${isInfo ? "info" : "error"} pre-line ${error ? "" : " hidden"} ${
                                showNoScreenshareWarning ? "margin-top" : ""
                            }`}
                        >
                            {error}
                        </div>
                        {!isOperator ? (
                            <div className="inputblock inputblock--big" style={{ width: "472px" }}>
                                <span>{loginNameText}</span>
                                <input
                                    className="input-field input-field--text"
                                    ref={(node) => {
                                        nameInput = node;
                                    }}
                                    type="text"
                                    name="participantName"
                                />
                            </div>
                        ) : null}
                        <div className="creds-input">
                            <div className="creds-input__pin-label">{isOperator ? modPinText : partPinText}</div>
                            <div className="creds-input__info-icon">
                                <span className="creds-input__tooltip">
                                    {isOperator ? modPinInfoText : partPinInfoText}
                                </span>
                            </div>
                            <div className="l-tab-row">
                                <div className="l-tab__item-grow">
                                    <input
                                        className={styles["c-pw-input"]}
                                        key={errorCode === "host" ? "pinInputfromMultiMod" : "pinInput"}
                                        ref={(node) => {
                                            pinInput = node;
                                        }}
                                        type={pinShown ? "text" : "password"}
                                        name="pin"
                                        onKeyUp={(e) => {
                                            if (e.keyCode === 13) {
                                                // FF hack
                                                // Do nothing, otherwise the event is fired twice
                                                // document.getElementById("start").click();
                                            }
                                        }}
                                        defaultValue={pin}
                                    />
                                </div>
                                <div className={styles["c-pw-vis"]}>
                                    <div className="l-center-xy l-max-size">
                                        <div
                                            className={`${styles["c-pw-vis__icon"]} ${
                                                pinShown
                                                    ? styles["c-pw-vis__icon--hide"]
                                                    : styles["c-pw-vis__icon--show"]
                                            }`}
                                            onClick={() => onClickPinVisiblity(!pinShown)}
                                            role="button"
                                            tabIndex="0"
                                        />
                                    </div>
                                </div>
                            </div>
                            <input className="input-field input-field--submit" id="start" type="submit" />
                        </div>
                    </form>
                </div>
            }
            buttonChildren={
                <SimpleDecissionButtons
                    abortClick={onClose}
                    acceptClick={() => {
                        // FF hack
                        document.getElementById("start").click();
                    }}
                    abortText={abortText}
                    acceptText={saveText}
                />
            }
        />
    );
};

LoginBoxComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    errorMsg: PropTypes.string.isRequired,
    isOperator: PropTypes.bool.isRequired,
    isInfo: PropTypes.bool.isRequired,
    participantName: PropTypes.string.isRequired,
    login: PropTypes.bool.isRequired,
    loginNameText: PropTypes.string.isRequired,
    modPinText: PropTypes.string.isRequired,
    partPinText: PropTypes.string.isRequired,
    modPinInfoText: PropTypes.string.isRequired,
    partPinInfoText: PropTypes.string.isRequired,
    abortText: PropTypes.string.isRequired,
    saveText: PropTypes.string.isRequired,
    errorCode: PropTypes.string.isRequired,
    noScreenshareText1: PropTypes.string.isRequired,
    noScreenshareText2: PropTypes.string.isRequired,
    pinShown: PropTypes.bool.isRequired,
    pin: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    doLogin: PropTypes.func.isRequired,
    onClickPinVisiblity: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    show: state.modal.modalId === LOGIN_BOX,
    title: state.login.isOperator ? translate("Login.StartConf") : translate("Login.PartConf"),
    errorMsg: state.modal.errorMsg,
    isOperator: state.login.isOperator,
    isInfo: state.modal.isInfo,
    participantName: state.login.participantName,
    login: state.login.login,
    loginNameText: translate("Login.Name"),
    modPinText: translate("Login.ModPin"),
    partPinText: translate("Login.PartPin"),
    modPinInfoText: translate("Login.ModLoginInfo"),
    partPinInfoText: translate("Login.PartLoginInfo"),
    abortText: translate("Button.Abort"),
    saveText: translate("Button.Start"),
    errorCode: state.modal.errorCode,
    noScreenshareText1: translate("Login.NoScreenshareWarning1"),
    noScreenshareText2: translate("Login.NoScreenshareWarning2"),
    pinShown: state.login.pinShown,
    pin: state.login.pin,
});

const isNameValid = (isOperator, participantName) => isOperator || participantName.length >= 2;

const doLogin = (dispatch, pin, isOperator, participantName) => {
    if (!isNameValid(isOperator, participantName)) {
        dispatch(setLoginError(translate("Error.InvalidName")));
        return;
    }
    dispatch(setPIN(pin));
    dispatch(
        writeToSocket(makeLoginJson(isOperator ? "start" : "join", pin, participantName.trim(), "not-registered"))
    );
};

const onClose = (dispatch) => {
    dispatch(resetLogin());
    dispatch(setModalVisibility(""));
};

const mapDispatchToProps = (dispatch) => ({
    onClickPinVisiblity: (option) => dispatch(setPinVisibility(option)),
    onClose: () => onClose(dispatch),
    doLogin: (pin, isOperator, participantName) => doLogin(dispatch, pin, isOperator, participantName),
});

const LoginBox = connect(mapStateToProps, mapDispatchToProps)(LoginBoxComponent);

export default LoginBox;
