import { connect } from "react-redux";

import ButtonContent from "../../../components/modal/ButtonContent";
import { setModalVisibility } from "../../../actions";
import { translate } from "../../../lib/Translator";

const mapStateToProps = (state, ownProps) => ({
    abortText:
        ownProps.abortText !== null && ownProps.abortText !== undefined ? ownProps.abortText : translate("Button.No"),
    acceptText:
        ownProps.acceptText !== null && ownProps.acceptText !== undefined
            ? ownProps.acceptText
            : translate("Button.Yes"),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    abortClick:
        ownProps.abortClick !== null && ownProps.abortClick !== undefined
            ? ownProps.abortClick
            : () => {
                  dispatch(setModalVisibility(""));
              },
    acceptClick: () => {
        ownProps.acceptClick(dispatch);
        if (ownProps.additionalFunc !== null && ownProps.additionalFunc !== undefined) {
            ownProps.additionalFunc(dispatch);
        }
    },
});

const SimpleDecissionButtons = connect(mapStateToProps, mapDispatchToProps)(ButtonContent);

export default SimpleDecissionButtons;
