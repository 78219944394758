import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import IconConferenceLock from "../../inlineSvgs/IconConferenceLock";
import IconConferenceMute from "../../inlineSvgs/IconConferenceMute";
import IconConferenceRecording from "../../inlineSvgs/IconConferenceRecording";

import { writeToSocket } from "../../actions";
import { translate } from "../../lib/Translator";

import "./conferenceControl.css";
import "../../styles/main.css";

const ConferenceControlComponent = ({
    show,
    globalMute,
    globalLock,
    globalRecording,
    muteFn,
    lockFn,
    recordingFn,
    allMicsOn,
    allMicsOff,
    unlockConference,
    lockConference,
    stopRecording,
    startRecording,
    product,
}) => {
    if (!show) return null;
    const conferneceControlActionSpacing =
        product.toLowerCase() === "meetgreen" ? "conference-control-action--half" : "conference-control-action--third";

    return (
        <div className="conference-control">
            <div className={`conference-control-action ${conferneceControlActionSpacing} tooltip`}>
                <div className="tooltiptext tooltiptext--right tooltip__text--conf-control-adjustment">
                    {globalMute ? allMicsOn : allMicsOff}
                </div>
                <IconConferenceMute
                    globalMute={globalMute}
                    onClick={muteFn}
                    label={globalMute ? allMicsOn : allMicsOff}
                />
            </div>
            {product.toLowerCase() === "meetgreen" ? null : (
                <div className="conference-control-action conference-control-action--third tooltip">
                    <div className="tooltiptext tooltiptext--right tooltip__text--conf-control-adjustment">
                        {globalRecording ? stopRecording : startRecording}
                    </div>
                    <IconConferenceRecording
                        globalRecording={globalRecording}
                        onClick={recordingFn}
                        label={globalRecording ? stopRecording : startRecording}
                    />
                </div>
            )}
            <div className={`conference-control-action ${conferneceControlActionSpacing} tooltip`}>
                <div className="tooltiptext tooltiptext--right tooltip__text--conf-control-adjustment">
                    {globalLock ? unlockConference : lockConference}
                </div>
                <IconConferenceLock
                    globalLock={globalLock}
                    onClick={lockFn}
                    label={globalLock ? unlockConference : lockConference}
                />
            </div>
        </div>
    );
};

ConferenceControlComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    globalMute: PropTypes.bool.isRequired,
    globalLock: PropTypes.bool.isRequired,
    globalRecording: PropTypes.bool.isRequired,
    muteFn: PropTypes.func.isRequired,
    lockFn: PropTypes.func.isRequired,
    recordingFn: PropTypes.func.isRequired,
    allMicsOn: PropTypes.string.isRequired,
    allMicsOff: PropTypes.string.isRequired,
    stopRecording: PropTypes.string.isRequired,
    startRecording: PropTypes.string.isRequired,
    unlockConference: PropTypes.string.isRequired,
    lockConference: PropTypes.string.isRequired,
    product: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    show:
        state.login.isOperator &&
        state.conference.globalMute !== null &&
        state.conference.globalMute !== undefined &&
        state.conference.globalLock !== null &&
        state.conference.globalLock !== undefined &&
        state.conference.globalRecording !== null &&
        state.conference.globalRecording !== undefined,
    globalMute: state.conference.globalMute,
    globalLock: state.conference.globalLock,
    globalRecording: state.conference.globalRecording,
    allMicsOn: translate("ConfControl.AllMicsOn"),
    allMicsOff: translate("ConfControl.AllMicsOff"),
    unlockConference: translate("ConfControl.UnlockConference"),
    lockConference: translate("ConfControl.LockConference"),
    stopRecording: translate("ConfControl.StopRecording"),
    startRecording: translate("ConfControl.StartRecording"),
    product: state.telco.product,
});

const toggleMute = () => (dispatch, getState) => {
    dispatch(
        writeToSocket({
            cmd: "muteConference",
            mute: !getState().conference.globalMute,
        })
    );
};
const toggleLock = () => (dispatch, getState) => {
    dispatch(
        writeToSocket({
            cmd: "lockConference",
            lock: !getState().conference.globalLock,
        })
    );
};
const toggleRecording = () => (dispatch, getState) => {
    dispatch(
        writeToSocket({
            cmd: "recordConference",
            record: !getState().conference.globalRecording,
        })
    );
};

const mapDispatchToProps = {
    muteFn: toggleMute,
    lockFn: toggleLock,
    recordingFn: toggleRecording,
};

const ConferenceControl = connect(mapStateToProps, mapDispatchToProps)(ConferenceControlComponent);

export default ConferenceControl;
