import { connect } from "react-redux";

import DropDownMenuEntry from "../../components/DropDownMenuEntry";
import { RENAME_PARTICIPANT_BOX } from "../../constants/modalConstants";
import { setModalVisibility, setSelectedUser } from "../../actions";
import { translate } from "../../lib/Translator";

const mapStateToProps = () => ({
    show: true,
    text: translate("DropDownMenu.ChangeName"),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    mouseDown: () => {
        dispatch(setModalVisibility(RENAME_PARTICIPANT_BOX));
        dispatch(setSelectedUser(ownProps.selectedUserName, ownProps.selectedUID));
    },
});

const DropDownMenuEntryRename = connect(mapStateToProps, mapDispatchToProps)(DropDownMenuEntry);

export default DropDownMenuEntryRename;
