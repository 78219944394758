import { ELECTRON_SELECT_BOX, ERROR_BOX } from "../constants/modalConstants";
import { checkBrowser } from "./utils";
import WebRtcHandler from "./WebRtcHandler";

import {
    writeToSocket,
    setStreamState,
    setCaptureState,
    setModalVisibility,
    setCaptureType,
    setErrorMessage,
    setErrorImgCls,
    setConnectionQuality,
    setErrorTitle,
    setErrorIsInfo,
    setElectronSources,
} from "../actions";

const ERROR_MSG = "Error.ConnectionProblem";

class WebRtcEventHandler {
    constructor(store) {
        this.store = store;

        this._statsFn = null;
        this.isInit = false;
        this.webRTCRetries = [];
        this.retried = false;
        this.remoteStreamState = "stop";
    }

    sendStats = (dispatch, sessionId, stats) => {
        dispatch(
            writeToSocket({
                cmd: "stats",
                sessionId,
                stats,
            })
        );
    };

    setConnQuality = (qual) => {
        this.store.dispatch(setConnectionQuality(qual));
    };

    get electronSources() {
        if (this.webRtcHandler !== null && this.webRtcHandler !== undefined) {
            return this.webRtcHandler.electronSourcesObj;
        }
        return [];
    }

    initWebRtcHandler = () => {
        const { sessionId, isPresenter } = this.store.getState().login;
        const { turn } = this.store.getState().webRtc;

        this.stopStreams();
        this.webRtcHandler = new WebRtcHandler(
            turn,
            isPresenter,
            (stats) => {
                this.sendStats(this.store.dispatch, sessionId, stats);
            },
            (qual) => {
                this.setConnQuality(qual);
            }
        );
    };

    initCapture = (sessionId, mediaSource) => {
        this.initWebRtcHandler();
        this.webRtcHandler
            .initCapture(
                () => {
                    this.store.dispatch(
                        writeToSocket({
                            cmd: "stop",
                            sessionId,
                        })
                    );
                },
                mediaSource,
                this.electronSelectFn
            )
            .then((st) => {
                this.store.dispatch(setCaptureState("localStream", st.localStream, st.captureStarted));
                this.store.dispatch(
                    writeToSocket({
                        cmd: "startSharing",
                        sessionId,
                    })
                );
            })
            .catch((reason) => {
                this.handleCaptureError(reason);
            });
    };

    electronSelectFn = (type, sources) => {
        this.store.dispatch(setModalVisibility(ELECTRON_SELECT_BOX));
        this.store.dispatch(setCaptureType(type));
        this.store.dispatch(setElectronSources(sources));
    };

    stopCapture = (sessionId) => {
        if (this.webRtcHandler !== null && this.webRtcHandler !== undefined) {
            this.webRtcHandler.resetProcessStats();
            this.webRtcHandler.stopCapture().then(() => {
                this.store.dispatch(
                    writeToSocket({
                        cmd: "stop",
                        sessionId,
                    })
                );
            });
        }
    };

    // stream initiator
    handleSdpOffer = (data) => {
        const { sessionId } = this.store.getState().login;
        if (data.success) {
            this.webRtcHandler
                .handleSdpOffer(data, this._retrySdpOffer)
                .then((sessionDescription) => {
                    this.store.dispatch(
                        writeToSocket({
                            cmd: "sdpAnswer",
                            sessionId,
                            answer: sessionDescription.sdp,
                        })
                    );
                })
                .catch((reason) => {
                    console.log("handleSdpOffer: %o", reason);
                    this._handleRTCError(reason);
                });
        } else {
            this.webRtcHandler.stopCapture();
            this._showError(ERROR_MSG);
        }
    };

    _retrySdpOffer = () => {
        const { sessionId } = this.store.getState().login;
        // check number of retries in last 5 minutes
        const ts = new Date().getTime();
        let retryCount = 0;
        this.webRTCRetries.forEach((r) => {
            if (r > ts - 300000) retryCount += 1;
        });
        console.log("number of retries " + retryCount);
        if (retryCount > 2) {
            this._stopCapture();
            // TODO: implement
            this._showError("Error.ConnectionAbort");
            return;
        }
        this.webRTCRetries.push(ts);
        this.store.dispatch(
            writeToSocket({
                cmd: "startSharing",
                sessionId,
            })
        );
        this.retried = true;
    };

    // stream joiner
    handleStreamOpen = (data) => {
        const { isPresenter } = this.store.getState().login;
        if (!isPresenter) {
            console.log("WebRtcEventHandler handleStreamOpen data: %o", data);
            if (data !== null && data !== undefined && data.success === true) {
                this.initWebRtcHandler();
                this.webRtcHandler
                    .joinStream(this._retryJoinStream, this._remoteStreamStateCallback)
                    .then((sessionDescription) => {
                        this.store.dispatch(
                            writeToSocket({
                                cmd: "joinSharing",
                                offer: sessionDescription.sdp,
                            })
                        );
                    })
                    .catch((reason) => {
                        this._handleRTCError(reason);
                    });
            }
        } else if (data !== null && data !== undefined && data.success === false) {
            // only the presenter should see error messages when he initiates a stream
            this._showError(ERROR_MSG);
        }
    };

    _remoteStreamStateCallback = (remoteStreamState) => {
        console.log("_remoteStreamStateCallback %o", remoteStreamState);
        this.remoteStreamState = remoteStreamState;
        this.store.dispatch(setStreamState("remoteStream", remoteStreamState));
    };

    _retryJoinStream = () => {
        if (this.remoteStreamState === "stop") return;
        this.remoteStreamState = "stop";
        this.store.dispatch(setStreamState("remoteStream", "stop"));
        setTimeout(() => this.handleStreamOpen(), 3000);
    };

    // stream joiner
    handleSdpAnswer = (data) => {
        const { isPresenter } = this.store.getState().login;
        console.log("_handleSdpAnswer data: %o", data);
        if (!isPresenter) {
            if (data !== null && data !== undefined && data.success === true) {
                this.webRtcHandler.handleSdpAnswer(data).catch((reason) => {
                    this._handleRTCError(reason);
                });
            }
        } else if (data.success === false) {
            // only the presenter should see error messages when he initiates a stream
            this._showError(ERROR_MSG);
        }
    };

    _stopCapture = () => {
        this.webRtcHandler.stopCapture().then(() => {
            this.retried = false;
            this.webRTCRetries = [];
            this._stopStream();
        });
    };

    _stopStream = () => {
        const { sessionId } = this.store.getState().login;
        this.store.dispatch(
            writeToSocket({
                cmd: "stop",
                sessionId,
            })
        );
    };

    handleStopStream = () => {
        if (this.webRtcHandler !== null && this.webRtcHandler !== undefined) {
            this.webRtcHandler.resetProcessStats();
            this.webRtcHandler.handleStopStream().then(() => {
                if (!this.retried) {
                    this.store.dispatch(setCaptureState("remoteStream", "stop", false));
                }
            });
            this.store.dispatch(setCaptureState("remoteStream", "stop", false));
            this.store.dispatch(setStreamState("localStream", "stop"));
        }
    };

    handlePresentationOver = () => {
        if (this.webRtcHandler !== null && this.webRtcHandler !== undefined) {
            const { captureStarted } = this.store.getState().webRtc;
            this.webRtcHandler.handlePresentationOver(captureStarted);
            this.webRtcHandler.resetProcessStats();
            this.webRtcHandler = null;
            this.isInit = false;
        }
    };

    electronGetUserMedia = (electronIndexToCapture) => {
        if (this.webRtcHandler !== null && this.webRtcHandler !== undefined) {
            this.webRtcHandler.electronGetUserMedia(electronIndexToCapture);
        }
    };

    handleCaptureError = (reason) => {
        console.log("reason: %o", reason);
        let error = false;
        this.store.dispatch(setCaptureState("localStream", "stop", false));

        // chrome: NavigatorUserMediaError
        // Firefox: MediaStreamError
        if (reason.name === "InvalidStateError" || reason.name === "NotAllowedError") {
            this.store.dispatch(setErrorMessage("Error.AllowBrowserAccess"));
            error = true;
        }
        const b = checkBrowser();
        if (b.isFirefox) {
            this.store.dispatch(setErrorImgCls("firefoxAllowCapture"));
            this.store.dispatch(setErrorMessage("Error.AllowBrowserAccess"));
            this.store.dispatch(setErrorTitle("Error.AccessDeniedTitle"));
            this.store.dispatch(setErrorIsInfo(true));
            error = true;
        }
        // TODO: NotFoundError, NotReadableError, OverconstrainedError, SecurityError, TypeError, how to trigger? equivalents for NavigatorUserMediaError
        if (error) this.store.dispatch(setModalVisibility(ERROR_BOX));
    };

    _handleRTCError = (reason) => {
        console.log("_handleRTCError %o", reason);
        let errorMsg = ERROR_MSG;

        if (typeof reason === "string") errorMsg = reason;

        this._showError(errorMsg);
    };

    _showError = (errorMsg) => {
        this.store.dispatch(setModalVisibility(ERROR_BOX));
        this.store.dispatch(setErrorMessage(errorMsg));
    };

    stopStreams = () => {
        if (this.webRtcHandler !== null && this.webRtcHandler !== undefined) {
            this.webRtcHandler.stopStreams();
            this.store.dispatch(setCaptureState("remoteStream", "stop", false));
        }
    };
}

export default WebRtcEventHandler;
