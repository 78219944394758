import React, { Component } from "react";
import PropTypes from "prop-types";

import "../styles/sidebar.css";

class IconConferenceLock extends Component {
    render() {
        const { globalRecording, onClick, label } = this.props;
        return (
            <span
                className={`conference-control-action__recording-inline ${
                    globalRecording
                        ? "conference-control-action__recording-inline--on"
                        : "conference-control-action__recording-inline--off"
                }`}
                onClick={onClick}
                aria-label={label}
                role="button"
                tabIndex="0"
            >
                <svg
                    version="1.1"
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 24 24"
                    xmlSpace="preserve"
                >
                    <circle cx="12" cy="5" r="5" />
                    <g>
                        <path
                            d="M5,20.5H3v3.4H1.9v-8.4h2.8c0.9,0,1.7,0.2,2.2,0.6c0.5,0.4,0.8,1.1,0.8,1.9c0,0.5-0.1,1-0.4,1.4S6.5,20,6,20.2
                l2,3.6v0.1H6.8L5,20.5z M3,19.6h1.7c0.6,0,1-0.1,1.3-0.4c0.3-0.3,0.5-0.7,0.5-1.1c0-0.5-0.2-0.9-0.5-1.2c-0.3-0.3-0.8-0.4-1.3-0.4
                H3V19.6z"
                        />
                        <path d="M13.9,20h-3.7v3h4.2v0.9H9.2v-8.4h5.3v0.9h-4.2v2.7h3.7V20z" />
                        <path
                            d="M22.1,21.2c-0.1,0.9-0.4,1.6-1,2.1c-0.6,0.5-1.3,0.7-2.2,0.7c-1,0-1.8-0.4-2.4-1.1c-0.6-0.7-0.9-1.7-0.9-2.9
                v-0.8c0-0.8,0.1-1.5,0.4-2.1c0.3-0.6,0.7-1.1,1.2-1.4c0.5-0.3,1.1-0.5,1.8-0.5c0.9,0,1.6,0.2,2.2,0.7c0.5,0.5,0.9,1.2,0.9,2.1H21
                c-0.1-0.7-0.3-1.2-0.6-1.5c-0.3-0.3-0.8-0.5-1.4-0.5c-0.7,0-1.3,0.3-1.7,0.8c-0.4,0.5-0.6,1.3-0.6,2.2v0.8c0,0.9,0.2,1.6,0.6,2.2
                c0.4,0.5,0.9,0.8,1.6,0.8c0.6,0,1.1-0.1,1.4-0.4c0.3-0.3,0.5-0.8,0.7-1.5H22.1z"
                        />
                    </g>
                </svg>
            </span>
        );
    }
}

IconConferenceLock.propTypes = {
    globalRecording: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
};

export default IconConferenceLock;
