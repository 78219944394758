import { connect } from "react-redux";

import DropDownMenuEntry from "../../components/DropDownMenuEntry";
import { writeToSocket } from "../../actions";
import { translate } from "../../lib/Translator";

const mapStateToProps = (state, ownProps) => ({
    show: true,
    text: ownProps.onHold ? translate("DropDownMenu.Unhold") : translate("DropDownMenu.Hold"),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    mouseDown: () => {
        dispatch(
            writeToSocket({
                cmd: "holdBridgeParticipant",
                bridgeId: ownProps.id,
                hold: !ownProps.onHold,
            })
        );
    },
});

const DropDownMenuEntryHold = connect(mapStateToProps, mapDispatchToProps)(DropDownMenuEntry);

export default DropDownMenuEntryHold;
