import { ActionTypes } from "redux-mw-ws";

import MeetyooApi from "../lib/MeetyooApi";

export default function createApiMiddleware() {
    return (store) => {
        const meetyooApi = new MeetyooApi(store);
        return (next) => (action) => {
            if (action.type === ActionTypes.RECEIVED_WEBSOCKET_DATA) {
                if (action.payload.cmd === "login") {
                    if (action.payload.success) {
                        meetyooApi.setMailText(
                            action.payload.mailText,
                            action.payload.product,
                            action.payload.dialinNumbersPdfLink,
                            action.payload.guestPin,
                            action.payload.dialinNumbers
                        );
                    }
                }
            }
            next(action);
        };
    };
}
