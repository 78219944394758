import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { translate } from "../../lib/Translator";
import { writeToSocket, showWelcomeTour, setModalVisibility, setWelcomeTourFromLogin } from "../../actions";
import { WELCOME_SCREEN } from "../../constants/modalConstants";

import SimpleMessageWithCheckbox from "./SimpleMessageWithCheckbox";

const SettingsListContentComponent = ({ settings }) => <div className="sidebar-settings-list">{settings}</div>;

SettingsListContentComponent.propTypes = {
    settings: PropTypes.array.isRequired,
};

const togglePartListChecked = (dispatch, state) => {
    dispatch(
        writeToSocket({
            cmd: "updateSettings",
            participantListEnabled: !state.settings.participantListEnabled,
        })
    );
};

const toggleDialoutChecked = (dispatch, state) => {
    dispatch(
        writeToSocket({
            cmd: "updateSettings",
            dialOutGuestAllowed: !state.settings.dialOutGuestAllowed,
        })
    );
};

const toggleTourChecked = (dispatch, state) => {
    const toggledTourState = !state.modal.showTour;
    dispatch(showWelcomeTour(toggledTourState, state.login.participantName));
    if (toggledTourState) {
        dispatch(setWelcomeTourFromLogin(false));
        dispatch(setModalVisibility(WELCOME_SCREEN));
    }
};

const getPartListSetting = (state) => (
    <SimpleMessageWithCheckbox
        key="partList"
        switchboxId="partList"
        message={<span>{translate("Settings.ParticipantListDescription")}</span>}
        toggleChecked={(dispatch) => {
            togglePartListChecked(dispatch, state);
        }}
        isChecked={state.settings.participantListEnabled}
    />
);

const getDialoutSetting = (state) => (
    <SimpleMessageWithCheckbox
        key="dialout"
        switchboxId="dialout"
        message={<span>{translate("Settings.PartDialoutDescription")}</span>}
        toggleChecked={(dispatch) => {
            toggleDialoutChecked(dispatch, state);
        }}
        isChecked={state.settings.dialOutGuestAllowed}
    />
);

const getTourSetting = (state) => (
    <SimpleMessageWithCheckbox
        key="tour"
        switchboxId="tour"
        message={<span>{translate("Welcome.btnTourStartTitle")}</span>}
        toggleChecked={(dispatch) => {
            toggleTourChecked(dispatch, state);
        }}
        isChecked={state.modal.showTour}
    />
);

const getSettingsArray = (state) => {
    const ar = [];
    if (state.login.isOperator) ar.push(getPartListSetting(state));
    if (state.modal.dialOutAllowed && state.login.isOperator) ar.push(getDialoutSetting(state));
    ar.push(getTourSetting(state));
    return ar;
};

const mapStateToProps = (state) => ({
    settings: getSettingsArray(state),
});

const SettingsListContent = connect(mapStateToProps)(SettingsListContentComponent);

export default SettingsListContent;
