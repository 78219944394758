import React from "react";
import PropTypes from "prop-types";

const ListHeader = ({ show, click, title, showSidebarContent }) => {
    if (!show) return null;
    return (
        <div
            className="sidebar-entry-container sidebar-entry-container--header"
            onClick={click}
            role="button"
            tabIndex="0"
        >
            <div className="sidebar-entry-content">
                <div className="sidebar-entry-content-title">
                    <div className="sidebar-entry-content-title__text">{title}</div>
                </div>
            </div>
            <div className="sidebar-entry-symbols">
                <div
                    className={`sidebar-entry-symbols__arrow sidebar-entry-symbols__arrow--up ${
                        showSidebarContent
                            ? "sidebar-entry-symbols__arrow--opening"
                            : "sidebar-entry-symbols__arrow--closing"
                    }`}
                />
            </div>
        </div>
    );
};

ListHeader.propTypes = {
    show: PropTypes.bool.isRequired,
    click: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    showSidebarContent: PropTypes.bool.isRequired,
};

export default ListHeader;
