import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import "../../../styles/layout.css";
import "./roundImageButton.css";

const RoundImageButtonComponent = ({ show, buttonText, buttonImageComp, buttonType, selectedButton, buttonClick }) =>
    show ? (
        <div className="c-image-button">
            <div className="l-tab-col" onClick={buttonClick} role="button" tabIndex="0">
                <div className="l-center-x">
                    <div className={`${selectedButton === buttonType ? "c-image--clicked" : "c-image"}`}>
                        {buttonImageComp}
                    </div>
                </div>
                <div className="c-name">{buttonText}</div>
            </div>
        </div>
    ) : null;

RoundImageButtonComponent.defaultProps = {
    show: true,
};

RoundImageButtonComponent.propTypes = {
    buttonText: PropTypes.string.isRequired,
    buttonImageComp: PropTypes.node.isRequired,
    buttonType: PropTypes.string.isRequired,
    selectedButton: PropTypes.string.isRequired,
    show: PropTypes.bool,
    buttonClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    buttonText: ownProps.buttonText,
    buttonImage: ownProps.buttonImage,
    buttonType: ownProps.buttonType,
    selectedButton: state.modal.selectedButton,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    buttonClick: () => {
        ownProps.buttonClick();
    },
});

const RoundImageButton = connect(mapStateToProps, mapDispatchToProps)(RoundImageButtonComponent);

export default RoundImageButton;
