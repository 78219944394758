import React from "react";
import PropTypes from "prop-types";

const SidebarButton = ({
    onClick,
    show,
    active,
    liClassname,
    actionClassname,
    tooltipText,
    showFullscreenMode,
    showBadge,
    badgeValue,
    children,
}) =>
    show ? (
        <li className={`tooltip sidebar-item ${liClassname} ${active ? "active" : ""}`}>
            <div
                className={showFullscreenMode ? "sidebar-item__button--fullscreen" : "sidebar-item__button"}
                onClick={() => {
                    onClick(!active);
                }}
                aria-label={tooltipText}
                role="button"
                tabIndex={0}
            >
                {badgeValue !== "" && badgeValue !== "0" && showBadge ? (
                    <div>
                        <div
                            className={`sidebar-item-badge__border ${
                                showFullscreenMode
                                    ? "sidebar-item-badge__border--fullscreen"
                                    : "sidebar-item-badge__border--norml-view"
                            }`}
                        />
                        <div
                            className={`sidebar-item-badge__content ${
                                showFullscreenMode
                                    ? "sidebar-item-badge__content--fullscreen"
                                    : "sidebar-item-badge__content--norml-view"
                            }`}
                        >
                            {badgeValue}
                        </div>
                    </div>
                ) : null}
                <div className="tooltiptext tooltiptext--right tooltip__text--sidebar-button-adjustment">
                    {tooltipText}
                </div>
                <span className={actionClassname + " sidebar-item__children"}>
                    {children !== null && children !== undefined ? children : null}
                </span>
            </div>
        </li>
    ) : null;

SidebarButton.defaultProps = {
    children: null,
    badgeValue: "",
    showFullscreenMode: false,
    showBadge: false,
};

SidebarButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    liClassname: PropTypes.string.isRequired,
    actionClassname: PropTypes.string.isRequired,
    tooltipText: PropTypes.string.isRequired,
    showBadge: PropTypes.bool,
    showFullscreenMode: PropTypes.bool,
    badgeValue: PropTypes.string,
    children: PropTypes.node,
};

export default SidebarButton;
