import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "./sidebar.css";
import { getSidebarViewStyle } from "../../lib/utils";
import { CHAT_LIST } from "../../constants/sidebarConstants";

import SidebarListHeader from "./SidebarListHeader";
import ChatListContent from "./ChatListContent";
import ChatListControl from "./ChatListControl";

export const ChatListComponent = ({ showFullscreenMode, sidebarContentId, showSidebarContent, headerTitle }) => {
    const style = getSidebarViewStyle(showFullscreenMode, showSidebarContent);
    return sidebarContentId === CHAT_LIST ? (
        <div className={`sidebar-list ${style.sidebarListView} ${style.showSidebar}`}>
            <SidebarListHeader title={headerTitle} />
            <div className="sidebar-list__main sidebar-list__main--padded">
                <ChatListContent />
            </div>
            <div className="sidebar-list__footer sidebar-list__footer--max-height-big">
                <ChatListControl />
            </div>
        </div>
    ) : null;
};

ChatListComponent.propTypes = {
    showFullscreenMode: PropTypes.bool.isRequired,
    sidebarContentId: PropTypes.string.isRequired,
    showSidebarContent: PropTypes.bool.isRequired,
    headerTitle: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    showFullscreenMode: state.modal.showFullscreenMode,
    showSidebarContent: state.modal.showSidebarContent,
    sidebarContentId: state.modal.sidebarContentId,
    headerTitle: "CHAT",
});

const ChatList = connect(mapStateToProps)(ChatListComponent);

export default ChatList;
