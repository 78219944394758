import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { translate } from "../../lib/Translator";

import "../../styles/presentation.css";

class BadConnectionIndicator extends Component {
    render() {
        const { connectionQuality, noConnection, badConnection } = this.props;
        if (connectionQuality === "ok") {
            return null;
        }
        const isNoConnection = connectionQuality === "none";
        return (
            <div className="connection-indicator">
                <span
                    className={
                        isNoConnection ? "connection-icon icon-no-connection" : "connection-icon icon-poor-connection"
                    }
                />
                {isNoConnection ? (
                    <span className="error">{noConnection}</span>
                ) : (
                    <span className="warn">{badConnection}</span>
                )}
            </div>
        );
    }
}

BadConnectionIndicator.propTypes = {
    connectionQuality: PropTypes.string.isRequired,
    noConnection: PropTypes.string.isRequired,
    badConnection: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    connectionQuality: state.modal.connectionQuality,
    noConnection: translate("Presentation.StartCapture"),
    badConnection: translate("Presentation.StartCapture"),
});

export default connect(mapStateToProps)(BadConnectionIndicator);
