import React from "react";
import ReactDOM from "react-dom";
import { applyMiddleware, createStore, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { TrackJS } from "trackjs";
import { createWebsocketMiddleware } from "redux-mw-ws";
import "babel-polyfill";
import cssVars from "css-vars-ponyfill";
import { isIE11 } from "./lib/utils";

import createScreenShareMiddleware from "./middleware/screenShareMiddleware";
import createVoipMiddleware from "./middleware/voipMiddleware";
import createUiMiddleware from "./middleware/uiMiddleware";
import createApiMiddleware from "./middleware/apiMiddleware";
import createTelcoMiddleware from "./middleware/telcoMiddleware";

import wsOnClose from "./lib/connectionHandling";
import rootReducer from "./reducers";
import App from "./App";
import ModalBox from "./containers/modals/ModalBox";
import * as serviceWorker from "./serviceWorker";

TrackJS.install({
    token: process.env.REACT_APP_TRACK_JS_TOKEN,
    version: "myweb-" + process.env.REACT_APP_GIT_HASH,
    forwardingDomain: "jserrors.meetyoo.com",
});

const websocketMiddleware = createWebsocketMiddleware({}, wsOnClose);
const screenShareMiddleware = createScreenShareMiddleware();
const voipMiddleware = createVoipMiddleware();
const uiMiddleware = createUiMiddleware();
const apiMiddleware = createApiMiddleware();
const telcoMiddleware = createTelcoMiddleware();

const store = compose(
    applyMiddleware(
        websocketMiddleware,
        thunk,
        screenShareMiddleware,
        voipMiddleware,
        uiMiddleware,
        apiMiddleware,
        telcoMiddleware
    )
)(createStore)(rootReducer);

if (isIE11()) {
    cssVars({
        onlyVars: true,
    });
}

ReactDOM.render(
    <Provider store={store}>
        <div>
            <App />
            {/* modal anchor */}
            <ModalBox />
        </div>
    </Provider>,
    document.getElementById("root")
);
serviceWorker.register();
