import React from "react";
import { connect } from "react-redux";

import { KICK_BRIDGE_PARTICIPANT_BOX } from "../../constants/modalConstants";
import { setModalVisibility, writeToSocket } from "../../actions";
import { translate } from "../../lib/Translator";
import { modalText } from "../../lib/utils";
import Modal from "../../components/modal/Modal";
import SimpleDecissionButtons from "../basics/buttons/SimpleDecissionButtons";

const onClose = () => setModalVisibility("");
const acceptClick = (dispatch, state) => {
    dispatch(
        writeToSocket({
            cmd: "kickBridgeParticipant",
            bridgeId: state.participants.selectedUID,
        })
    );
    dispatch(setModalVisibility(""));
};

const mapStateToProps = (state) => ({
    show: state.modal.modalId === KICK_BRIDGE_PARTICIPANT_BOX,
    title: translate("KickBridgeParticipant.Title"),
    contentChildren: <span>{modalText("KickBridgeParticipant.Message", state.participants.selectedUserName)}</span>,
    buttonChildren: (
        <SimpleDecissionButtons
            acceptClick={(dispatch) => {
                acceptClick(dispatch, state);
            }}
        />
    ),
});

const mapDispatchToProps = {
    onClose,
};
const KickBridgeParticipant = connect(mapStateToProps, mapDispatchToProps)(Modal);

export default KickBridgeParticipant;
