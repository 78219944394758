import { connect } from "react-redux";

import SidebarButton from "../../components/SidebarButton";
import { setFullscreenMode } from "../../actions";
import { translate } from "../../lib/Translator";
import { isFullScreen, isIE11 } from "../../lib/utils";

export const toggleFullScreen = () =>
    function action(dispatch) {
        if (window.navigator.userAgent.indexOf("Chrome/") > -1) {
            if (!document.webkitFullscreenElement) {
                document.body.webkitRequestFullscreen();
                dispatch(setFullscreenMode(true));
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
                dispatch(setFullscreenMode(false));
            }
        }
        if (window.navigator.userAgent.indexOf("Firefox/") > -1) {
            if (!document.mozFullScreenElement) {
                document.body.mozRequestFullScreen();
                dispatch(setFullscreenMode(true));
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
                dispatch(setFullscreenMode(false));
            }
        }
        if (isIE11()) {
            if (!isFullScreen()) {
                document.documentElement.msRequestFullscreen();
                dispatch(setFullscreenMode(true));
            } else {
                document.msExitFullscreen();
                dispatch(setFullscreenMode(false));
            }
        }
    };

const mapStateToProps = () => ({
    show: true, // not evaluated
    active: false, // not evaluated
    liClassname: isFullScreen() ? "exitfullscreen" : "fullscreen",
    actionClassname: isFullScreen() ? "action-exit-fullscreen" : "action-fullscreen",
    tooltipText: isFullScreen() ? translate("Sidebar.ExitFullscreen") : translate("Sidebar.Fullscreen"),
});

const mapDispatchToProps = (dispatch) => ({
    onClick: (show) => {
        dispatch(toggleFullScreen(show));
    },
});

const SidebarButtonFullscreen = connect(mapStateToProps, mapDispatchToProps)(SidebarButton);

export default SidebarButtonFullscreen;
