import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import DialoutEntry from "../basics/Selecter/DialoutEntry";

import { INVITATION_DIALOUT } from "../../constants/modalConstants";
import { dialoutStates } from "../../constants/commonConstants";

import { translate } from "../../lib/Translator";

import { setStartDialout } from "../../actions";

import "../../styles/layout.css";
import "../basics/buttons/button.css";
import styles from "./invitationDialout.module.css";

const InvitationDialoutComponent = ({
    show,
    labelText,
    btnText,
    btnTextStarted,
    dialouts,
    startDialout,
    startDialoutFn,
}) =>
    show ? (
        <div className="l-tab-col">
            <div className={styles["c-label"]}>{labelText}</div>
            <div className={styles["c-dialouts"]}>
                {dialouts.map((d, i) => (
                    <DialoutEntry
                        key={d.dialoutId}
                        showAdd={
                            (dialouts.length === i + 1 && d.callState !== dialoutStates.SUCCESS) ||
                            dialouts.length === 1
                        }
                        showDelete={dialouts.length > 1 && d.callState !== dialoutStates.SUCCESS}
                        dialout={d}
                    />
                ))}
            </div>
            <div className={styles["c-button"]}>
                {startDialout ? (
                    <div className="c-btn c-btn--max c-btn--grey">{btnTextStarted}</div>
                ) : (
                    <div
                        className="c-btn c-btn--max c-btn--branded"
                        onClick={() => {
                            startDialoutFn();
                        }}
                        role="button"
                        tabIndex="0"
                    >
                        {btnText}
                    </div>
                )}
            </div>
        </div>
    ) : null;

InvitationDialoutComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    labelText: PropTypes.string.isRequired,
    btnText: PropTypes.string.isRequired,
    btnTextStarted: PropTypes.string.isRequired,
    dialouts: PropTypes.array.isRequired,
    startDialout: PropTypes.bool.isRequired,
    startDialoutFn: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    show: state.modal.modalContent === INVITATION_DIALOUT,
    labelText: translate("Invitation.DialoutHeader"),
    btnText: translate("Invitation.Dialout"),
    btnTextStarted: translate("Invitation.DialoutButtonStarted"),
    dialouts: state.telco.dialouts,
    startDialout: state.telco.startDialout,
});

const mapDispatchToProps = (dispatch) => ({
    startDialoutFn: () => dispatch(setStartDialout(true)),
});

const InvitationDialout = connect(mapStateToProps, mapDispatchToProps)(InvitationDialoutComponent);

export default InvitationDialout;
