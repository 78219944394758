import { writeToSocket, setReconnecting } from "../actions";
import { makeLoginJson } from "./utils";

const _sendLoginCreds = (store) => {
    const state = store.getState();
    console.log("try to reconnect...");
    store.dispatch(setReconnecting(true));
    store.dispatch(
        writeToSocket(
            makeLoginJson(
                state.login.isOperator ? "start" : "join",
                state.login.pin,
                state.login.participantName.trim(),
                state.login.sessionId
            ),
            true
        )
    );
};

const wsOnClose = (store, close) => {
    const state = store.getState();
    if (close.code !== 1000) {
        setTimeout(() => {
            if (state.login.pin !== "") {
                _sendLoginCreds(store);
            }
        }, 1000);
    }
};

export default wsOnClose;
