import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { RENAME_PARTICIPANT_BOX } from "../../constants/modalConstants";

import Modal from "../../components/modal/Modal";
import SimpleDecissionButtons from "../basics/buttons/SimpleDecissionButtons";

import { writeToSocket, setModalVisibility, setErrorMessage } from "../../actions";
import { translate } from "../../lib/Translator";
import { countChars } from "../../lib/utils";

const RenameParticipantBoxComponent = ({
    show,
    title,
    errorMsg,
    selectedUID,
    selectedUserName,
    newNameForText,
    abortText,
    saveText,
    abortClick,
    acceptClick,
}) => {
    let nameInput = null;
    return (
        <Modal
            show={show}
            onClose={abortClick}
            title={title}
            errorMsg={errorMsg}
            contentChildren={
                <div>
                    <span>{newNameForText}</span>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            acceptClick(selectedUID, nameInput);
                            return false;
                        }}
                        ref={(form) => form}
                    >
                        <input
                            className="input-field input-field--text"
                            ref={(input) => {
                                nameInput = input;
                                return input;
                            }}
                            type="text"
                            defaultValue={selectedUserName}
                        />
                        <input className="input-field input-field--submit" id="renamehiddenbutton" type="submit" />
                    </form>
                </div>
            }
            buttonChildren={
                <SimpleDecissionButtons
                    acceptClick={() => {
                        document.getElementById("renamehiddenbutton").click();
                    }}
                    abortText={abortText}
                    acceptText={saveText}
                />
            }
        />
    );
};

RenameParticipantBoxComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    errorMsg: PropTypes.string.isRequired,
    selectedUID: PropTypes.string.isRequired,
    selectedUserName: PropTypes.string.isRequired,
    newNameForText: PropTypes.string.isRequired,
    abortText: PropTypes.string.isRequired,
    saveText: PropTypes.string.isRequired,
    abortClick: PropTypes.func.isRequired,
    acceptClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    show: state.modal.modalId === RENAME_PARTICIPANT_BOX,
    title: translate("RenameParticipant.Title"),
    errorMsg: state.modal.errorMsg,
    selectedUID: state.participants.selectedUID,
    selectedUserName: state.participants.selectedUserName,
    newNameForText: translate("RenameParticipant.NewNameFor"),
    abortText: translate("Button.Abort"),
    saveText: translate("Button.Save"),
});

const abortClick = (dispatch) => dispatch(setModalVisibility(""));
const acceptClick = (dispatch, selectedUID, nameInput) => {
    let name = "";
    if (nameInput) name = nameInput.value;
    if (name !== "" && countChars(name) > 1)
        dispatch(
            writeToSocket({
                cmd: "renameParticipant",
                uid: selectedUID,
                username: name,
            })
        );
    else dispatch(setErrorMessage(translate("Error.InvalidName")));
};

const mapDispatchToProps = (dispatch) => ({
    abortClick: () => abortClick(dispatch),
    acceptClick: (selectedUID, name) => {
        acceptClick(dispatch, selectedUID, name);
    },
});

const RenameParticipantBox = connect(mapStateToProps, mapDispatchToProps)(RenameParticipantBoxComponent);

export default RenameParticipantBox;
