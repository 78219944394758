import React from "react";
import { connect } from "react-redux";

import { PRESENTER_REVOKED_BOX } from "../../constants/modalConstants";
import { setModalVisibility } from "../../actions";
import { translate } from "../../lib/Translator";
import { modalText } from "../../lib/utils";
import Modal from "../../components/modal/Modal";
import SimpleAcceptButton from "../basics/buttons/SimpleAcceptButton";

const onClose = () => setModalVisibility("");

const mapStateToProps = (state) => ({
    show: state.modal.modalId === PRESENTER_REVOKED_BOX && !state.modal.wasPresenter,
    title: translate("PresenterRevoked.Title"),
    contentChildren: <div>{modalText("PresenterRevoked.Message", state.login.presenterRevokedBy)}</div>,
    buttonChildren: <SimpleAcceptButton acceptText="OK" modalId={PRESENTER_REVOKED_BOX} />,
});

const mapDispatchToProps = {
    onClose,
};

const PresenterRevoked = connect(mapStateToProps, mapDispatchToProps)(Modal);

export default PresenterRevoked;
