import { connect } from "react-redux";

import DropDownMenuEntry from "../../components/DropDownMenuEntry";
import { writeToSocket } from "../../actions";
import { translate } from "../../lib/Translator";

const mapStateToProps = (state, ownProps) => ({
    show: true,
    text: ownProps.mute ? translate("DropDownMenu.Unmute") : translate("DropDownMenu.Mute"),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    mouseDown: () => {
        dispatch(
            writeToSocket({
                cmd: "muteBridgeParticipant",
                bridgeId: ownProps.id,
                mute: !ownProps.mute,
            })
        );
    },
});

const DropDownMenuEntryMute = connect(mapStateToProps, mapDispatchToProps)(DropDownMenuEntry);

export default DropDownMenuEntryMute;
