import { connect } from "react-redux";

import ButtonContent from "../../../components/modal/ButtonContent";
import { setModalVisibility } from "../../../actions";

const mapStateToProps = (state, ownProps) => ({
    acceptText: ownProps.acceptText,
    marginTop: ownProps.marginTop ? ownProps.marginTop : "btn-wrapper--margin-top",
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    acceptClick: () => {
        dispatch(setModalVisibility(""));
        if (ownProps.additionalFunc !== null && ownProps.additionalFunc !== undefined) {
            ownProps.additionalFunc(dispatch);
        }
    },
});

const SimpleAcceptButton = connect(mapStateToProps, mapDispatchToProps)(ButtonContent);

export default SimpleAcceptButton;
