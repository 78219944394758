import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "./sidebar.css";
import { translate } from "../../lib/Translator";
import { getSidebarViewStyle } from "../../lib/utils";

import SidebarListHeader from "./SidebarListHeader";
import ParticipantListWeb from "./ParticipantListWeb";
import ParticipantListBridge from "./ParticipantListBridge";
import ParticipantListMatched from "./ParticipantListMatched";
import ConferenceControl from "./ConferenceControl";

import { PARTICIPANT_LIST } from "../../constants/sidebarConstants";

export const ParticipantListComponent = ({
    showFullscreenMode,
    sidebarContentId,
    showSidebarContent,
    isOperator,
    participantListEnabled,
    headerTitle,
    headerInfo,
}) => {
    const style = getSidebarViewStyle(showFullscreenMode, showSidebarContent);
    return sidebarContentId === PARTICIPANT_LIST && (participantListEnabled || isOperator) ? (
        <div className={`sidebar-list ${style.sidebarListView} ${style.showSidebar}`}>
            <SidebarListHeader title={headerTitle} info={headerInfo} />
            <div className="sidebar-list__main">
                <ParticipantListMatched />
                <ParticipantListWeb />
                <ParticipantListBridge />
            </div>
            <div className="sidebar-list__footer sidebar-list__footer--max-height-small">
                <ConferenceControl />
            </div>
        </div>
    ) : null;
};

ParticipantListComponent.propTypes = {
    showFullscreenMode: PropTypes.bool.isRequired,
    sidebarContentId: PropTypes.string.isRequired,
    showSidebarContent: PropTypes.bool.isRequired,
    isOperator: PropTypes.bool.isRequired,
    participantListEnabled: PropTypes.bool.isRequired,
    headerTitle: PropTypes.string.isRequired,
    headerInfo: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    showFullscreenMode: state.modal.showFullscreenMode,
    showSidebarContent: state.modal.showSidebarContent,
    sidebarContentId: state.modal.sidebarContentId,
    isOperator: state.login.isOperator,
    participantListEnabled: state.settings.participantListEnabled,
    headerTitle: translate("ParticipantList.Participants"),
    headerInfo: `${state.participants.audio.length + state.participants.matched.length} / ${
        state.login.maxParticipants
    } ${translate("ParticipantList.Active")}`,
});

const ParticipantList = connect(mapStateToProps)(ParticipantListComponent);

export default ParticipantList;
