import React from "react";
import { connect } from "react-redux";

import { setSelectedCountryNumber } from "../../../actions";
import SelectContent from "../../../components/modal/SelectContent";

const selectOnChange = (e) => (dispatch, getState) => {
    const { options } = e.target;
    for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
            dispatch(setSelectedCountryNumber(getState().telco.countryNumbers[i].countryNumber));
            break;
        }
    }
};

const mapStateToProps = (state, ownProps) => ({
    selectLabel: ownProps.selectLabel,
    selectContent: state.telco.countryNumbers.map((part) => (
        <option key={part.country + "-" + part.countryNumber}>{part.country}</option>
    )),
});

const mapDispatchToProps = {
    selectOnChange,
};

const SelectCountry = connect(mapStateToProps, mapDispatchToProps)(SelectContent);

export default SelectCountry;
