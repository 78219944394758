import { connect } from "react-redux";

import StopSharingButton from "../../components/StopSharingButton";
import { stopSharing } from "../../actions";

const mapStateToProps = (state, ownProps) => ({
    title: ownProps.title,
    sessionId: state.login.sessionId,
});

const mapDispatchToProps = {
    onClick: (sessionId) => stopSharing(sessionId),
};

const StopSharingDefault = connect(mapStateToProps, mapDispatchToProps)(StopSharingButton);

export default StopSharingDefault;
