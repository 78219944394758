import { connect } from "react-redux";
import VideoView from "../../components/VideoView";
import streamContainer from "../../lib/StreamContainer";

const mapStateToProps = (state) => ({
    show: state.webRtc.remoteStream === "ready",
    className: `presentation ${
        state.modal.showFullscreenMode ? "presentation--fullscreen" : "presentation--normal-view"
    }`,
    mediaStream: streamContainer.remoteStream,
});

const RemoteVideoView = connect(mapStateToProps)(VideoView);

export default RemoteVideoView;
