import React, { Component } from "react";
import PropTypes from "prop-types";

import "../styles/sidebar.css";

class IconSidebarEntryPresenting extends Component {
    render() {
        const { presentationActive } = this.props;
        return (
            <span
                className={`sidebar-entry-content-symbols__presenting-inline${
                    presentationActive ? " sidebar-entry-content-symbols__presenting-inline--active" : ""
                }`}
            >
                <svg
                    version="1.1"
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 16 16"
                    xmlSpace="preserve"
                >
                    <path
                        d="M15,1H1C0.5,1,0,1.5,0,2v9c0,0.5,0.5,1,1,1h6.5v2h-3C4.2,14,4,14.2,4,14.5S4.2,15,4.5,15h7
        c0.3,0,0.5-0.2,0.5-0.5S11.8,14,11.5,14h-3v-2H15c0.5,0,1-0.5,1-1V2C16,1.5,15.5,1,15,1z M15,10.5c0,0.3-0.2,0.5-0.5,0.5h-13
        C1.2,11,1,10.8,1,10.5v-8C1,2.2,1.2,2,1.5,2h13C14.8,2,15,2.2,15,2.5V10.5z"
                    />
                </svg>
            </span>
        );
    }
}

IconSidebarEntryPresenting.propTypes = {
    presentationActive: PropTypes.bool.isRequired,
};

export default IconSidebarEntryPresenting;
