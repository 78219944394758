import React, { Component } from "react";
import "../containers/basics/buttons/roundImageButton.css";

class IconCopyLink extends Component {
    render() {
        return (
            <div className="c-image__icon">
                <svg
                    version="1.1"
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 34 34"
                    style={{ background: "0 0 34 34" }}
                    xmlSpace="preserve"
                >
                    <style type="text/css" />
                    <g>
                        <g id="Group-4" transform="translate(11.736842, 0.784105)">
                            <g id="Clip-3" />
                            <defs>
                                <filter
                                    id="Adobe_OpacityMaskFilter"
                                    filterUnits="userSpaceOnUse"
                                    x="2.5"
                                    y="1.2"
                                    width="17.8"
                                    height="19.4"
                                >
                                    <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                                </filter>
                            </defs>
                            <mask maskUnits="userSpaceOnUse" x="2.5" y="1.2" width="17.8" height="19.4" id="mask-2_2_">
                                <g className="st0" style={{ filter: "url(#Adobe_OpacityMaskFilter)" }}>
                                    <polygon
                                        id="path-1_2_"
                                        className="st1"
                                        style={{ mask: "url(#mask-2_2_)", fillRule: "evenodd", clipRule: "evenodd" }}
                                        points="2.5,1.2 20.3,1.2 20.3,20.6 2.5,20.6 				"
                                    />
                                </g>
                            </mask>
                            <path
                                id="Fill-2"
                                className="st2 st-conn-0"
                                d="M18.4,3.1c-2.5-2.5-6.7-2.5-9.2,0L3.8,8.5c-0.3,0.3-0.3,0.9,0,1.2c0.3,0.3,0.9,0.3,1.2,0l5.4-5.4
               c1.8-1.8,4.8-1.8,6.7,0c1.8,1.8,1.8,4.8,0,6.7l-6.4,6.4c-1.8,1.8-4.8,1.8-6.7,0c-0.3-0.3-0.9-0.3-1.2,0c-0.3,0.3-0.3,0.9,0,1.2
               C4,20,5.7,20.6,7.3,20.6s3.3-0.6,4.6-1.9l6.4-6.4C20.9,9.8,20.9,5.7,18.4,3.1"
                            />
                        </g>
                        <g id="Group-10" transform="translate(0.000000, 11.520947)">
                            <g id="Clip-9" />
                            <defs>
                                <filter
                                    id="Adobe_OpacityMaskFilter_1_"
                                    filterUnits="userSpaceOnUse"
                                    x="2"
                                    y="1.1"
                                    width="17.8"
                                    height="19.4"
                                >
                                    <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
                                </filter>
                            </defs>
                            <mask maskUnits="userSpaceOnUse" x="2" y="1.1" width="17.8" height="19.4" id="mask-6_2_">
                                <g className="st3" style={{ filter: "url(#Adobe_OpacityMaskFilter_1_)" }}>
                                    <polygon id="path-5_2_" className="st1" points="2,1.1 19.8,1.1 19.8,20.5 2,20.5 				" />
                                </g>
                            </mask>
                            <path
                                id="Fill-8"
                                style={{ mask: "url(#mask-6_2_)", fillRule: "evenodd", clipRule: "evenodd" }}
                                className="st4 st-conn-0"
                                d="M17.3,11.9l-5.4,5.4c-1.8,1.8-4.8,1.8-6.7,0c-1.8-1.8-1.8-4.8,0-6.7l6.4-6.4
               c1.8-1.8,4.8-1.8,6.7,0c0.3,0.3,0.9,0.3,1.2,0c0.3-0.3,0.3-0.9,0-1.2c-2.5-2.5-6.7-2.5-9.2,0L3.9,9.4c-2.5,2.5-2.5,6.7,0,9.2
               c1.3,1.3,2.9,1.9,4.6,1.9c1.7,0,3.3-0.6,4.6-1.9l5.4-5.4c0.3-0.3,0.3-0.9,0-1.2C18.2,11.6,17.6,11.6,17.3,11.9"
                            />
                        </g>
                    </g>
                </svg>
            </div>
        );
    }
}

export default IconCopyLink;
