import React from "react";
import { connect } from "react-redux";

import { DOWNLOAD_BOX } from "../../constants/modalConstants";
import Modal from "../../components/modal/Modal";
import SimpleAcceptButton from "../basics/buttons/SimpleAcceptButton";
import { setModalVisibility } from "../../actions";
import { translate } from "../../lib/Translator";
import { acceptDownloadText } from "../../lib/utils";

const mapStateToProps = (state) => ({
    show: state.modal.modalId === DOWNLOAD_BOX,
    title: translate("DownloadWebApp.Title"),
    contentChildren: (
        <span>
            {translate("DownloadWebApp.MessageI")} <br />
            <br />
            {translate("DownloadWebApp.MessageII")}
        </span>
    ),
    buttonChildren: <SimpleAcceptButton acceptText={acceptDownloadText()} modalId={DOWNLOAD_BOX} />,
});

const onClose = () => setModalVisibility("");

const mapDispatchToProps = {
    onClose,
};

const DownloadApp = connect(mapStateToProps, mapDispatchToProps)(Modal);

export default DownloadApp;
