import React from "react";
import PropTypes from "prop-types";

class VideoView extends React.Component {
    componentDidMount() {
        const { mediaStream } = this.props;
        if (this.video === undefined || this.video === null) return;
        if ("srcObject" in window.HTMLMediaElement.prototype) this.video.srcObject = mediaStream;
        else this.video.src = window.URL.createObjectURL(mediaStream);
    }

    shouldComponentUpdate(nextProps) {
        const { mediaStream, className } = this.props;
        return mediaStream !== nextProps.mediaStream || className !== nextProps.className;
    }

    componentDidUpdate() {
        const { mediaStream } = this.props;
        if (this.video === undefined || this.video === null) return;
        if ("srcObject" in window.HTMLMediaElement.prototype) this.video.srcObject = mediaStream;
        else this.video.src = window.URL.createObjectURL(mediaStream);
    }

    render() {
        const { show, className } = this.props;
        return show ? (
            <div className={className}>
                <video
                    width="100%"
                    height="100%"
                    ref={(video) => {
                        this.video = video;
                    }}
                    autoPlay
                />
            </div>
        ) : null;
    }
}

VideoView.defaultProps = {
    mediaStream: null,
};

VideoView.propTypes = {
    show: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired,
    mediaStream: PropTypes.object,
};

export default VideoView;
