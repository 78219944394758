import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { PHONE_DIALIN_BOX } from "../../constants/modalConstants";
import { MATCH_SEQUENCE_START } from "../../constants/commonConstants";
import SelectNumbers from "../basics/Selecter/SelectNumbers";

import { translate } from "../../lib/Translator";
import { modalText } from "../../lib/utils";
import { setModalVisibility } from "../../actions";

import styles from "./connectionDialin.module.css";

import "../../styles/layout.css";
import "../basics/buttons/button.css";

const ConnectionDialinComponent = ({
    show,
    messageDial,
    messageEntryCode,
    messageDialedIn,
    selectNumberLabel,
    selectedDialinNumber,
    pin,
    onClose,
    matchingId,
}) =>
    show ? (
        <div className="l-tab-col">
            <div className={styles["c-info"]}>
                <span>{modalText("PhoneDialin.MessageMatching", MATCH_SEQUENCE_START + "#" + matchingId)}</span>
            </div>
            <div className={styles["c-spacer"]} />
            <SelectNumbers key="selectNumbers" show selectLabel={selectNumberLabel} />
            <div className={styles["c-spacer"]} />
            <div className="l-tab-row">
                <div className={`${styles["c-text"]} ${styles["c-text--left"]}`}>{messageDial}</div>
                <div className={`${styles["c-text"]} ${styles["c-text--right"]}`}>
                    {modalText("{0}", selectedDialinNumber)}
                </div>
            </div>
            <div className="l-tab-row">
                <div className={`${styles["c-text"]} ${styles["c-text--left"]}`}>{messageEntryCode}</div>
                <div className={`${styles["c-text"]} ${styles["c-text--right"]}`}>{modalText("{0}", pin + "#")}</div>
            </div>
            <div className={styles["c-button"]}>
                <div className="c-btn c-btn--max c-btn--branded" onClick={onClose} role="button" tabIndex="0">
                    {messageDialedIn}
                </div>
            </div>
        </div>
    ) : null;

ConnectionDialinComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    messageDial: PropTypes.string.isRequired,
    messageEntryCode: PropTypes.string.isRequired,
    messageDialedIn: PropTypes.string.isRequired,
    selectNumberLabel: PropTypes.string.isRequired,
    selectedDialinNumber: PropTypes.string.isRequired,
    pin: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    matchingId: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
    show: state.modal.modalContent === PHONE_DIALIN_BOX,
    messageDial: translate("PhoneDialin.Dial"),
    messageEntryCode: translate("PhoneDialin.EntryCode"),
    messageDialedIn: translate("PhoneDialin.DialedIn"),
    selectNumberLabel: translate("PhoneDialin.Numbers"),
    selectedDialinNumber: state.telco.selectedDialinNumber,
    pin: state.login.pin,
    matchingId: state.login.matchingId,
});

const mapDispatchToProps = {
    onClose: () => setModalVisibility(""),
};

const ConnectionDialin = connect(mapStateToProps, mapDispatchToProps)(ConnectionDialinComponent);

export default ConnectionDialin;
