import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "./button.css";

const ButtonComponent = ({ title, btnSize, btnColor, onClickFnDispatchIncluded, onClickFn, onClickDispatchedFn }) => (
    <div
        className={`c-btn ${btnSize} ${btnColor}`}
        onClick={onClickFnDispatchIncluded ? onClickDispatchedFn : onClickFn}
        role="button"
        tabIndex="0"
    >
        {title}
    </div>
);
ButtonComponent.defaultProps = {
    onClickFnDispatchIncluded: false,
};

ButtonComponent.propTypes = {
    title: PropTypes.string.isRequired,
    btnSize: PropTypes.string.isRequired,
    btnColor: PropTypes.string.isRequired,
    onClickFnDispatchIncluded: PropTypes.bool,
    onClickFn: PropTypes.func.isRequired,
    onClickDispatchedFn: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    title: ownProps.title,
    btnSize: ownProps.btnSize,
    btnColor: ownProps.btnColor,
    onClickFnDispatchIncluded: ownProps.onClickFnDispatchIncluded,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onClickFn: () => dispatch(ownProps.onClickFn()),
    onClickDispatchedFn: () => ownProps.onClickFn(),
});

const Button = connect(mapStateToProps, mapDispatchToProps)(ButtonComponent);

export default Button;
