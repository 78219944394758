import { connect } from "react-redux";

import { KICK_BRIDGE_PARTICIPANT_BOX } from "../../constants/modalConstants";
import DropDownMenuEntry from "../../components/DropDownMenuEntry";
import { setSelectedUser, setModalVisibility } from "../../actions";
import { translate } from "../../lib/Translator";

const mapStateToProps = () => ({
    show: true,
    text: translate("DropDownMenu.RemoveBridgePart"),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    mouseDown: () => {
        dispatch(setModalVisibility(KICK_BRIDGE_PARTICIPANT_BOX));
        dispatch(setSelectedUser(ownProps.phone, ownProps.id));
    },
});

const DropDownMenuEntryKick = connect(mapStateToProps, mapDispatchToProps)(DropDownMenuEntry);

export default DropDownMenuEntryKick;
