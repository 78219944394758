import React from "react";
import { connect } from "react-redux";

import { END_PRESENTATION_BOX } from "../../constants/modalConstants";
import { setModalVisibility, writeToSocket, voipHangup } from "../../actions";
import { translate } from "../../lib/Translator";
import Modal from "../../components/modal/Modal";
import SimpleDecissionButtons from "../basics/buttons/SimpleDecissionButtons";

const onClose = () => setModalVisibility("");
const acceptClick = (dispatch, state) => {
    dispatch(voipHangup());
    dispatch(
        writeToSocket({
            cmd: "leavePresentation",
            uid: state.login.uid,
        })
    );
    dispatch(setModalVisibility(""));
};

const mapStateToProps = (state) => ({
    show: state.modal.modalId === END_PRESENTATION_BOX,
    title: translate("LeavePresentation.Title"),
    contentChildren: <p>{translate("LeavePresentation.Message")}</p>,
    buttonChildren: <SimpleDecissionButtons acceptClick={(dispatch) => acceptClick(dispatch, state)} />,
});

const mapDispatchToProps = {
    onClose,
};

const EndPresentation = connect(mapStateToProps, mapDispatchToProps)(Modal);

export default EndPresentation;
