import { ActionTypes } from "redux-mw-ws";
import { dialoutStates } from "../constants/commonConstants";

export const telcoInitialState = {
    dialinNumbers: [],
    countryNumbers: [],
    dialinNumbersPdfLink: "",
    product: "",
    selectedDialinNumber: "",
    selectedDialoutNumber: -1,
    selectedCountryNumber: "",
    voipConnectionStatus: false,
    voipMuteStatus: false,
    bridgeType: "",
    invitationMail: "",
    dialouts: [],
    addedDialouts: 0,
    selectedCountryIndex: 0,
    selectedDialoutId: "",
    dialoutInProgress: false,
    startDialout: false,
};

const calcId = (countryNumbers, i1, i2) => `${countryNumbers[i1].countryCode}-${i1}-${i2}`;

const telco = (state = telcoInitialState, action) => {
    switch (action.type) {
        case ActionTypes.RECEIVED_WEBSOCKET_DATA:
            if (action.payload.cmd === "login" && action.payload.success === true) {
                return {
                    ...state,
                    dialinNumbers: action.payload.dialinNumbers,
                    countryNumbers: action.payload.countryNumbers,
                    dialinNumbersPdfLink: action.payload.dialinNumbersPdfLink,
                    product: action.payload.product,
                    selectedDialinNumber:
                        action.payload.dialinNumbers.length > 0
                            ? Object.values(action.payload.dialinNumbers[0])[2]
                            : -1,
                    selectedCountryNumber:
                        action.payload.countryNumbers.length > 0
                            ? Object.values(action.payload.countryNumbers[0])[2]
                            : "49",
                    bridgeType: action.payload.bridgeType,
                };
            }
            return state;
        case "SET_SELECTED_DIALIN_NUMBER":
            return {
                ...state,
                selectedDialinNumber: action.dialinNumber,
            };
        case "SET_SELECTED_DIALOUT_NUMBER":
            return {
                ...state,
                selectedDialoutNumber: action.dialoutNumber,
            };
        case "SET_SELECTED_COUNTRY_NUMBER":
            return {
                ...state,
                selectedCountryNumber: action.countryNumber,
            };
        case "SET_VOIP_CONNECTION_STATUS":
            return {
                ...state,
                voipConnectionStatus: action.connected,
            };
        case "SET_VOIP_MUTE_STATUS":
            return {
                ...state,
                voipMuteStatus: action.mute,
            };
        case "SET_INVITATION_MAIL":
            return {
                ...state,
                invitationMail: action.invitationMail,
            };
        case "SET_SELECTED_COUNTRY_INDEX":
            return {
                ...state,
                selectedCountryIndex: state.dialouts.some((d) => d.dialoutId === action.dialoutId)
                    ? state.selectedCountryIndex
                    : action.selectedCountryIndex,
                dialouts: state.dialouts.map((d) =>
                    d.dialoutId === action.dialoutId
                        ? {
                              ...d,
                              countryIndex: action.selectedCountryIndex,
                          }
                        : d
                ),
            };
        case "SET_SELECTED_DIALOUT_ID":
            return {
                ...state,
                selectedDialoutId: action.selectedDialoutId,
            };
        case "ADD_DIALOUT":
            return {
                ...state,
                selectedCountryIndex: 0,
                dialouts: [
                    ...state.dialouts,
                    {
                        dialoutId: calcId(action.countryNumbers, 0, state.addedDialouts),
                        countryIndex: 0,
                        phoneNumber: null,
                        callState: dialoutStates.INITIAL,
                    },
                ],
                addedDialouts: state.addedDialouts + 1,
            };
        case "DELETE_DIALOUT":
            return {
                ...state,
                dialouts: state.dialouts.filter((d) => d.dialoutId !== action.dialoutId),
            };
        case "MODIFY_DIALOUT_PHONENUMBER":
            return {
                ...state,
                dialouts: state.dialouts.map((d) =>
                    d.dialoutId === state.selectedDialoutId
                        ? {
                              ...d,
                              phoneNumber: action.phoneNumber,
                          }
                        : d
                ),
            };
        case "SET_START_DIALOUT":
            return {
                ...state,
                startDialout: action.startDialout,
            };
        case "SET_DIALOUT_CALL_STATE":
            return {
                ...state,
                dialouts: state.dialouts.map((d) =>
                    d.dialoutId === action.dialoutId
                        ? {
                              ...d,
                              callState: action.callState,
                          }
                        : d
                ),
            };
        default:
            return state;
    }
};

export default telco;
