import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { PHONE_DIALOUT_BOX, SHOW_SPINNER } from "../../constants/modalConstants";

import SelectCountry from "../basics/Selecter/SelectCountry";
import InputPhoneNumber from "../basics/Inputs/InputPhoneNumber";

import { translate } from "../../lib/Translator";
import { writeToSocket, setModalContent, setSelectedDialoutNumber } from "../../actions";

import "../../styles/layout.css";
import "../basics/buttons/button.css";
import styles from "./connectionDialout.module.css";

const formatDialoutNumber = (dialoutNumber) => {
    const number = dialoutNumber.toString();
    return number[0] === "0" ? number.substring(1) : number;
};

const ConnectionDialoutComponent = ({
    show,
    messageCountry,
    messageTelephoneNumber,
    messageDialedOut,
    selectedCountryNumber,
    dialoutFn,
}) =>
    show ? (
        <div className="l-tab-col">
            <div className={styles["c-spacer"]} />
            <SelectCountry key="selectCountries" selectLabel={messageCountry} />
            <InputPhoneNumber
                key="phoneNumber"
                inputLabel={messageTelephoneNumber}
                prefix={`+${selectedCountryNumber}`}
                placeholder="30 123456789"
                func={(number) => setSelectedDialoutNumber(formatDialoutNumber(number))}
            />
            <div className={styles["c-button"]}>
                <div className="c-btn c-btn--max c-btn--branded" onClick={dialoutFn} role="button" tabIndex="0">
                    {messageDialedOut}
                </div>
            </div>
        </div>
    ) : null;

ConnectionDialoutComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    messageCountry: PropTypes.string.isRequired,
    messageTelephoneNumber: PropTypes.string.isRequired,
    messageDialedOut: PropTypes.string.isRequired,
    selectedCountryNumber: PropTypes.string.isRequired,
    dialoutFn: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    show: state.modal.modalContent === PHONE_DIALOUT_BOX,
    messageCountry: translate("PhoneDialout.Country"),
    messageTelephoneNumber: translate("PhoneDialout.TelephoneNumber"),
    messageDialedOut: translate("PhoneDialout.DialedOut"),
    selectedCountryNumber: state.telco.selectedCountryNumber,
});

const dialout = () => (dispatch, getState) => {
    dispatch(
        writeToSocket({
            cmd: "dialOutParticipants",
            participants: [
                {
                    NAME: getState().login.participantName,
                    PHONE: "00" + getState().telco.selectedCountryNumber + getState().telco.selectedDialoutNumber,
                    COMPANY: "",
                    MATCHING_ID: getState().login.matchingId,
                },
            ],
        })
    );
    dispatch(setModalContent(SHOW_SPINNER));
};

const mapDispatchToProps = {
    dialoutFn: () => dialout(),
};

const ConnectionDialout = connect(mapStateToProps, mapDispatchToProps)(ConnectionDialoutComponent);

export default ConnectionDialout;
