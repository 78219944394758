import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { FEEDBACK_BOX } from "../../../constants/modalConstants";
import MeetyooApi from "../../../lib/MeetyooApi";
import Modal from "../../../components/modal/Modal";
import Rating from "./Rating";
import { translate } from "../../../lib/Translator";

import "../../../styles/layout.css";
import "../../../styles/forms.css";
import "../../../styles/modal.css";
import "./feedbackBox.css";

import { setModalVisibility, writeToSocket, voipHangup, setFeedbackSent } from "../../../actions";

class FeedbackBox extends Component {
    constructor(props) {
        super(props);
        const { rating } = this.props;
        this.initialState = {
            rating: rating || -1,
            comment: "",
            phone: "",
            showError: false,
            endPresentation: false,
        };
        this.state = this.initialState;

        this.setRating = this._setRating.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._submit = this._submit.bind(this);
        this._validate = this._validate.bind(this);
        this._close = this._close.bind(this);
    }

    _setRating = (rating) => {
        this.setState({
            rating,
            showError: false,
        });
    };

    _handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    _submit() {
        if (!this._validate()) {
            return;
        }
        const { participantName, conferenceRoomId, salt, dispatch } = this.props;
        const { rating, comment, phone } = this.state;
        const data = {
            name: participantName,
            ratingSource: "myWeb.de",
            ratingSubject: "Bewertung",
            ratingValue: rating,
            ratingComment: comment,
        };
        if (phone) {
            data.telephone = phone;
            data.ringbackTrigger = true;
        }
        // console.log("data: %o", data);

        MeetyooApi.postFeedback(conferenceRoomId, salt, data)
            .then((json) => console.log("rating resp: %o", json))
            .catch((error) => console.log("error json response: %o", error));

        dispatch(setFeedbackSent(true));
        this.setState(this.initialState);
    }

    _endPresentation() {
        const { uid, dispatch } = this.props;
        this.setState({ showError: false });
        this.setState({ endPresentation: true });
        dispatch(voipHangup());
        dispatch(
            writeToSocket({
                cmd: "leavePresentation",
                uid,
            })
        );
    }

    _validate() {
        const { participantName, conferenceRoomId, salt } = this.props;
        const { rating } = this.state;
        const valid = rating !== -1 && participantName !== "" && conferenceRoomId !== null && salt !== null;
        this.setState({ showError: !valid });
        return valid;
    }

    _close() {
        const { dispatch } = this.props;
        this.setState({ showError: false });
        dispatch(setModalVisibility(""));
    }

    render() {
        const {
            show,
            feedbackSent,
            thankYou,
            title,
            enterRating,
            rateExperience,
            commentPlaceHolder,
            phoneNumber,
            quit,
            sendAndQuit,
        } = this.props;
        const { endPresentation, showError, rating, comment, phone } = this.state;

        return feedbackSent && !endPresentation ? (
            <Modal
                show={show}
                onClose={this._close}
                title=""
                contentChildren={
                    <div>
                        <div>{thankYou}</div>
                    </div>
                }
            />
        ) : (
            <Modal
                show={show}
                onClose={this._close}
                title={title}
                contentChildren={
                    <div>
                        <div className={"messagebox error" + (showError ? "" : " hidden")}>{enterRating}</div>
                        <div>
                            <span>{rateExperience}</span>
                            <Rating default={rating} max={5} setRating={this._setRating} />
                            <div className="inputblock inputblock--big">
                                <textarea
                                    className="textarea-input"
                                    ref={(textarea) => {
                                        this.textarea = textarea;
                                        return textarea;
                                    }}
                                    rows="6"
                                    name="comment"
                                    value={comment}
                                    onChange={this._handleChange}
                                    placeholder={commentPlaceHolder}
                                />
                            </div>
                            <div className="inputblock inputblock--big">
                                <input
                                    className="input-field input-field--text"
                                    ref={(input) => {
                                        this.input = input;
                                        return input;
                                    }}
                                    type="text"
                                    name="phone"
                                    value={phone}
                                    onChange={this._handleChange}
                                    placeholder={phoneNumber}
                                />
                            </div>
                        </div>
                    </div>
                }
                buttonChildren={
                    <div className="btn-wrapper btn-wrapper--margin-top">
                        <div className="l-tab-col l-max-size">
                            <div
                                className="btn btn-grey"
                                style={{ marginRight: "0" }}
                                onClick={() => {
                                    this._endPresentation();
                                }}
                                role="button"
                                tabIndex="0"
                            >
                                {quit}
                            </div>
                            <div className="c-spacer-ver" />
                            <div
                                className="btn btn-cta"
                                style={{ marginLeft: "0" }}
                                onClick={() => {
                                    this._submit();
                                    if (this._validate()) this._endPresentation();
                                }}
                                role="button"
                                tabIndex="0"
                            >
                                {sendAndQuit}
                            </div>
                        </div>
                    </div>
                }
            />
        );
    }
}

FeedbackBox.defaultProps = {
    rating: -1,
    conferenceRoomId: null,
    salt: null,
};

FeedbackBox.propTypes = {
    show: PropTypes.bool.isRequired,
    participantName: PropTypes.string.isRequired,
    conferenceRoomId: PropTypes.string,
    salt: PropTypes.string,
    uid: PropTypes.string.isRequired,
    feedbackSent: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    enterRating: PropTypes.string.isRequired,
    rateExperience: PropTypes.string.isRequired,
    thankYou: PropTypes.string.isRequired,
    commentPlaceHolder: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    quit: PropTypes.string.isRequired,
    sendAndQuit: PropTypes.string.isRequired,
    rating: PropTypes.number,
    dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    show: state.modal.modalId === FEEDBACK_BOX,
    participantName: state.login.participantName,
    conferenceRoomId: state.login.conferenceRoomId,
    salt: state.login.salt,
    uid: state.login.uid,
    feedbackSent: state.modal.feedbackSent,
    title: translate("Feedback.Title"),
    enterRating: translate("Feedback.EnterRating"),
    rateExperience: translate("Feedback.RateExperience"),
    thankYou: translate("Feedback.Thankyou"),
    commentPlaceHolder: translate("Feedback.Comment"),
    phoneNumber: translate("Feedback.PhoneNumber"),
    quit: translate("Feedback.DontSendAndQuit"),
    sendAndQuit: translate("Feedback.SendAndQuit"),
});

export default connect(mapStateToProps)(FeedbackBox);
