import React, { Component } from "react";
import "../containers/start.css";

class IconStartMod extends Component {
    render() {
        return (
            <div className="starticon">
                <svg
                    version="1.1"
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlSpace="preserve"
                    viewBox="-10 -10 140 140"
                    width="140"
                    height="150"
                >
                    <defs>
                        <filter id="dropshadow" x="-20%" y="-20%" width="130%" height="130%">
                            <feGaussianBlur in="SourceAlpha" stdDeviation="5" />
                            <feOffset dx="0" dy="3" result="offsetblur" />
                            <feComponentTransfer>
                                <feFuncA type="linear" slope="0.6" />
                            </feComponentTransfer>
                            <feMerge>
                                <feMergeNode />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                    <g>
                        <circle className="st0" cx="60" cy="60" r="60" style={{ filter: "url(#dropshadow)" }} />

                        <path
                            className="st1"
                            d="M82.7,58.5L73.2,49c-0.8-0.8-2.1-0.2-2.1,0.9v5.2c0,6.8,0,5.5,0,8.1v5.7c0,1.1,1.3,1.6,2.1,0.9l9.5-9.5
            C83.1,59.8,83.1,59,82.7,58.5L82.7,58.5z"
                        />

                        <path
                            className="st1"
                            d="M65.9,74.2c0,0-16.1-8.1-15.4-9c2.2-1.9,3.6-4.3,4.2-7.2c0.4-0.1,0.8-0.4,1.2-0.8c0.5-0.5,0.8-1.2,0.8-2
            c0-0.8-0.3-1.5-0.8-2.1c-0.3-0.3-0.4-0.5-0.5-0.8c0.3-2,0.4-4,0.4-5.9c0-3.2-1.1-5.9-3.4-8c-2-2-4.4-3.1-7.1-3.3
            c-0.3,0-0.6,0-0.9,0c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6,0-0.9,0c-2.8,0.2-5.1,1.3-7.1,3.3c-2.2,2.2-3.4,4.9-3.3,8c0,2.1,0.1,4,0.4,5.8
            c0,0.4-0.2,0.7-0.5,1c-0.6,0.6-0.8,1.3-0.8,2.1c0,0.8,0.2,1.4,0.8,2c0.3,0.4,0.7,0.6,1.2,0.8c0.7,2.9,2.1,5.3,4.3,7.2
            c0.9,1-15.4,9-15.4,9s-3.1,1.4-3.1,3.1v4.6c0,1.7,1.4,3.1,3.1,3.1h43.1c1.7,0,3.1-1.4,3.1-3.1v-4.6C69,75.5,65.9,74.2,65.9,74.2z"
                        />
                        <path
                            className="st1"
                            d="M96.6,42.3H57.7c0.2,0.8,0.4,1.6,0.5,2.4h38.4c0.7,0,1.2,0.6,1.2,1.2v26.8c0,0.7-0.6,1.2-1.2,1.2H69.9
            c0.6,0.7,1.2,1.5,1.4,2.4h25.3c2,0,3.7-1.6,3.7-3.7V46C100.2,44,98.6,42.3,96.6,42.3z"
                        />
                    </g>
                </svg>
            </div>
        );
    }
}

export default IconStartMod;
