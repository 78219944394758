import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "../../styles/recordinginformation.css";

const RecordingInformationComponent = ({ show }) =>
    show ? (
        <div className="information">
            <div className="information-elements">
                <span className="information-elements__rec-symbol" />
                <span className="information-elements__rec-text">REC</span>
            </div>
        </div>
    ) : null;

RecordingInformationComponent.propTypes = {
    show: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    show: state.conference.globalRecording && !state.login.isOperator,
});

const RecordingInformation = connect(mapStateToProps)(RecordingInformationComponent);

export default RecordingInformation;
