import { connect } from "react-redux";
import { translate } from "../../lib/Translator";

import "../../constants/stylesConstants.css";

import SidebarButton from "../../components/SidebarButton";
import { setSidebarContentVisibility, setSidebarContentId, resetSettings } from "../../actions";
import { PARTICIPANT_LIST } from "../../constants/sidebarConstants";

const mapStateToProps = (state) => ({
    show: state.settings.participantListEnabled || state.login.isOperator,
    active: state.modal.sidebarContentId === PARTICIPANT_LIST && state.modal.showSidebarContent,
    liClassname: "participantlistfullscreen",
    actionClassname: "action-participantlist",
    tooltipText: translate("ParticipantList.Title"),
});

const mapDispatchToProps = (dispatch) => ({
    onClick: (active) => {
        dispatch(resetSettings());
        dispatch(setSidebarContentId(PARTICIPANT_LIST));
        setTimeout(() => {
            dispatch(setSidebarContentVisibility(active));
        }, 1); // Hack to preserve transition animation
    },
});

const SidebarButtonParticipantList = connect(mapStateToProps, mapDispatchToProps)(SidebarButton);

export default SidebarButtonParticipantList;
