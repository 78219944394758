import { ActionTypes } from "redux-mw-ws";
import { getHoursAndMinutes } from "../lib/utils";

export const chatInitialState = {
    chatMessages: [],
    numberOfLastReadMessage: 0,
    numberOfUnreadMessages: 0,
    charsLeft: -1,
};

const chat = (state = chatInitialState, action) => {
    switch (action.type) {
        case ActionTypes.RECEIVED_WEBSOCKET_DATA:
            if (action.payload.cmd === "sendChatMessage") {
                const a = { ...action };
                return {
                    ...state,
                    chatMessages: [
                        ...state.chatMessages,
                        {
                            id: state.chatMessages.length + 1,
                            from: a.payload.from,
                            chatMessage: a.payload.chatMessage,
                            time: getHoursAndMinutes(),
                        },
                    ],
                };
            }
            return state;
        case "SET_NUMBER_OF_LAST_READ_MESSAGE":
            return {
                ...state,
                numberOfLastReadMessage:
                    action.numberOfLastReadMessage === "MAX"
                        ? state.chatMessages.length
                        : action.numberOfLastReadMessage,
            };
        case "SET_NUMBER_OF_UNREAD_MESSAGES":
            return {
                ...state,
                numberOfUnreadMessages: action.numberOfUnreadMessages,
            };
        case "SET_CHAT_MESSAGE_CHARS_LEFT":
            return {
                ...state,
                charsLeft: action.charsLeft,
            };
        default:
            return state;
    }
};

export default chat;
