import { connect } from "react-redux";

import { INVITATION_SCREEN, INVITATION_LINK } from "../../constants/modalConstants";
import SidebarButton from "../../components/SidebarButton";
import { setModalVisibility, setSelectedButton, setModalContent } from "../../actions";
import { translate } from "../../lib/Translator";

const onClick = () => (dispatch) => {
    dispatch(setModalVisibility(INVITATION_SCREEN));
    dispatch(setSelectedButton(INVITATION_LINK));
    dispatch(setModalContent(INVITATION_LINK));
};

const mapStateToProps = (state) => ({
    show: state.login.isOperator,
    active: state.modal.modalId === INVITATION_SCREEN,
    liClassname: "invitefullscreen",
    actionClassname: "action-invite",
    tooltipText: translate("Invite.Title"),
});

const mapDispatchToProps = {
    onClick,
};

const SidebarButtonParticipantList = connect(mapStateToProps, mapDispatchToProps)(SidebarButton);

export default SidebarButtonParticipantList;
