import React, { Component } from "react";
import PropTypes from "prop-types";

import IconStar from "./IconStar";

import "../../../styles/main.css";
import "../../../styles/modal.css";
import "../../../styles/feedbackstars.css";

class Rating extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // default: props.default,
            max: props.max,
            rating: -1,
            hoverIndex: -1,
        };
    }

    hoverStar = (index) => {
        this.setState({ hoverIndex: index });
    };

    leaveStar = () => {
        this.setState({ hoverIndex: -1 });
    };

    clickStar = (index) => {
        const { setRating } = this.props;
        this.setState({ rating: index });
        setRating(index);
    };

    render() {
        const { max, hoverIndex, rating } = this.state;
        const stars = [];
        for (let i = 1; i <= max; i += 1) {
            const active = hoverIndex !== -1 ? i <= hoverIndex : i <= rating;
            stars.push(
                <IconStar
                    key={i}
                    active={active}
                    click={this.clickStar}
                    mouseLeave={this.leaveStar}
                    mouseOver={this.hoverStar}
                    index={i}
                />
            );
        }

        return <div className="feedbackblock">{stars}</div>;
    }
}

Rating.propTypes = {
    setRating: PropTypes.func.isRequired,
    max: PropTypes.number.isRequired,
};

export default Rating;
