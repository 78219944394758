import { ActionTypes } from "redux-mw-ws";

import { LOGIN_ERROR_HOST } from "../constants/commonConstants";

export const loginInitialState = {
    isOperator: false,
    pin: "",
    participantName: "",
    login: false,
    isOver: false,
    wasKicked: false,
    isPresenter: false,
    uid: "",
    presenterRevoked: false,
    sessionId: "",
    matchingId: -1,
    makePresenterInitiatorId: null,
    maxParticipants: 0,
    guestPin: "",
    dialinDTMF: false,
    allowScreenshare: false,
    presenterRevokedBy: "",
    presentationIsOverReason: "",
    pinShown: false,
    reconnecting: false,
};

const windowOnBeforeUnload = () => {
    if (!(window && window.process && window.process.type)) {
        window.onbeforeunload = (e) => {
            const dialogText = "Wollen Sie die Konferenz beenden?";
            e.returnValue = dialogText;
            return dialogText;
        };
    }
};

const handleLogin = (state, payload) => {
    let ret = { ...state };
    if (payload.success) {
        windowOnBeforeUnload();
        const d = { ...payload };
        delete d.turn;
        delete d.cmd;
        delete d.dialinNumbers;
        delete d.countryNumbers;
        delete d.dialinNumbersPdfLink;
        delete d.product;
        delete d.dialOutAllowed;
        delete d.dialOutGuestAllowed;
        delete d.bridgeType;
        delete d.newFeatures;
        delete d.showNewFeatures;
        ret = {
            ...state,
            ...d,
            login: true,
        };
    } else if (!payload.success && payload.error === LOGIN_ERROR_HOST) {
        ret = {
            ...state,
            isOperator: false,
            pin: payload.guestcode,
        };
    }
    return ret;
};

const handleParticipants = (state, payload) => {
    const ret = { ...state };
    // own name changed
    payload.value.forEach((p) => {
        if (p.uid === state.uid) {
            ret.participantName = p.name;
        }
    });
    return ret;
};

const handleRename = (state, payload) => {
    let ret = { ...state };
    const data = { ...payload };
    if (data.success && data.value !== null && data.value !== undefined) {
        ret = {
            ...ret,
            participantName: data.value,
        };
    }
    return ret;
};

const handleBecomePresenter = (state, payload) => {
    let ret = { ...state, isPresenter: true };
    const data = { ...payload };
    if (state.presenterRevoked || data.initiatorUid === state.uid) {
        ret = {
            ...ret,
            presenterRevoked: false,
        };
    }
    return {
        ...ret,
        makePresenterInitiatorId: data.initiatorUid,
    };
};

const login = (state = loginInitialState, action) => {
    let a = null;
    switch (action.type) {
        case ActionTypes.RECEIVED_WEBSOCKET_DATA:
            if (action.payload.cmd === "login") {
                return handleLogin(state, action.payload);
            }
            if (action.payload.cmd === "participants") {
                return handleParticipants(state, action.payload);
            }
            if (action.payload.cmd === "rename") {
                return handleRename(state, action.payload);
            }
            if (action.payload.cmd === "becomePresenter") {
                return handleBecomePresenter(state, action.payload);
            }
            if (action.payload.cmd === "presenterRevoked") {
                return {
                    ...state,
                    presenterRevokedBy: action.payload.presenterRevokedBy,
                    isPresenter: false,
                };
            }
            if (action.payload.cmd === "presentationOver") {
                return {
                    ...state,
                    presentationIsOverReason: action.payload.reason,
                };
            }
            return state;
        case "START_LOGIN":
            return {
                ...state,
                isOperator: action.isOperator,
            };
        case "RESET_LOGIN":
            a = { ...action };
            delete a.type;
            return {
                ...state,
                ...a,
            };
        case "SET_PIN":
            return {
                ...state,
                pin: action.pin,
            };
        case "SHOW_PIN":
            return {
                ...state,
                pinShown: action.pinShown,
            };
        case "SET_RECONNECTING":
            return {
                ...state,
                reconnecting: action.reconnecting,
            };
        default:
            return state;
    }
};

export default login;
