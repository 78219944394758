/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import autosize from "autosize";

import { writeToSocket, setChatMessageCharsLeft } from "../../actions";
import { translate } from "../../lib/Translator";
import { countChars } from "../../lib/utils";

import { MAX_CHAT_MESSAGE_LENGTH, MAX_CHAT_MESSAGE_LENGTH_HINT_BORDER } from "../../constants/commonConstants";

import "../../styles/layout.css";
import "./chatListControl.css";

const ChatListControlComponent = ({ placeholder, showMessageHint, charsLeftMsg, sendMessageFn, setCharsLeftFn }) => {
    let chatMessageInput = null;
    useEffect(() => {
        const ta = document.getElementsByClassName("c-text-input__field")[0];
        if (ta) {
            ta.focus();
            autosize(ta);
        }
    }, []);
    return (
        <form
            className="chat-control"
            onSubmit={(e) => {
                sendMessageFn(chatMessageInput);
                e.preventDefault();
                return false;
            }}
        >
            <div className="chat-message-input chat-control-input--min-max">
                <div className="l-tab-row">
                    <div className="l-tab__item-grow ">
                        <div className="c-text-input">
                            <textarea
                                className={`c-text-input__field ${
                                    showMessageHint
                                        ? "c-text-input__field--max-height-small"
                                        : "c-text-input__field--max-height-big"
                                }`}
                                placeholder={placeholder}
                                ref={(node) => {
                                    chatMessageInput = node;
                                }}
                                rows={1}
                                maxLength={MAX_CHAT_MESSAGE_LENGTH}
                                onKeyDown={(e) => {
                                    if (!e.shiftKey && e.keyCode === 13) {
                                        sendMessageFn(chatMessageInput);
                                        e.preventDefault();
                                    }
                                }}
                                onKeyUp={() => {
                                    setCharsLeftFn(chatMessageInput.value);
                                }}
                                onPaste={() => {
                                    setCharsLeftFn(chatMessageInput.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="l-tab-col">
                        <div className="l-tab__item-grow" />
                        <div
                            className="chat-message-input__send-button"
                            onClick={() => {
                                document.getElementById("sendChatMessage").click();
                            }}
                            role="button"
                            tabIndex="0"
                        />
                    </div>
                </div>
            </div>
            {showMessageHint ? <div className="chat-message-hint">{charsLeftMsg}</div> : null}
            <input className="input-field--submit" id="sendChatMessage" type="submit" />
        </form>
    );
};

ChatListControlComponent.propTypes = {
    placeholder: PropTypes.string.isRequired,
    showMessageHint: PropTypes.bool.isRequired,
    charsLeftMsg: PropTypes.string.isRequired,
    sendMessageFn: PropTypes.func.isRequired,
    setCharsLeftFn: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    placeholder: translate("Chat.EnterMessage"),
    showMessageHint: state.chat.charsLeft !== -1,
    charsLeftMsg: translate("Chat.CharsLeft", [state.chat.charsLeft]),
});

const sendMessageFn = (chatMessageInput) => (dispatch, getState) => {
    let chatMessage = chatMessageInput.value;
    if (chatMessage) {
        chatMessage = chatMessage.trim();
        if (chatMessage !== "") {
            dispatch(
                writeToSocket({
                    cmd: "sendChatMessage",
                    from: getState().login.participantName,
                    chatMessage,
                })
            );
            dispatch(setChatMessageCharsLeft(-1));
            chatMessageInput.style.height = getComputedStyle(chatMessageInput).getPropertyValue(
                "--chat-message-height"
            );
            chatMessageInput.value = "";
        }
    }
};

const setCharsLeftFn = (chatMessage) => (dispatch) => {
    if (chatMessage) {
        const charsLeft = MAX_CHAT_MESSAGE_LENGTH - countChars(chatMessage);
        if (charsLeft < MAX_CHAT_MESSAGE_LENGTH_HINT_BORDER) dispatch(setChatMessageCharsLeft(charsLeft));
        else dispatch(setChatMessageCharsLeft(-1));
    }
};

const mapDispatchToProps = {
    sendMessageFn,
    setCharsLeftFn,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatListControlComponent);
