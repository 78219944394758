import { connect } from "react-redux";
import Participant from "../../components/Participant";

const mapStateToProps = (state, ownProps) => ({
    host: false,
    presenting: false,
    onHold: ownProps.p.onHold,
    mute: ownProps.p.mute,
    talking: ownProps.p.talking,
    titleText: ownProps.p.phone,
    presentationActive: false,
});

const ParticipantBridge = connect(mapStateToProps)(Participant);

export default ParticipantBridge;
