import { translate } from "./Translator";

const _showNotification = (title, body) => {
    if ("Notification" in window) {
        if (window.Notification.permission !== undefined && Notification.permission === "granted") {
            try {
                new Notification(title, { body }); // eslint-disable-line no-new
            } catch (error) {
                // TODO: notification not possible, happens in chrome for android see: https://gitlab.com/ubivent/ubivent/issues/859
            }
        }
    }
};

class NotificationHandler {
    constructor(store) {
        this.store = store;
    }

    triggerNotification(receivedParticipants) {
        // deep copy
        const parts = this.store
            .getState()
            .participants.participants.filter((p) => p.type === "web" || p.type === "matched")
            .map((p) => p);
        // don't check on first participant list
        if (parts.length > 0) {
            receivedParticipants
                .filter((rp) => rp.type === "web" || rp.type === "matched")
                .forEach((a) => {
                    const idx = parts.findIndex((b) => {
                        if (a.uid === b.uid) return true;
                        return false;
                    });
                    if (idx < 0) {
                        _showNotification(translate("Notification.ParticipantEntering"), a.name);
                    } else {
                        parts.splice(idx, 1);
                    }
                });
            parts.forEach((p) => {
                _showNotification(translate("Notification.ParticipantLeaving"), p.name);
            });
        }
    }
}

export default NotificationHandler;
