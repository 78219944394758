import { connect } from "react-redux";

import { REVOKE_PRESENTER_BOX } from "../../constants/modalConstants";
import DropDownMenuEntry from "../../components/DropDownMenuEntry";
import { setModalVisibility, setSelectedUser } from "../../actions";
import { translate } from "../../lib/Translator";

const mapStateToProps = () => ({
    show: true,
    text: translate("RevokePresenter.Title"),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    mouseDown: () => {
        dispatch(setModalVisibility(REVOKE_PRESENTER_BOX));
        dispatch(setSelectedUser(ownProps.selectedUserName, ownProps.selectedUID));
    },
});

const DropDownMenuEntryRevokePresenter = connect(mapStateToProps, mapDispatchToProps)(DropDownMenuEntry);

export default DropDownMenuEntryRevokePresenter;
