import { connect } from "react-redux";

import SidebarButton from "../../components/SidebarButton";
import { FEEDBACK_BOX } from "../../constants/modalConstants";
import { translate } from "../../lib/Translator";
import { setModalVisibility, setFullscreenMode } from "../../actions";
import { toggleFullScreen } from "./SidebarButtonFullscreen";
import { isFullScreen } from "../../lib/utils";

const mapStateToProps = () => ({
    show: true, // not evaluated
    active: false, // not evaluated
    liClassname: "quit",
    actionClassname: "action-quit",
    tooltipText: translate("Sidebar.LeaveConference"),
});

const mapDispatchToProps = (dispatch) => ({
    onClick: () => {
        if (isFullScreen()) {
            dispatch(toggleFullScreen(false));
            dispatch(setFullscreenMode(false));
        }
        dispatch(setModalVisibility(FEEDBACK_BOX));
    },
});

const SidebarButtonSettings = connect(mapStateToProps, mapDispatchToProps)(SidebarButton);

export default SidebarButtonSettings;
