import { connect } from "react-redux";

import ListHeader from "../../components/ListHeader";
import { setMatchedParticipantVisibility } from "../../actions";
import { translate } from "../../lib/Translator";

const onClick = () => (dispatch, getState) => {
    dispatch(setMatchedParticipantVisibility(!getState().modal.showMatchedParticipants));
};

const mapStateToProps = (state) => ({
    show: state.participants.matched.length !== 0,
    showSidebarContent: state.modal.showMatchedParticipants,
    title: translate("ParticipantList.Matched"),
});

const mapDispatchToProps = {
    click: onClick,
};

const HeaderMatched = connect(mapStateToProps, mapDispatchToProps)(ListHeader);

export default HeaderMatched;
