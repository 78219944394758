import { FEEDBACK_HOST } from "../constants/commonConstants";
import { setInvitationMail } from "../actions";

class MeetyooApi {
    constructor(store) {
        this.store = store;
    }

    setMailText(mailText, product, dialinNumbersPdfLink, guestPin, dialinNumbers) {
        let text = mailText;
        // https://www.npmjs.com/package/html-to-text
        text = text.replace(
            "[LINK]",
            "https://" + window.location.host + window.location.pathname + "#guest/" + guestPin
        );
        text = text.replace("[GUEST_PIN]", guestPin);
        text = text.replace(
            "[DIALIN_NUMBERS]",
            dialinNumbers
                .map(
                    (part, index) =>
                        MeetyooApi.starNumber(product, part.location + " (" + part.language + ") " + part.number) +
                        (index < dialinNumbers.length - 1 ? "\n" : "")
                )
                .join("")
        );
        text = text.replace(
            "[MORE_DIALIN_NUMBERS]",
            dialinNumbersPdfLink !== null && dialinNumbersPdfLink !== undefined ? dialinNumbersPdfLink : ""
        );
        this.store.dispatch(setInvitationMail(text));
        // }
    }

    static starNumber = (product, number) => {
        if (product.toLowerCase() === "meetgreen" || product.toLowerCase() === "meetgreenplus") {
            if (number.trim().includes("868757757")) {
                return number + "*";
            }
            if (number.trim().includes("535353")) {
                return number + "**";
            }
        }
        return number;
    };

    static postFeedback(conferenceRoomId, salt, postData) {
        return new Promise((resolve, reject) => {
            const req = new Request(
                "https://" +
                    FEEDBACK_HOST +
                    "/feedback/v1/conferencerooms/" +
                    conferenceRoomId +
                    "/ratings;salt=" +
                    salt,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    mode: "cors",
                    body: JSON.stringify(postData),
                }
            );
            // console.log("req: %o", req);
            fetch(req)
                .then((resp) => resp.json())
                .then((json) => resolve(json))
                .catch((error) => reject(error));
        });
    }
}

export default MeetyooApi;
