/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getLanguage } from "../../lib/utils";

import { WELCOME_SCREEN, MAX_TOUR_INDEX, CONNECTIONS_SCREEN } from "../../constants/modalConstants";
import {
    setModalVisibility,
    incrementTourIndex,
    decrementTourIndex,
    showWelcomeTour,
    setFeatureTextIndex,
} from "../../actions";
import { translate } from "../../lib/Translator";
import Button from "../basics/buttons/Button";

import "../../styles/layout.css";
import "../../styles/common.css";
import "./welcomeScreen.css";

const getTourAction = (paddingClass, tourIndex, title, text, btnAcceptTitle, btnBackTitle, onClose) => (
    <div
        className={`c-tour-action ${paddingClass} ${tourIndex === 0 ? "c-tour-action--start" : "c-tour-action--tour"}`}
    >
        <div className={`l-tab-col ${tourIndex !== 0 ? "l-tab-col--grow-y" : ""}`}>
            <div className="c-tour-action-title">{title}</div>
            <div className="c-tour-action-text">{text}</div>
            {tourIndex !== 0 ? <div className="l-tab__item-grow" /> : null}
            {tourIndex === 0 ? (
                <div className="l-tab-col">
                    <Button
                        title={btnAcceptTitle}
                        btnSize="c-btn--long"
                        btnColor="c-btn--branded"
                        onClickFn={incrementTourIndex}
                    />
                </div>
            ) : (
                <div className="l-tab-row">
                    <div className="l-tab__item-grow" />
                    {tourIndex > 1 ? (
                        <Button
                            title={btnBackTitle}
                            btnSize="c-btn--small"
                            btnColor="c-btn--grey"
                            onClickFn={decrementTourIndex}
                        />
                    ) : null}
                    <div className="c-btn-devider" />
                    <Button
                        title={btnAcceptTitle}
                        btnSize="c-btn--small"
                        btnColor="c-btn--branded"
                        onClickFnDispatchIncluded={tourIndex === MAX_TOUR_INDEX}
                        onClickFn={tourIndex < MAX_TOUR_INDEX ? incrementTourIndex : onClose}
                    />
                </div>
            )}
            {tourIndex !== 0 ? <div className="c-btn-spacer" /> : null}
        </div>
    </div>
);

const getTourPicture = (tourIndex) => (
    <div className="c-tour-image">
        <div
            className={`c-tour-image__image-${tourIndex} ${tourIndex === 5 ? "c-tour-image__image--left-margin" : ""}`}
        />
    </div>
);

const createProgressSteps = (tourIndex) => {
    const steps = [];
    for (let i = 1; i <= MAX_TOUR_INDEX; i += 1) {
        steps.push(
            <div
                key={i}
                className={`c-progress-step ${
                    i === tourIndex ? "c-progress-step--active" : "c-progress-step--inactive"
                }`}
            />
        );
    }
    return (
        <div className="c-progress">
            <div className="l-tab-row">{steps}</div>
        </div>
    );
};

const featureText = (featureTextIndex, newFeatures) => {
    if (newFeatures[featureTextIndex % newFeatures.length][getLanguage()] === "no-entry") {
        if (newFeatures[featureTextIndex % newFeatures.length].en === "no-entry") {
            return null;
        }
        return newFeatures[featureTextIndex % newFeatures.length].en;
    }
    return newFeatures[featureTextIndex % newFeatures.length][getLanguage()];
};

const createNewFeatureContent = (show, newText, featureTextIndex, newFeatures) =>
    show ? (
        <div className="c-new">
            <div className="l-tab-row">
                <div className="c-new__symbol">
                    <div className="l-center-xy l-max-size">
                        <div className="c-symbol-text">{newText}</div>
                    </div>
                </div>
                <div className="c-new__text">{featureText(featureTextIndex, newFeatures)}</div>
            </div>
        </div>
    ) : (
        <div className="c-new--empty" />
    );

const WelcomeScreenComponent = ({
    show,
    tourIndex,
    tourTitle,
    tourText,
    btnAcceptTitle,
    btnBackTitle,
    checkTourText,
    showNewFeatures,
    newText,
    featureTextIndex,
    newFeatures,
    onChangeFn,
    onClose,
    changeFeatureText,
}) => {
    useEffect(() => {
        changeFeatureText();
    }, [changeFeatureText]);
    return show ? (
        <div>
            <div className="l-layer-1">
                <div className="c-darken" />
            </div>
            <div className="l-layer-2 l-center-xy">
                <div className="c-welcome">
                    <div className="c-welcome-tour">
                        <div className="l-tab-row">
                            <div className="l-tab__item-grow" />
                            <div className="c-window-action-cross" onClick={onClose} role="button" tabIndex="0" />
                        </div>
                        <div className="l-tab-row">
                            <div className="l-center-y">
                                {tourIndex === 0
                                    ? getTourAction(
                                          "c-tour--padding-left",
                                          tourIndex,
                                          tourTitle,
                                          tourText,
                                          btnAcceptTitle,
                                          btnBackTitle,
                                          onClose
                                      )
                                    : getTourPicture(tourIndex)}
                            </div>
                            <div className="l-tab__item-grow" />
                            <div className=" l-center-y">
                                {tourIndex === 0
                                    ? getTourPicture(tourIndex)
                                    : getTourAction(
                                          "c-tour--padding-right",
                                          tourIndex,
                                          tourTitle,
                                          tourText,
                                          btnAcceptTitle,
                                          btnBackTitle,
                                          onClose
                                      )}
                            </div>
                        </div>
                    </div>
                    <div className={`c-welcome-footer ${tourIndex !== 0 ? "c-welcome-footer--tour" : ""}`}>
                        <div className="l-center-xy l-max-size">
                            {tourIndex !== 0
                                ? createProgressSteps(tourIndex)
                                : createNewFeatureContent(showNewFeatures, newText, featureTextIndex, newFeatures)}
                        </div>
                        {tourIndex === 0 ? (
                            <div className="c-tour-check">
                                <div className="l-tab-row">
                                    <input
                                        className="c-tour-check__check-box"
                                        type="checkbox"
                                        onChange={(e) => onChangeFn(e.target.checked)}
                                    />
                                    <div className="c-tour-check__text">{checkTourText}</div>
                                </div>
                            </div>
                        ) : null}
                        <input className="c-welcome-input-hidden" id="cb-tour-hidden" type="checkbox" />
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

WelcomeScreenComponent.defaultProps = {
    showNewFeatures: false,
    newFeatures: [],
    changeFeatureText: null,
};

WelcomeScreenComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    tourIndex: PropTypes.number.isRequired,
    tourTitle: PropTypes.string.isRequired,
    tourText: PropTypes.string.isRequired,
    btnAcceptTitle: PropTypes.string.isRequired,
    btnBackTitle: PropTypes.string.isRequired,
    checkTourText: PropTypes.string.isRequired,
    showNewFeatures: PropTypes.bool,
    newText: PropTypes.string.isRequired,
    featureTextIndex: PropTypes.number.isRequired,
    newFeatures: PropTypes.array,
    onChangeFn: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    changeFeatureText: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
};

const mapStateToProps = (state) => {
    let acceptTitle = null;
    if (state.modal.tourIndex === 0) acceptTitle = translate("Welcome.btnTourStartTitle");
    else if (state.modal.tourIndex === MAX_TOUR_INDEX) acceptTitle = translate("Welcome.Finish");
    else acceptTitle = translate("Welcome.btnForwardTitle");

    return {
        show: state.modal.modalId === WELCOME_SCREEN && state.modal.showTour,
        tourIndex: state.modal.tourIndex,
        tourTitle: translate("Welcome.TourTitle" + state.modal.tourIndex),
        tourText: translate("Welcome.TourText" + state.modal.tourIndex),
        btnAcceptTitle: acceptTitle,
        btnBackTitle: translate("Welcome.btnBackTitle"),
        checkTourText: translate("Welcome.tourCheck"),
        showNewFeatures: state.modal.showNewFeatures,
        newText: translate("Welcome.NewBlock"),
        featureTextIndex: state.modal.featureTextIndex,
        newFeatures: state.modal.newFeatures,
    };
};

const mapDispatchToProps = {
    onClose: () => (dispatch, getState) => {
        dispatch(showWelcomeTour(!document.getElementById("cb-tour-hidden").checked, getState().login.participantName));
        if (getState().modal.welcomeTourFromLogin) dispatch(setModalVisibility(CONNECTIONS_SCREEN));
        else dispatch(setModalVisibility(""));
    },
    onChangeFn: (checked) => () => {
        document.getElementById("cb-tour-hidden").checked = checked;
    },
    changeFeatureText: () => (dispatch, getState) => {
        setInterval(() => {
            if (getState().modal.showNewFeatures) dispatch(setFeatureTextIndex(getState().modal.featureTextIndex + 1));
        }, 5000);
    },
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeScreenComponent);
