import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { VOIP_FAILED } from "../../constants/modalConstants";

import { translate } from "../../lib/Translator";
import "./voipFailed.css";

const VoipFailedComponent = ({ show, text }) =>
    show ? (
        <div className="l-tab-col">
            <div className="c-warn">{text}</div>
        </div>
    ) : null;

VoipFailedComponent.defaultProps = {
    text: null,
};

VoipFailedComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    text: PropTypes.string,
};

const mapStateToProps = (state) => ({
    show: state.modal.modalContent === VOIP_FAILED,
    text: translate("VoipConnect.Failed"),
});

const VoipFailed = connect(mapStateToProps)(VoipFailedComponent);

export default VoipFailed;
