import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { voipHangup, voipToggleMute } from "../../actions";

import "../../styles/voipcontrols.css";

const VoipControlsComponent = ({ voipConnectionStatus, showFullscreenMode, isMuted, hangup, toggleMute }) => {
    if (!voipConnectionStatus) {
        return null;
    }
    return (
        <div className={`control ${showFullscreenMode ? "control--fullscreen" : "control--normalsize"}`}>
            <div className="control-action control-action--mute" onClick={toggleMute} role="button" tabIndex="0">
                <span
                    className={`control-action__element ${
                        isMuted ? "control-action__element--muted" : "control-action__element--unmuted"
                    }`}
                />
            </div>
            <div className="control-action control-action__placeholder" />
            <div className="control-action control-action--hangup" onClick={hangup} role="button" tabIndex="0">
                <span className="control-action__element control-action__element--hangup" />
            </div>
        </div>
    );
};

VoipControlsComponent.propTypes = {
    voipConnectionStatus: PropTypes.bool.isRequired,
    showFullscreenMode: PropTypes.bool.isRequired,
    isMuted: PropTypes.bool.isRequired,
    hangup: PropTypes.func.isRequired,
    toggleMute: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    voipConnectionStatus: state.telco.voipConnectionStatus,
    showFullscreenMode: state.modal.showFullscreenMode,
    isMuted: state.telco.voipMuteStatus,
});

const mapDispatchTpProps = {
    hangup: voipHangup,
    toggleMute: voipToggleMute,
};

const VoipControls = connect(mapStateToProps, mapDispatchTpProps)(VoipControlsComponent);

export default VoipControls;
