import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import "./header.css";

const HeaderComponent = ({ show }) =>
    show ? (
        <div className="header-block">
            <div className="header-block__logo" />
        </div>
    ) : null;

HeaderComponent.propTypes = {
    show: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    show: !state.login.login,
});

const Header = connect(mapStateToProps)(HeaderComponent);

export default Header;
