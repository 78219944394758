import { connect } from "react-redux";

import InputNumberContent from "../../../components/modal/InputNumberContent";

const mapStateToProps = (state, ownProps) => ({
    inputLabel: ownProps.inputLabel,
    prefix: ownProps.prefix,
    placeholder: ownProps.placeholder,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    inputOnChange: (number) => {
        dispatch(ownProps.func(number.target.value));
    },
});

const InputPhoneNumber = connect(mapStateToProps, mapDispatchToProps)(InputNumberContent);

export default InputPhoneNumber;
