import React from "react";
import { connect } from "react-redux";

import { CONNECTIONS_SCREEN, PHONE_DIALOUT_BOX } from "../../constants/modalConstants";
import { setModalVisibility } from "../../actions";
import { translate } from "../../lib/Translator";

import MultiModalComponent from "../../components/modal/MultiModal";

import ConnectionBar from "../basics/buttons/ConnectionBar";
import ConnectionDialin from "./ConnectionDialin";
import ConnectionDialout from "./ConnectionDialout";
import Spinner from "./Spinner";
import VoipFailed from "./VoipFailed";

const mapStateToProps = (state) => ({
    show: state.modal.modalId === CONNECTIONS_SCREEN,
    title: translate("Connections.Title"),
    categories: <ConnectionBar />,
    content: [
        <ConnectionDialin key="dialin" />,
        <ConnectionDialout key="dialout" />,
        <Spinner
            key="spinner"
            text={
                state.modal.selectedConnectionButton === PHONE_DIALOUT_BOX
                    ? translate("PhoneDialout.Connecting")
                    : translate("VoipConnect.Connecting")
            }
        />,
        <VoipFailed key="voipFailed" />,
    ],
});

const mapDispatchToProps = {
    onClose: () => setModalVisibility(""),
};

const Connections = connect(mapStateToProps, mapDispatchToProps)(MultiModalComponent);

export default Connections;
