import React from "react";

import { translate } from "./Translator";

const wsHost = process.env.REACT_APP_WS_HOST || "manama:8443";

export const getBrandingType = () => {
    const ar = window.location.pathname.split("/");
    let last = ar[ar.length - 1];
    if (last === "" || last === "index.html") last = "meetyoo";
    return last;
};

export const applyBrandingStyles = (product) => {
    const head = document.head || document.getElementsByTagName("head")[0];
    const stylesheet = document.createElement("link");

    stylesheet.type = "text/css";
    stylesheet.rel = "stylesheet";
    stylesheet.href = `https://${wsHost}/myweb/v1/templates?action=branding&project=myweb&product=${product}`;

    head.appendChild(stylesheet);
};

export const applyBrandingStylesFallback = (css) => {
    const head = document.head || document.getElementsByTagName("head")[0];
    const style = document.createElement("style");

    style.type = "text/css";
    if (style.styleSheet) {
        style.styleSheet.cssText = css;
    } else {
        style.appendChild(document.createTextNode(css));
    }
    head.appendChild(style);
};

export const checkBrowser = () => {
    const isOpera = !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;
    const isFirefox = typeof InstallTrigger !== "undefined";
    // const isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf("Constructor") > 0;
    const isSafari =
        navigator.vendor &&
        navigator.vendor.indexOf("Apple") > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf("CriOS") === -1 &&
        navigator.userAgent.indexOf("FxiOS") === -1;
    const isEdge = window.navigator.userAgent.indexOf("Edge") > -1;
    const isChrome = !!window.chrome && !isOpera && !isEdge;
    const isIE = /* @cc_on!@ */ false || !!document.documentMode; // At least IE6
    let ieVersion = 0;
    if (isIE) {
        let rv = -1;
        if (navigator.appName === "Microsoft Internet Explorer") {
            const ua = navigator.userAgent;
            const re = new RegExp("MSIE ([0-9]{1,}[.0-9]{0,})");
            if (re.exec(ua) != null) rv = parseFloat(RegExp.$1);
        } else if (navigator.appName === "Netscape") {
            const ua = navigator.userAgent;
            const re = new RegExp("Trident/.*rv:([0-9]{1,}[.0-9]{0,})");
            if (re.exec(ua) != null) {
                rv = parseFloat(RegExp.$1);
            }
        }
        ieVersion = rv;
    }
    return {
        isOpera,
        isFirefox,
        isSafari,
        isEdge,
        isChrome,
        isIE,
        ieVersion,
        isMac: navigator.userAgent.indexOf("Mac OS X") !== -1,
    };
};

export const isIE11 = () => !!window.MSInputMethodContext && !!document.documentMode;

export const isElectron = () => !!(window && window.process && window.process.type);

export const getOS = () => {
    const { userAgent } = window.navigator;
    const { platform } = window.navigator;
    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
    const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
    const iosPlatforms = ["iPhone", "iPad", "iPod"];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = "Mac OS";
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "iOS";
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "Windows";
    } else if (/Android/.test(userAgent)) {
        os = "Android";
    } else if (!os && /Linux/.test(platform)) {
        os = "Linux";
    }

    return os;
};

export const sortParticipants = (participants, uid) =>
    participants.sort((a, b) => {
        if (a.uid === uid) {
            // Current User?
            return -1;
        }
        if (b.uid === uid) {
            // Current User?
            return 1;
        }

        let tmp = 0;
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
            tmp = -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
            tmp = 1;
        }
        return tmp; // Alphabetical
    });

export const toTitleCase = (str) =>
    str.toLowerCase().replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

export const isFullScreen = () => {
    let ret = false;
    if (window.navigator.userAgent.indexOf("Chrome/") > -1) {
        if (document.webkitFullscreenElement) {
            ret = true;
        }
    }
    if (window.navigator.userAgent.indexOf("Firefox/") > -1) {
        if (document.mozFullScreenElement) {
            ret = true;
        }
    }
    if (isIE11()) {
        if (document.msFullscreenElement) {
            ret = true;
        }
    }
    return ret;
};

export const formatUserLanguage = (userLanguage) => {
    let formattedLanguage = userLanguage;
    const languages = formattedLanguage.split("-");
    if (languages.length > 1) {
        [formattedLanguage] = languages;
    }
    return formattedLanguage;
};

// usage: removeUnitFromCssVariable("--sidebar-content-transition-time"));
export const removeUnitFromCssVariable = (cssVarName) => {
    let style = getComputedStyle(document.body);
    style = style.getPropertyValue(cssVarName);
    if (style.includes("ms")) {
        return style.slice(0, -2);
    }
    return cssVarName;
};

export const getLanguage = () => formatUserLanguage(window.navigator.userLanguage || window.navigator.language);

const excludedBrowsers = () => {
    const b = checkBrowser();
    return b.isSafari || b.isIE || b.ieVersion || b.isEdge;
};

export const canReceiveWebRtc = () =>
    !!(window.RTCPeerConnection || window.webkitRTCPeerConnection || window.mozRTCPeerConnection) &&
    !excludedBrowsers();

export const canScreenshare = () => {
    let screenshare = true;
    // device detection
    if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
            navigator.userAgent
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            navigator.userAgent.substr(0, 4)
        )
    ) {
        screenshare = false;
    }
    return screenshare && !excludedBrowsers();
};

export const hasArrayAllNullEntries = (array) => array.every((element) => element === null);

export const makeLoginJson = (cmd, pin, name, sessionId) => ({
    cmd,
    pin,
    name,
    sessionId,
    userAgentString: navigator.userAgent,
    lang: getLanguage(),
    canReceiveWebRtc: canReceiveWebRtc(),
    canScreenshare: canScreenshare(),
});

export const canVoip = (product) =>
    product !== "EASYAUDIO" &&
    product !== "BA-FLAT-RV" &&
    !checkBrowser().isEdge &&
    !isIE11() &&
    !checkBrowser().isSafari;

export const escapeHtml = (str) => {
    const div = document.createElement("div");
    div.appendChild(document.createTextNode(str));
    return div.innerHTML;
};

export const modalText = (string, name) => {
    const arr = translate(string).split(/\{|\}/);
    arr.forEach((i, idx) => {
        if (arr[idx] === "0") {
            arr[idx] = <b>{name}</b>;
        }
    });
    return arr;
};

export const getAcceptDownloadText = (hrefWin, hrefMac, linkTextWin, linkTextMac, linkFn) => {
    let ret = null;
    if (getOS() === "Windows") {
        ret = linkFn(hrefWin, linkTextWin);
    }
    if (getOS() === "Mac OS") {
        ret = linkFn(hrefMac, linkTextMac);
    }
    return ret;
};

export const getBlankLink = (href, linkText) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
        {linkText}
    </a>
);

export const getLink = (href, linkText) => <a href={href}>{linkText}</a>;

export const acceptBlankDownloadAppText = () => {
    let appTitle = "app";
    const lang = getLanguage();
    if (lang === "de") appTitle = "App";
    if (lang === "es") appTitle = "aplicación";
    if (lang === "fr") appTitle = "application";

    return getAcceptDownloadText(
        "https://s3.eu-central-1.amazonaws.com/meetyoo-dl/meetgreen/dist/meetyoo-screenshare-0.0.14.exe",
        "https://s3.eu-central-1.amazonaws.com/meetyoo-dl/meetgreen/dist/meetyoo-screenshare-0.0.14.zip",
        appTitle,
        appTitle,
        getBlankLink
    );
};

export const acceptBlankDownloadText = () =>
    getAcceptDownloadText(
        "https://s3.eu-central-1.amazonaws.com/meetyoo-dl/meetgreen/dist/meetyoo-screenshare-0.0.14.exe",
        "https://s3.eu-central-1.amazonaws.com/meetyoo-dl/meetgreen/dist/meetyoo-screenshare-0.0.14.zip",
        translate("DownloadWebApp.Gen"),
        translate("DownloadWebApp.Gen"),
        getBlankLink
    );

export const acceptDownloadText = () =>
    getAcceptDownloadText(
        "https://s3.eu-central-1.amazonaws.com/meetyoo-dl/meetgreen/dist/meetyoo-screenshare-0.0.14.exe",
        "https://s3.eu-central-1.amazonaws.com/meetyoo-dl/meetgreen/dist/meetyoo-screenshare-0.0.14.zip",
        translate("DownloadWebApp.Win"),
        translate("DownloadWebApp.Mac"),
        getLink
    );

export const getHoursAndMinutes = () => {
    const d = new Date();
    let min = d.getMinutes();
    if (min < 10) min = "0" + min;
    return `${d.getHours()}:${min}`;
};

export const getSidebarViewStyle = (showFullscreenMode, showSidebarContent) => {
    const sidebarListView = showFullscreenMode ? "sidebar-list--fullscreen" : "sidebar-list--normal-view";
    let showSidebar = "";
    if (showFullscreenMode && showSidebarContent) showSidebar = "sidebar-list--fullscreen-shown";
    if (!showFullscreenMode && showSidebarContent) showSidebar = "sidebar-list--normal-view-shown";
    return {
        sidebarListView,
        showSidebar,
    };
};

export const countChars = (str) => str.length;

export const isClassNotIncluded = (generalClassName, specificClassName) =>
    document.querySelector(`[class*='${specificClassName}']`) !== undefined &&
    !generalClassName.includes(specificClassName);

export default this;
