import React from "react";
import PropTypes from "prop-types";

const MessageWithCheckbox = ({ switchboxId, message, toggleChecked, isChecked }) => (
    <div className="inputblock__child left-right-container">
        <div className="left">
            <label htmlFor={switchboxId} className="switch">
                <input
                    className="input-field input-field--checkbox"
                    type="checkbox"
                    id={switchboxId}
                    checked={isChecked}
                    onChange={toggleChecked}
                />
                <span className="slider round" />
            </label>
        </div>
        <div className="right">{message}</div>
    </div>
);

MessageWithCheckbox.propTypes = {
    switchboxId: PropTypes.string.isRequired,
    message: PropTypes.object.isRequired,
    toggleChecked: PropTypes.func.isRequired,
    isChecked: PropTypes.bool.isRequired,
};

export default MessageWithCheckbox;
