import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { translate } from "../../lib/Translator";
import { acceptBlankDownloadText } from "../../lib/utils";
import { voipHangup, writeToSocket } from "../../actions";
import ActionButtons from "./ActionButtons";
import SimpleAcceptButton from "../basics/buttons/SimpleAcceptButton";

const PresentationWelcomeScreenComponent = ({
    show,
    helloText,
    participantName,
    noPresText,
    participantPinText,
    participantPin,
    showWaitingFor,
    waitingForText,
    presenter,
    allowScreenshare,
    canReceiveWebRtc,
    canScreenshare,
    downloadAppText,
    uid,
}) =>
    !show ? null : (
        <div>
            <h1>
                {helloText} {participantName}!
            </h1>
            <div className="participant-pin-text">
                {participantPinText} {participantPin}
            </div>
            {allowScreenshare && canReceiveWebRtc ? (
                <div>
                    <div className="icon-no-presentation" />
                    <p>{noPresText}</p>
                    {showWaitingFor ? (
                        <p>
                            {waitingForText} {presenter}
                            ...
                        </p>
                    ) : null}
                    {canScreenshare ? <ActionButtons /> : null}
                </div>
            ) : (
                <div>
                    <div className="welcomescreen__download-app-text">{downloadAppText}</div>
                    <div className="welcomescreen__download-app-button">
                        <SimpleAcceptButton
                            acceptText={acceptBlankDownloadText()}
                            marginTop="btn-wrapper--margin-top-small"
                            additionalFunc={(dispatch) => {
                                setTimeout(() => {
                                    dispatch(voipHangup());
                                    dispatch(
                                        writeToSocket({
                                            cmd: "leavePresentation",
                                            uid,
                                        })
                                    );
                                }, 1000);
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );

PresentationWelcomeScreenComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    participantName: PropTypes.string.isRequired,
    helloText: PropTypes.string.isRequired,
    noPresText: PropTypes.string.isRequired,
    participantPinText: PropTypes.string.isRequired,
    participantPin: PropTypes.string.isRequired,
    showWaitingFor: PropTypes.bool.isRequired,
    waitingForText: PropTypes.string.isRequired,
    presenter: PropTypes.string.isRequired,
    allowScreenshare: PropTypes.bool.isRequired,
    canReceiveWebRtc: PropTypes.bool.isRequired,
    canScreenshare: PropTypes.bool.isRequired,
    downloadAppText: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    show:
        (state.webRtc.remoteStream !== "ready" && state.login.isPresenter && !state.webRtc.captureStarted) ||
        (state.webRtc.remoteStream !== "ready" && !state.login.isPresenter),
    participantName: state.login.participantName,
    participantPin: state.login.guestPin,
    helloText: translate("Presentation.Hello"),
    noPresText: translate("Presentation.NoPresAtTheMom"),
    participantPinText: translate("Presentation.ParticipantPinText"),
    showWaitingFor: state.webRtc.remoteStream !== "ready" && !state.login.isPresenter,
    waitingForText: translate("Presentation.WaitingFor"),
    presenter: state.participants.presenter,
    allowScreenshare: state.login.allowScreenshare,
    canReceiveWebRtc: state.modal.canReceiveWebRtc,
    canScreenshare: state.modal.canScreenshare,
    downloadAppText: translate("Presentation.DownloadApp"),
    uid: state.login.uid,
});

const PresentationWelcomeScreen = connect(mapStateToProps)(PresentationWelcomeScreenComponent);
export default PresentationWelcomeScreen;
