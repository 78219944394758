import React, { Component } from "react";

import "../styles/sidebar.css";

class IconSidebarEntryActiveSpeaker extends Component {
    render() {
        return (
            <span className="sidebar-entry-content-symbols__active-speaker-inline">
                <svg
                    version="1.1"
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 16 16"
                    xmlSpace="preserve"
                >
                    <path
                        d="M7.3,2.2L3.9,5H1C0.5,5,0,5.5,0,6v4c0,0.5,0.5,1,1,1h2.9l3.4,2.8c0.7,0.5,1.6,0,1.6-0.8V3
            C8.9,2.2,8,1.7,7.3,2.2z"
                    />
                    <g>
                        <g>
                            <path
                                d="M11.5,11.7c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.6,0-0.8c1.4-1.5,1.4-4,0-5.5c-0.2-0.2-0.2-0.6,0-0.8
                    s0.5-0.2,0.7,0c1.8,1.9,1.8,5.1,0,7.1C11.7,11.6,11.6,11.7,11.5,11.7z"
                            />
                        </g>
                        <g>
                            <path
                                d="M13.5,14c-0.1,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.6,0-0.8c2.5-2.8,2.5-7.3,0-10.1c-0.2-0.2-0.2-0.6,0-0.8
                    s0.5-0.2,0.7,0c2.9,3.2,2.9,8.5,0,11.7C13.7,13.9,13.6,14,13.5,14z"
                            />
                        </g>
                    </g>
                </svg>
            </span>
        );
    }
}

export default IconSidebarEntryActiveSpeaker;
