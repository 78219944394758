import { ActionTypes } from "redux-mw-ws";
import { canReceiveWebRtc } from "../lib/utils";
import { setTurnServer } from "../actions";

import WebRtcEventHandler from "../lib/WebRtcEventHandler";

export default function createScreenShareMiddleware() {
    return (store) => {
        const webRtcEventHandler = new WebRtcEventHandler(store);
        return (next) => (action) => {
            // stream initiator
            if (action.type === "START_SHARING") {
                webRtcEventHandler.initCapture(action.sessionId, action.mediaSource);
            }
            // stream initiator
            if (action.type === "STOP_SHARING") {
                webRtcEventHandler.stopCapture(action.sessionId);
            }
            if (action.type === "GET_ELECTRON_USER_MEDIA") {
                webRtcEventHandler.electronGetUserMedia(action.electronIndexToCapture);
            }
            if (action.type === ActionTypes.RECEIVED_WEBSOCKET_DATA) {
                if (action.payload.cmd === "login") {
                    store.dispatch(setTurnServer(action.payload.turn));
                }
                // stream initiator
                if (action.payload.cmd === "sdpOffer") {
                    webRtcEventHandler.handleSdpOffer(action.payload);
                }
                // stream joiner
                if (action.payload.cmd === "streamOpen" && canReceiveWebRtc()) {
                    webRtcEventHandler.handleStreamOpen(action.payload);
                }
                // stream joiner
                if (action.payload.cmd === "sdpAnswer" && canReceiveWebRtc()) {
                    webRtcEventHandler.handleSdpAnswer(action.payload);
                }
                if (action.payload.cmd === "stopStream") {
                    webRtcEventHandler.handleStopStream();
                }
                if (action.payload.cmd === "presenterRevoked") {
                    webRtcEventHandler.stopStreams();
                }
                if (action.payload.cmd === "presentationOver") {
                    webRtcEventHandler.handlePresentationOver();
                }
            }
            next(action);
        };
    };
}
