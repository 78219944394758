/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ELECTRON_SELECT_BOX } from "../../constants/modalConstants";
import { setModalVisibility, setElectronIndexToCapture, getElectronuserMedia } from "../../actions";

const initialState = {
    clicked: false,
    clickedIndex: 0,
};

class ElectronSelectBox extends Component {
    constructor(props) {
        super(props);

        this.state = initialState;
    }

    _reset = () => {
        this.setState(initialState);
    };

    _setActiveClass = (index) => {
        this.setState({
            clicked: true,
            clickedIndex: index,
        });
    };

    _getGrid = (sources, dispatch) => {
        // console.log("sources: %o", sources);
        const { clicked, clickedIndex } = this.state;
        return (
            <div className="electron-thumbnails">
                {sources.map((source, index) => (
                    <div
                        id={source.id}
                        className="electron-thumbnail"
                        key={source.id}
                        onClick={() => {
                            // console.log("onclick index: " + index);
                            dispatch(setElectronIndexToCapture(index));
                            this._setActiveClass(index);
                        }}
                        role="button"
                        tabIndex="0"
                    >
                        <div
                            className={`${
                                (clicked && clickedIndex === index) || (!clicked && index === 0)
                                    ? "electron-thumbnail--border"
                                    : ""
                            }`}
                        >
                            <div
                                className="electron-thumbnail__image"
                                style={{
                                    backgroundImage: `url(${source.thumbnail.toDataURL()})`,
                                }}
                            />
                            <div className="electron-thumbnail__title">{source.name}</div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    render() {
        const { state, dispatch } = this.props;
        if (state.modal.modalId !== ELECTRON_SELECT_BOX) {
            return null;
        }
        return (
            <div className="modal-container">
                <div className="electron-select">
                    <span
                        className="icon-close"
                        onClick={() => {
                            this._reset();
                            dispatch(setModalVisibility(""));
                            dispatch(setElectronIndexToCapture(0));
                        }}
                        role="button"
                        tabIndex="0"
                    />
                    <div className="modal-top">
                        <div>
                            {state.webRtc.captureType === "window"
                                ? "FENSTER ZUR FREIGABE AUSWÄHLEN"
                                : "DESKTOP ZUR FREIGABE AUSWÄHLEN"}
                        </div>
                    </div>
                    {this._getGrid(state.webRtc.electronSources, dispatch)}
                    <div className="modal-bottom modal-bottom--big">
                        <div className="btn-wrapper btn-wrapper--margin-top">
                            <div
                                className="btn btn-grey"
                                onClick={() => {
                                    this._reset();
                                    dispatch(setModalVisibility(""));
                                    dispatch(setElectronIndexToCapture(0));
                                }}
                                role="button"
                                tabIndex="0"
                            >
                                Abbrechen
                            </div>
                            <div
                                className="btn btn-cta"
                                onClick={() => {
                                    this._reset();
                                    dispatch(getElectronuserMedia(state.modal.electronIndexToCapture));
                                    dispatch(setModalVisibility(""));
                                    dispatch(setElectronIndexToCapture(0));
                                }}
                                role="button"
                                tabIndex="0"
                            >
                                Teilen
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ElectronSelectBox.propTypes = {
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default connect((state) => ({ state }))(ElectronSelectBox);
