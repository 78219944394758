import React from "react";
import PropTypes from "prop-types";

const StopSharingButton = ({ onClick, sessionId, title }) => (
    <div
        className="action"
        onClick={() => {
            onClick(sessionId);
        }}
        role="button"
        tabIndex="0"
    >
        <div className="action-button button-stop">
            <p>{title}</p>
        </div>
    </div>
);

StopSharingButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    sessionId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default StopSharingButton;
