import React from "react";
import PropTypes from "prop-types";

import IconSidebarEntryPresenting from "../inlineSvgs/IconSidebarEntryPresenting";
import IconSidebarEntryOnHold from "../inlineSvgs/IconSidebarEntryOnHold";
import IconSidebarEntryMute from "../inlineSvgs/IconSidebarEntryMute";
import IconSidebarEntryActiveSpeaker from "../inlineSvgs/IconSidebarEntryActiveSpeaker";

import { translate } from "../lib/Translator";

const Participant = ({
    host,
    presenting,
    onHold,
    mute,
    talking,
    titleText,
    presentationActive,
    showPresenterSymbol,
    dropdownmenu,
}) => (
    <div className="sidebar-entry-container sidebar-entry-container--participant">
        <div className="sidebar-entry-content">
            <div className="sidebar-entry-content-title">
                <div className="sidebar-entry-content-title__text">{titleText}</div>
                {host ? (
                    <span className="sidebar-entry-content-title--host">
                        &nbsp;&bull;&nbsp;
                        {translate("Participants.Moderator")}
                    </span>
                ) : null}
            </div>
            <div className="sidebar-entry-content-divider" />
            <div className="sidebar-entry-content-symbols">
                <span className="sidebar-entry-content-symbols__placeholder" />
                {presenting && showPresenterSymbol ? (
                    <IconSidebarEntryPresenting presentationActive={presentationActive} />
                ) : null}
                {onHold ? <IconSidebarEntryOnHold /> : null}
                {mute ? <IconSidebarEntryMute /> : null}
                {talking ? <IconSidebarEntryActiveSpeaker /> : null}
            </div>
        </div>
        {dropdownmenu !== null ? (
            <div className="sidebar-entry-symbols">
                <div className="sidebar-entry-symbols__dropbtn">{dropdownmenu}</div>
            </div>
        ) : null}
    </div>
);

Participant.defaultProps = {
    dropdownmenu: null,
    showPresenterSymbol: false,
};

Participant.propTypes = {
    host: PropTypes.bool.isRequired,
    presenting: PropTypes.bool.isRequired,
    onHold: PropTypes.bool.isRequired,
    mute: PropTypes.bool.isRequired,
    talking: PropTypes.bool.isRequired,
    titleText: PropTypes.string.isRequired,
    presentationActive: PropTypes.bool.isRequired,
    showPresenterSymbol: PropTypes.bool,
    dropdownmenu: PropTypes.node,
};

export default Participant;
