import JsSIP from "jssip";
import {
    setModalVisibility,
    setVoipConnectionStatus,
    setVoipMuteStatus,
    setSelectedButton,
    setModalContent,
} from "../actions";
import { checkBrowser } from "./utils";
import { VOIP_FAILED, PHONE_DIALIN_BOX } from "../constants/modalConstants";

class Voip {
    constructor(store) {
        this.store = store;

        this.connected = false;
        this.session = null;
        this.connecting = false;
    }

    sendConfPin = () => {
        const { pin, matchingId } = this.store.getState().login;

        this.session.sendDTMF(`${pin}#`);
        const that = this;
        this.store.dispatch(setModalVisibility(""));
        if (matchingId === -1) {
            console.log("no matchingId");
            this.remoteAudio.muted = false;
            this.connected = true;
            this.store.dispatch(setVoipConnectionStatus(this.connected));
            return;
        }
        setTimeout(() => {
            // that.session.sendDTMF(`123#${that.matchingId}#`);
            that.session.sendDTMF(`123#${matchingId}#`);
            this.remoteAudio.muted = false;
            this.connected = true;
            this.store.dispatch(setVoipConnectionStatus(this.connected));
        }, 3000);
    };

    dial = () => {
        const { turn } = this.store.getState().webRtc;
        const eventHandlers = {
            progress: () => {
                console.log("call is in progress");
            },
            failed: (e) => {
                console.log(`call failed with cause: ${JSON.stringify(e)}`);
                this._onFailed();
            },
            ended: (e) => {
                console.log("call ended with cause: ");
                console.log(e);
            },
            confirmed: (e) => {
                console.log("call confirmed");
                console.log(e);
            },
        };
        this.remoteAudio = new window.Audio();
        this.remoteAudio.autoplay = true;
        this.ua.on("newRTCSession", (e) => {
            console.log("newRTCSession %o", e);
            if (checkBrowser().isChrome)
                e.session.connection.onaddstream = (e2) => {
                    console.log("onaddstream %o", e2);
                    this._finishConnection(e2.stream);
                };
            else
                e.session.connection.ontrack = (e2) => {
                    console.log("ontrack %o", e2);
                    this._finishConnection(e2.streams[0]);
                };
        });
        const pcconf = [
            {
                urls: ["stun:stun2.l.google.com:19302"],
            },
        ];
        // using ubivent turn server
        pcconf.push({
            username: turn.username,
            credential: turn.password,
            urls: turn.uris,
        });
        const options = {
            pcConfig: {
                iceServers: pcconf,
                rtcpMuxPolicy: "negotiate",
            },
            eventHandlers,
            mediaConstraints: { audio: true, video: false },
            rtcOfferConstraints: {
                offerToReceiveAudio: 1,
                offerToReceiveVideo: 0,
            },
        };

        this.session = this.ua.call(`sip:${this.sipCredentials.numberToDial}`, options);
        this.connecting = false;
    };

    _finishConnection(stream) {
        this.remoteAudio.srcObject = stream;
        this.remoteAudio.play();
        this.remoteAudio.muted = true;
        setTimeout(() => this.sendConfPin(), 2000);
    }

    connect = (sipCredentials) => {
        if ((this.session != null && !this.session.isEnded()) || this.connecting) {
            this.store.dispatch(setModalVisibility(""));
            return;
        }
        this.connecting = true;
        this.sipCredentials = sipCredentials;
        const socket = new JsSIP.WebSocketInterface(sipCredentials.url);
        const configuration = {
            sockets: [socket],
            uri: `sip:${sipCredentials.user}@mywebrtc.meetyoo.de`,
            password: sipCredentials.pw,
        };
        this.ua = new JsSIP.UA(configuration);

        this.ua.start();
        this.ua.on("registered", () => this.dial());
    };

    _onFailed = () => {
        this.connecting = false;
        this.store.dispatch(setModalContent(VOIP_FAILED));
        setTimeout(() => {
            if (this.store.getState().modal.modalContent === VOIP_FAILED) {
                this.store.dispatch(setSelectedButton(PHONE_DIALIN_BOX));
                this.store.dispatch(setModalContent(PHONE_DIALIN_BOX));
            }
        }, 5000);
    };

    hangup = () => {
        if (this.session != null && !this.session.isEnded()) this.session.terminate();
        this.session = null;

        this.connected = false;
        this.store.dispatch(setVoipConnectionStatus(this.connected));
    };

    _isMuted = () => {
        let isMuted = true;
        if (this.connected) {
            isMuted = this.session.isMuted().audio;
        }
        return isMuted;
    };

    toggleMute = () => {
        if (!this.connected) {
            return;
        }
        if (this._isMuted()) {
            this.session.unmute({ audio: true });
            this.store.dispatch(setVoipMuteStatus(false));
        } else {
            this.session.mute({ audio: true });
            this.store.dispatch(setVoipMuteStatus(true));
        }
    };
}

export default Voip;
