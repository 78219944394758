import React from "react";
import { connect } from "react-redux";

import { REVOKE_PRESENTER_BOX } from "../../constants/modalConstants";
import { writeToSocket, setModalVisibility, setDidRevokePresenter, setPresenterRevoked } from "../../actions";
import { translate } from "../../lib/Translator";
import { modalText } from "../../lib/utils";
import Modal from "../../components/modal/Modal";
import SimpleDecissionButtons from "../basics/buttons/SimpleDecissionButtons";

const abortClick = () => setModalVisibility("");
const acceptClick = (dispatch, state) => {
    dispatch(
        writeToSocket({
            cmd: "makePresenter",
            uid: state.login.uid,
            initiatorUid: state.login.uid,
        })
    );
    dispatch(setModalVisibility(""));
    dispatch(setDidRevokePresenter(true));
    dispatch(setPresenterRevoked(true));
};

const mapStateToProps = (state) => ({
    show: state.modal.modalId === REVOKE_PRESENTER_BOX,
    title: translate("RevokePresenter.Title").toUpperCase(),
    contentChildren: <span>{modalText("RevokePresenter.Message", state.participants.selectedUserName)}</span>,
    buttonChildren: (
        <SimpleDecissionButtons
            acceptClick={(dispatch) => {
                acceptClick(dispatch, state);
            }}
        />
    ),
});

const mapDispatchToProps = {
    onClose: abortClick,
};

const RevokePresenter = connect(mapStateToProps, mapDispatchToProps)(Modal);

export default RevokePresenter;
