/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from "react";
import PropTypes from "prop-types";

import "../../../styles/main.css";
import "../../../styles/modal.css";
import "../../../styles/feedbackstars.css";

class IconStar extends Component {
    mouseOver = () => {
        const { mouseOver, index } = this.props;
        mouseOver(index);
    };

    mouseLeave = () => {
        const { mouseLeave, index } = this.props;
        mouseLeave(index);
    };

    click = (event) => {
        const { click, index } = this.props;
        click(index);
        event.preventDefault();
    };

    render() {
        const { active } = this.props;
        return (
            <div
                className={"star" + (active ? " active" : "")}
                onMouseEnter={this.mouseOver}
                onMouseLeave={this.mouseLeave}
                onClick={this.click}
                role="button"
                tabIndex="0"
            >
                <svg
                    version="1.1"
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="30"
                    height="30"
                    xmlSpace="preserve"
                >
                    <path
                        className="star-bg"
                        d="M29.1,12.7c0.7-0.5,0.3-1.7-0.6-1.7l-8.9-0.2c-0.4,0-0.8-0.3-0.9-0.6l-2.9-8.4c-0.3-0.9-1.5-0.9-1.8,0l-2.9,8.4
      c-0.1,0.4-0.5,0.6-0.9,0.6L1.4,11c-0.9,0-1.3,1.2-0.6,1.7l7.1,5.4c0.3,0.2,0.5,0.7,0.3,1l-2.6,8.5c-0.3,0.9,0.7,1.6,1.5,1.1l7.3-5.1
      c0.3-0.2,0.8-0.2,1.1,0l7.3,5.1c0.7,0.5,1.7-0.2,1.5-1.1l-2.6-8.5c-0.1-0.4,0-0.8,0.3-1L29.1,12.7z"
                    />
                    <path
                        className="star-border"
                        d="M23.4,29.3c-0.3,0-0.6-0.1-0.8-0.3L15.3,24c-0.2-0.1-0.4-0.1-0.5,0l-7.3,5.1c-0.5,0.4-1.2,0.3-1.7,0
      c-0.5-0.4-0.7-1-0.5-1.6L7.8,19c0.1-0.2,0-0.4-0.2-0.5l-7.1-5.4c-0.5-0.4-0.7-1-0.5-1.6c0.2-0.6,0.7-1,1.3-1l8.9-0.2
      c0.2,0,0.4-0.1,0.4-0.3l2.9-8.4c0.2-0.6,0.7-1,1.4-1s1.2,0.4,1.4,1l2.9,8.4c0.1,0.2,0.2,0.3,0.4,0.3l8.9,0.2c0.6,0,1.1,0.4,1.3,1
      c0.2,0.6,0,1.2-0.5,1.6l-7.1,5.4c-0.2,0.1-0.2,0.3-0.2,0.5l2.6,8.5c0.2,0.6,0,1.2-0.5,1.6C24,29.2,23.7,29.3,23.4,29.3z M15,23
      c0.3,0,0.6,0.1,0.8,0.3l0,0l7.3,5.1c0.3,0.2,0.5,0,0.5,0c0.1,0,0.3-0.2,0.2-0.5l-2.6-8.5c-0.2-0.6,0-1.2,0.5-1.6l7.1-5.4
      c0.2-0.2,0.2-0.4,0.2-0.5c0-0.1-0.1-0.3-0.4-0.3l-8.9-0.2c-0.6,0-1.1-0.4-1.3-1l-2.9-8.4c-0.1-0.3-0.4-0.3-0.4-0.3s-0.3,0-0.4,0.3
      l-2.9,8.4c-0.2,0.6-0.7,1-1.3,1l-8.9,0.2c-0.3,0-0.4,0.3-0.4,0.3c0,0.1-0.1,0.3,0.2,0.5l7.1,5.4c0.5,0.4,0.7,1,0.5,1.6l-2.6,8.5
      c-0.1,0.3,0.1,0.5,0.2,0.5c0.1,0,0.3,0.2,0.5,0l7.3-5.1C14.4,23,14.7,23,15,23z"
                    />
                </svg>
            </div>
        );
    }
}

IconStar.propTypes = {
    mouseOver: PropTypes.func.isRequired,
    mouseLeave: PropTypes.func.isRequired,
    click: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    active: PropTypes.bool.isRequired,
};

export default IconStar;
