import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "./start.css";

import ErrorBoundary from "./errorHandling/ErrorBoundary";

import IconStartMod from "../inlineSvgs/IconStartMod";
import IconStartParticipant from "../inlineSvgs/IconStartParticipant";
import { translate } from "../lib/Translator";
import { getBrandingType, getLanguage } from "../lib/utils";
import { MYWEB_BACKEND } from "../constants/commonConstants";

const StartComponent = ({ show, modLabel, partLabel, onClick }) => (
    <ErrorBoundary>
        <div className="main-block">
            {show ? (
                <div className="start-block">
                    <div
                        className="start-block__left "
                        onClick={() => {
                            onClick(true);
                        }}
                        role="button"
                        tabIndex="0"
                    >
                        <IconStartMod />
                        <div className="start-block__label">{modLabel}</div>
                    </div>
                    <div
                        className="start-block__right"
                        onClick={() => {
                            onClick(false);
                        }}
                        role="button"
                        tabIndex="0"
                    >
                        <IconStartParticipant />
                        <div className="start-block__label">{partLabel}</div>
                    </div>
                    <div className="start-block__buyproduct">
                        <a
                            className="start-block__buyproduct-link"
                            href={`https://${MYWEB_BACKEND}/myweb/v1/buy?product=${getBrandingType()}&language=${getLanguage()}`}
                        >
                            {translate("Start.BecomeCustomer")}
                        </a>
                    </div>
                </div>
            ) : null}
        </div>
    </ErrorBoundary>
);

StartComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    modLabel: PropTypes.string.isRequired,
    partLabel: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    show: !(state.login.login || state.modal.modalId !== ""),
    modLabel: translate("Start.Moderator"),
    partLabel: translate("Start.Participant"),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onClick: (isOp) => {
        ownProps.startLogin(isOp);
    },
});

const Start = connect(mapStateToProps, mapDispatchToProps)(StartComponent);

export default Start;
