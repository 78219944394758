import React from "react";
import PropTypes from "prop-types";

const ButtonContent = ({ abortText, abortClick, acceptText, acceptClick, marginTop }) => {
    const showCancel = abortClick !== null && abortClick !== undefined && abortText !== null && abortText !== undefined;
    return (
        <div className={`btn-wrapper ${marginTop}`}>
            {showCancel ? (
                <div className="btn btn-grey" onClick={abortClick} role="button" tabIndex="0">
                    {abortText}
                </div>
            ) : null}
            <div className="btn btn-cta" onClick={acceptClick} role="button" tabIndex="0">
                {acceptText}
            </div>
        </div>
    );
};

ButtonContent.defaultProps = {
    abortText: null,
    abortClick: null,
    marginTop: "btn-wrapper--margin-top",
};

ButtonContent.propTypes = {
    abortText: PropTypes.any,
    acceptText: PropTypes.any.isRequired,
    abortClick: PropTypes.func,
    acceptClick: PropTypes.func.isRequired,
    marginTop: PropTypes.string,
};

export default ButtonContent;
