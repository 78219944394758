import React, { Component } from "react";
import "../containers/basics/buttons/roundImageButton.css";

class IconCopyTemplate extends Component {
    render() {
        return (
            <div className="c-image__icon">
                <svg
                    version="1.1"
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 34 34"
                    style={{ background: "0 0 34 34" }}
                    xmlSpace="preserve"
                >
                    <g>
                        <rect x="20" y="4" className="st-conn-0" width="4" height="2" />
                    </g>
                    <g>
                        <rect x="10" y="8" className="st-conn-0" width="14" height="2" />
                    </g>
                    <g>
                        <rect x="10" y="12" className="st-conn-0" width="14" height="2" />
                    </g>
                    <path
                        className="st-conn-0"
                        d="M29,11.4V0H5v11.4l-5,2.1V34h34V13.5L29,11.4z M5,13.4v2.1l-2-1.2L5,13.4z M7,16.7V2h20v14.7L17,22L7,16.7z
        M29,15.5v-2.1l2,0.8L29,15.5z"
                    />
                </svg>
            </div>
        );
    }
}

export default IconCopyTemplate;
