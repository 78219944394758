import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { sortParticipants } from "../../lib/utils";

import HeaderMatched from "./HeaderMatched";
import ModeratorMatched from "../dropdown/ModeratorMatched";
import ParticipantMatched from "../dropdown/ParticipantMatched";

const ParticipantListMatchedComponents = ({ isOperator, showMatchedParticipants, sortedParticipants }) => {
    const pOperator = sortedParticipants.filter((p) => p.host === true)[0];
    return (
        <div>
            <HeaderMatched />
            {/* <SlideDown transitionOnAppear closed={false}> */}
            {showMatchedParticipants
                ? sortedParticipants.map((p, i) => {
                      // console.log("matched p %o", p)
                      const containerRef = (
                          <div key={p.uid}>
                              {isOperator ? <ModeratorMatched p={p} pOp={pOperator} /> : <ParticipantMatched p={p} />}
                              {i < sortedParticipants.length - 1 ? (
                                  <div className="sidebar-entry-container-divider" />
                              ) : null}
                          </div>
                      );
                      return containerRef;
                  })
                : null}
            {/* </SlideDown> */}
        </div>
    );
};

ParticipantListMatchedComponents.propTypes = {
    isOperator: PropTypes.bool.isRequired,
    showMatchedParticipants: PropTypes.bool.isRequired,
    sortedParticipants: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    showMatchedParticipants: state.modal.showMatchedParticipants,
    sortedParticipants: sortParticipants(state.participants.matched, state.login.uid),
    isOperator: state.login.isOperator,
});

const ParticipantListMatched = connect(mapStateToProps)(ParticipantListMatchedComponents);

export default ParticipantListMatched;
