import streamContainer from "./StreamContainer";

const MAX_FRAMERATE = 10;

const PeerConnectionState = {
    CLOSED: "closed",
    STARTED: "started",
};

class WebRtcHandler {
    Role = {
        Pres: 1,
        NoPres: 2,
    };

    constructor(turnServer, isPresenter, statsFn, qualFn) {
        this._initVariables();

        this.turnServer = turnServer;
        this.isPresenter = isPresenter;
        this._statsFn = statsFn;
        this._qualFn = qualFn;

        if (isPresenter) {
            this.role = 1;
        } else {
            this.role = 2;
        }
    }

    _initVariables = () => {
        this.outsideResolver = null;
        this.outsideRejecter = null;
        this.stopStreamFn = null;

        this.gotAllCandidates = false;
        this.gotAllCandidatesTimeout = -1;
        this.localCandCount = 0;

        this.rtcPeerConnection = null;
        this.rtcPeerConnectionState = PeerConnectionState.CLOSED;

        this.electronSources = null;
        this.previousStats = null;
        this.processStatsId = null;
    };

    resetProcessStats = () => {
        clearTimeout(this.processStatsId);
    };

    get _rtcPeerConnection() {
        return this.rtcPeerConnection;
        // return window.rtcPeerConnection; // DEBUG
    }

    set _rtcPeerConnection(rtcPeerConnection) {
        this.rtcPeerConnection = rtcPeerConnection;
        // window.rtcPeerConnection = this.rtcPeerConnection; // DEBUG
    }

    _processStats = (report) => {
        if (report !== null && report !== undefined) {
            report.forEach((now) => {
                const stats = {};
                const type = this.role === 1 ? "outbound-rtp" : "inbound-rtp";
                if (now.type !== type || now.mediaType !== "video") return;
                const base = this.previousStats;
                this.previousStats = now;
                if (base === undefined) return;
                if (base) {
                    stats.nacks = now.nackCount - base.nackCount;
                    if (now.bytesSent !== undefined) stats.bytesSent = now.bytesSent - base.bytesSent;
                    if (now.bytesReceived !== undefined) stats.bytesReceived = now.bytesReceived - base.bytesReceived;
                    if (now.framesEncoded !== undefined) stats.framesEncoded = now.framesEncoded - base.framesEncoded;
                    if (now.framesDecoded !== undefined) stats.framesDecoded = now.framesDecoded - base.framesDecoded;

                    this._calculateConnectionQuality(
                        stats.nacks,
                        this.role === 1 ? stats.bytesSent : stats.bytesReceived
                    );
                    this._statsFn(stats);
                }
            });
        }
        if (this._rtcPeerConnection === undefined) return;
        this.processStatsId = setTimeout(() => this._rtcPeerConnection.getStats().then(this._processStats), 5000);
    };

    _calculateConnectionQuality = (nacks, bytesSent) => {
        console.log("nacks " + nacks + " bytes " + bytesSent);
        const POOR_CONNECTION_NACKS_THRESHOLD = 7;

        let connectionQuality = "ok";
        if (nacks > POOR_CONNECTION_NACKS_THRESHOLD) {
            connectionQuality = "poor";
            if (bytesSent !== undefined && bytesSent < 1) {
                connectionQuality = "none";
            }
        }

        // Connection quality is only updated when it changed to trigger a re-render
        // if (this._readStateFn().connectionQuality !== connectionQuality) {
        //   this._writeStateFn({
        //     connectionQuality
        //   });
        // }
        this._qualFn(connectionQuality);
    };

    /**
     * capture
     *
     */
    initCapture = (stopStreamFn, type, electronSelectFn) =>
        new Promise((resolve, reject) => {
            this.outsideResolver = resolve;
            this.outsideRejecter = reject;
            this.stopStreamFn = stopStreamFn;
            this._getScreenStream(type, electronSelectFn);
            console.log("init capture");
        });

    _getScreenStream = (type, electronSelectFn) => {
        this._trace("getScreenStream - posting screen capture message");
        if (window.navigator.userAgent.indexOf("Firefox/") > -1) {
            this._getScreenCaptureFF(type);
            return false;
        }
        // check for electron
        if (window && window.process && window.process.type) {
            this._getScreenCaptureElectron(type, electronSelectFn);
            return false;
        }

        // TODO: delete in separate merge request
        if (navigator.mediaDevices && navigator.userAgent.match(/Edge\/(\d+).(\d+)$/)) {
            this._getDisplayMedia();
            return false;
        }
        // assume chrome
        if (window.navigator.mediaDevices.getDisplayMedia !== undefined) {
            this._getDisplayMediaFromChrome();
            return false;
        }
        return false;
    };

    _getScreenCaptureElectron = (type, electronSelectFn) => {
        const { desktopCapturer } = window.require("electron");
        desktopCapturer.getSources(
            // { types: ["screen", "window"] },
            {
                types: [type],
            },
            (error, sources) => {
                if (error) {
                    this.outsideRejecter(error);
                    return;
                }
                this.electronSources = sources;
                console.log("sources: %o", sources);
                electronSelectFn(type, sources);
            }
        );
    };

    electronGetUserMedia = (electronIndexToCapture) => {
        console.log("electronGetUserMedia - index: " + electronIndexToCapture);
        const screenConstraints = {
            audio: false,
            video: {
                mandatory: {
                    chromeMediaSource: "desktop",
                    chromeMediaSourceId: this.electronSourcesObj[electronIndexToCapture].id,
                    maxHeight: 1080,
                    maxWidth: 1920,
                    maxFrameRate: MAX_FRAMERATE,
                },
            },
        };
        this._getUserMedia(screenConstraints);
    };

    get electronSourcesObj() {
        if (this.electronSources !== null && this.electronSources !== undefined) {
            return this.electronSources;
        }
        return [];
    }

    _getScreenCaptureFF = (type) => {
        const screenConstraints = {
            audio: false,
            video: {
                mediaSource: type,
                frameRate: {
                    max: String(MAX_FRAMERATE),
                },
            },
        };

        this._getUserMedia(screenConstraints);
    };

    _getDisplayMedia = () =>
        this._resolveUserMediaPromise(
            navigator.mediaDevices.getDisplayMedia({
                audio: false,
                video: {
                    mandatory: {
                        maxHeight: 1080,
                        maxWidth: 1920,
                        maxFrameRate: MAX_FRAMERATE,
                    },
                },
            })
        );

    _getDisplayMediaFromChrome = () =>
        this._resolveUserMediaPromise(
            window.navigator.mediaDevices.getDisplayMedia({
                audio: false,
                video: {
                    mandatory: {
                        maxHeight: 1080,
                        maxWidth: 1920,
                        maxFrameRate: MAX_FRAMERATE,
                    },
                },
            })
        );

    _getUserMedia = (screenConstraints) =>
        this._resolveUserMediaPromise(navigator.mediaDevices.getUserMedia(screenConstraints));

    _resolveUserMediaPromise = (promise) =>
        promise
            .then((screenStream) => {
                console.log("_getUserMedia %o", screenStream);
                streamContainer.localStream = screenStream;
                streamContainer.localStream.getVideoTracks()[0].onended = this.stopStreamFn;
            })
            .then(() => {
                this.outsideResolver({
                    localStream: "ready",
                    captureStarted: true,
                });
            })
            .catch((reason) => {
                console.log("_getUserMedia...%o", reason);
                if (typeof this.outsideRejecter === "function") this.outsideRejecter(reason);
            });

    stopCapture = () =>
        new Promise((resolve) => {
            console.log("stopCapture...");
            this._stopClientStream(streamContainer.localStream);
            this._stopClientStream(streamContainer.remoteStream);
            streamContainer.localStream = null;
            streamContainer.remoteStream = null;
            resolve();
        });

    /**
     * sdp handlung
     *
     */
    handleSdpOffer = (sdpOffer, retry) =>
        new Promise((resolve, reject) => {
            console.log("on handleSdpOffer, sdpOffer: %o", sdpOffer);
            this._configureLocalRTCPeerConnection(resolve, reject);
            this._rtcPeerConnection
                .setRemoteDescription(new RTCSessionDescription(sdpOffer.sdpOffer))
                .then(() => {
                    streamContainer.localStream.getTracks().forEach((track) => {
                        this._rtcPeerConnection.addTrack(track, streamContainer.localStream);
                    });
                    this._rtcPeerConnection.oniceconnectionstatechange = () => {
                        console.log("oniceconnectionstatechange " + this._rtcPeerConnection.iceConnectionState);
                        if (
                            this._rtcPeerConnection.iceConnectionState === "closed" ||
                            this._rtcPeerConnection.iceConnectionState === "failed"
                        ) {
                            if (streamContainer.localStream) {
                                streamContainer.localStream.getTracks().forEach((track) => this._removeTrack(track));
                            }
                            this.gotAllCandidates = false;
                            if (this.rtcPeerConnectionState !== PeerConnectionState.CLOSED) {
                                console.log("restarting share");
                                retry();
                            }
                        }
                    };
                    setTimeout(this._processStats(), 1);
                    this.rtcPeerConnectionState = PeerConnectionState.STARTED;
                })
                .then(() => {
                    this._createAnswer(resolve, reject);
                });
        });

    _configureLocalRTCPeerConnection = (resolve, reject) => {
        console.log("on configureLocalRTCPeerConnection");
        this._windowRTCPeerConnection();
        this._rtcPeerConnection = new RTCPeerConnection(this._configureIceServers());
        // Setup ice handling
        this._rtcPeerConnection.onicecandidate = (e) => {
            this._onLocalIceCandidate(e, resolve, reject);
        };
        console.log("LocalRTCPeerConnection: %o", this._rtcPeerConnection);
    };

    _createAnswer = (resolve, reject) => {
        console.log("on createAnswer");
        if (this.gotAllCandidates) {
            this._onLocalAnswer(this._rtcPeerConnection.localDescription, resolve, reject);
            return;
        }
        this._rtcPeerConnection.createAnswer(
            (sessionDescription) => {
                this._onLocalAnswer(sessionDescription, resolve, reject);
            },
            (error) => {
                reject(error);
            }
        );
    };

    _onLocalAnswer = (sessionDescription, resolve, reject) => {
        console.log("onLocalAnswer, sessionDescription: %o", sessionDescription);
        console.log("sdp %s", sessionDescription.sdp);
        console.log("gotAllCandidates: " + this.gotAllCandidates);

        if (this.gotAllCandidates) {
            if (sessionDescription.sdp !== "") {
                resolve(sessionDescription);
            } else {
                reject("Die SDP Nachricht ihrer Antwort ist leer.");
            }
        } else {
            // da rtcPeerConnection bereits geöffnet kann die description ausserhalb der if-Bedingung nicht mehrfach gesetzt werden
            this._rtcPeerConnection.setLocalDescription(sessionDescription);
        }
    };

    joinStream = (retry, remoteStreamStateCallback) =>
        new Promise((resolve, reject) => {
            console.log("joinStream");
            this._configureRemoteRTCPeerConnection(resolve, reject, remoteStreamStateCallback);
            setTimeout(this._processStats, 1);
            this._rtcPeerConnection.oniceconnectionstatechange = () => {
                console.log("oniceconnectionstatechange " + this._rtcPeerConnection.iceConnectionState);
                if (
                    this._rtcPeerConnection.iceConnectionState === "closed" ||
                    this._rtcPeerConnection.iceConnectionState === "failed"
                ) {
                    console.log("restarting join");
                    this.gotAllCandidates = false;
                    if (this.rtcPeerConnectionState !== PeerConnectionState.CLOSED) {
                        this.resetProcessStats();
                        streamContainer.remoteStream = null;
                        retry();
                    }
                }
            };
            this._createOffer(resolve, reject);
        });

    _configureRemoteRTCPeerConnection = (resolve, reject, remoteStreamStateCallback) => {
        this._windowRTCPeerConnection();
        this._rtcPeerConnection = new RTCPeerConnection(this._configureIceServers());
        this._rtcPeerConnection.onaddstream = (e) => {
            streamContainer.remoteStream = e.stream;
            // electron doesn't work with ontrack
            // this._rtcPeerConnection.ontrack = track => {
            // streamContainer.remoteStream = track.streams[0];

            remoteStreamStateCallback("ready");
        };
        this._rtcPeerConnection.onicecandidate = (e) => {
            this._onLocalIceCandidate(e, resolve, reject);
        };
        console.log("RemoteRTCPeerConnection: %o", this._rtcPeerConnection);
    };

    _windowRTCPeerConnection = () => {
        window.RTCPeerConnection =
            window.RTCPeerConnection || window.webkitRTCPeerConnection || window.mozRTCPeerConnection;
    };

    _createOffer = (resolve, reject) => {
        console.log("createOffer");
        if (this.gotAllCandidates) {
            this._onLocalOffer(this._rtcPeerConnection.localDescription, resolve, reject);
            return;
        }
        // create an offer
        this._rtcPeerConnection.createOffer(
            (sessionDescription) => {
                this._onLocalOffer(sessionDescription, resolve, reject);
            },
            (error) => {
                reject(error);
            },
            {
                offerToReceiveAudio: false,
                offerToReceiveVideo: true,
            }
        );
    };

    _onLocalOffer = (sessionDescription, resolve, reject) => {
        console.log("onLocalOffer, sessionDescription: %o", sessionDescription);
        console.log("gotAllCandidates: " + this.gotAllCandidates);

        // check for all candidates
        if (this.gotAllCandidates) {
            if (sessionDescription.sdp !== "") {
                resolve(sessionDescription);
            } else {
                reject("Die SDP Nachricht ihrer Anfrage ist leer.");
            }
        } else {
            // da rtcPeerConnection bereits geöffnet kann die description ausserhalb der if-Bedingung nicht mehrfach gesetzt werden
            this._rtcPeerConnection.setLocalDescription(sessionDescription);
        }
    };

    _configureIceServers = () => {
        // using Google public stun server
        const configuration = [
            {
                urls: ["stun:stun2.l.google.com:19302"],
            },
        ];
        // using ubivent turn server
        if (window.navigator.userAgent.indexOf("Edge") > -1) {
            if (this.turnServer !== undefined && this.turnServer.uris !== undefined) {
                configuration.pop();
                configuration.push({
                    username: this.turnServer.username,
                    credential: this.turnServer.password,
                    urls: this.turnServer.uris[0],
                });
            }
        } else {
            configuration.push({
                username: this.turnServer.username,
                credential: this.turnServer.password,
                urls: this.turnServer.uris,
            });
        }
        return {
            iceServers: configuration,
            iceTransportPolicy: "all",
            bundlePolicy: "max-bundle", // wichtig
        };
    };

    _onLocalIceCandidate = (event, resolve, reject) => {
        console.log("onLocalIceCandidate");
        const { candidate } = event;
        console.log("candidate: %o", candidate);
        if (candidate !== null && candidate !== undefined) {
            this.localCandCount += 1;
            if (this.gotAllCandidatesTimeout !== -1) clearTimeout(this.gotAllCandidatesTimeout);
            this.gotAllCandidatesTimeout = setTimeout(() => {
                this._onAllLocalCandidates(resolve, reject);
            }, 1000);
        } else {
            setTimeout(() => {
                if (this.rtcPeerConnection && this.rtcPeerConnectionState !== PeerConnectionState.CLOSED)
                    this._onAllLocalCandidates(resolve, reject);
            }, 100);
        }
    };

    _onAllLocalCandidates = (resolve, reject) => {
        console.log("onAllLocalCandidates");
        console.log("localCandCount: " + this.localCandCount);
        console.log("gotAllCandidates: " + this.gotAllCandidates);
        if (this.gotAllCandidates) {
            if (window.navigator.userAgent.indexOf("Edge") > -1) {
                // https://stackoverflow.com/questions/51495599/timeout-for-addremotecandidate-consider-sending-an-end-of-candidates-notificati
                this._rtcPeerConnection.addIceCandidate(null);
            }
            return;
        }
        this.gotAllCandidates = true;
        if (this.isPresenter) {
            this._createAnswer(resolve, reject);
        } else {
            this._createOffer(resolve, reject);
        }
    };

    handleSdpAnswer = (sdpAnswer) => {
        console.log("sdpanswer: %o", sdpAnswer);
        return new Promise((resolve, reject) => {
            this._rtcPeerConnection
                .setRemoteDescription(new RTCSessionDescription(sdpAnswer.sdpAnswer))
                .then(() => {
                    this.rtcPeerConnectionState = PeerConnectionState.STARTED;
                })
                .catch((reason) => {
                    this.rtcPeerConnectionState = PeerConnectionState.CLOSED;
                    reject(reason);
                });
        });
    };

    handleStopStream = () => {
        console.log("handleStopStream");
        return new Promise((resolve) => {
            this._stopClientStream(streamContainer.remoteStream);
            streamContainer.remoteStream = null;
            resolve();
        });
    };

    stopStreams = () => {
        this._stopClientStream(streamContainer.remoteStream);
        this._stopClientStream(streamContainer.localStream);
        streamContainer.remoteStream = null;
        streamContainer.localStream = null;
    };

    handlePresentationOver = (captureStarted) => {
        console.log("on handlePresentationOver...");
        if (captureStarted) {
            this._stopClientStream(streamContainer.localStream);
            streamContainer.localStream = null;
        }
        this._stopClientStream(streamContainer.remoteStream);
        streamContainer.remoteStream = null;
    };

    /**
     * common
     *
     */
    _stopClientStream = (stream) => {
        console.log("stream: %o", stream);
        if (stream) {
            stream.getTracks().forEach((track) => {
                track.stop();
                this._removeTrack(track);
            });
        }
        if (this._rtcPeerConnection) {
            if (this._rtcPeerConnection.signalingState !== "closed") {
                this.rtcPeerConnectionState = PeerConnectionState.CLOSED;
                this._rtcPeerConnection.close();
            }
        }
    };

    _removeTrack(track) {
        if (this._rtcPeerConnection && this._rtcPeerConnection.removeTrack) {
            const s = this._rtcPeerConnection.getSenders().find((sender) => sender.track === track);
            const rtcRtpSender = window.RTCRtpSender;
            if (s && rtcRtpSender && s instanceof rtcRtpSender && this._rtcPeerConnection.signalingState !== "closed") {
                this._rtcPeerConnection.removeTrack(s);
            }
        }
    }

    _trace = (text, error) => {
        const txt = (performance.now() / 1000).toFixed(3) + ": " + text;
        if (error !== undefined) console.log(txt + " error: %o", error);
        else console.log(txt);
    };
}

export default WebRtcHandler;
