import { writeToSocket, setStartDialout, setDialoutCallState } from "../actions";
import { dialoutStates } from "../constants/commonConstants";

class Dialouthandler {
    constructor(store) {
        this.store = store;
        this.dialoutStarted = false;
        this.setTimeout = true;
        this.otherDialouts = [];
    }

    startDialout() {
        if (this.dialoutStarted) return;
        this.dialoutStarted = true;

        const { dialouts } = this.store.getState().telco;
        for (let i = 0; i < dialouts.length; i += 1) {
            if (dialouts[i].callState !== dialoutStates.SUCCESS)
                this.store.dispatch(setDialoutCallState(dialouts[i].dialoutId, dialoutStates.IN_PROGRESS));
        }

        this.store.dispatch(
            writeToSocket({
                cmd: "dialOutParticipants",
                participants: this.store
                    .getState()
                    .telco.dialouts.filter((d) => d.callState !== dialoutStates.SUCCESS)
                    .map((d) => ({
                        PHONE:
                            "00" +
                            this.store.getState().telco.countryNumbers[d.countryIndex].countryNumber +
                            d.phoneNumber,
                    })),
            })
        );
    }

    setParticipantsDialoutStatus(participants) {
        const withPhone = participants.filter((p) => p.type === "bridge" || p.type === "matched").map((p) => p);
        const { dialouts } = this.store.getState().telco;

        const successfullDialouts = dialouts.filter((d) => {
            for (let k = 0; k < withPhone.length; k += 1) {
                if (withPhone[k].phone.includes(d.phoneNumber)) return true;
            }
            return false;
        });
        for (let i = 0; i < successfullDialouts.length; i += 1) {
            if (successfullDialouts[i].callState !== dialoutStates.SUCCESS)
                this.store.dispatch(setDialoutCallState(successfullDialouts[i].dialoutId, dialoutStates.SUCCESS));
        }

        this.otherDialouts = dialouts.filter((d) => !successfullDialouts.includes(d));
        for (let i = 0; i < this.otherDialouts.length; i += 1) {
            if (this.otherDialouts[i].callState === dialoutStates.SUCCESS)
                this.store.dispatch(setDialoutCallState(this.otherDialouts[i].dialoutId, dialoutStates.INITIAL));
        }

        if (this.setTimeout && this.dialoutStarted) {
            this.setTimeout = false;
            setTimeout(() => {
                for (let i = 0; i < this.otherDialouts.length; i += 1) {
                    this.store.dispatch(setDialoutCallState(this.otherDialouts[i].dialoutId, dialoutStates.FAIL));
                }
                this.store.dispatch(setStartDialout(false));
                this.dialoutStarted = false;
                this.setTimeout = true;
            }, 65000);
        }
    }
}

export default Dialouthandler;
