import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "../../styles/layout.css";
import "./sidebarListHeader.css";

import { setSidebarContentVisibility } from "../../actions";

const SidebarListHeaderComponent = ({ title, info, closeClick }) => (
    <div className="sidebar-list-header">
        <div className="l-tab-row">
            <div className="c-cross" onClick={closeClick} role="button" tabIndex="0" aria-label="close" />
            <div className="l-tab__item-grow">
                <div className="sidebar-list-header-messages">
                    <div className="sidebar-list-header-messages__title">{title}</div>
                    {info ? <div className="sidebar-list-header-messages__info">{info}</div> : null}
                </div>
            </div>
        </div>
    </div>
);

SidebarListHeaderComponent.defaultProps = {
    info: null,
};

SidebarListHeaderComponent.propTypes = {
    title: PropTypes.string.isRequired,
    info: PropTypes.string,
    closeClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    title: ownProps.title,
    info: ownProps.info,
});

const closeClick = () => (dispatch) => {
    dispatch(setSidebarContentVisibility(false));
};

const mapDispatchToProps = {
    closeClick,
};

const SidebarListHeader = connect(mapStateToProps, mapDispatchToProps)(SidebarListHeaderComponent);

export default SidebarListHeader;
