import React, { Component } from "react";
import "../containers/basics/buttons/roundImageButton.css";

class IconHeadset extends Component {
    render() {
        return (
            <div className="c-image__icon">
                <svg
                    version="1.1"
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 34 34"
                    style={{ background: "0 0 34 34" }}
                    xmlSpace="preserve"
                >
                    <path
                        className="st-conn-0"
                        d="M31.2,12.8v-0.6c0-3.3-1.1-6-3.2-8.2c-4.1-4.1-10.8-4-11-4c-0.2,0.1-6.9,0-11,4.1c-2.1,2.1-3.2,4.8-3.2,8.2v0.6
       c-1.6,0.6-2.8,2-2.8,3.8v3.9c-0.1,1.5,1,2.9,2.4,3.5c0.4,1.7,1.3,3.2,2.6,4.5c1.4,1.4,3.2,2.4,5.2,3c0.1,0.5,0.3,0.9,0.7,1.3
       c0.4,0.3,0.8,0.5,1.3,0.5h1.1h1.1c0.6,0,1.1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4v-0.3v-0.2c0-0.6-0.2-1.1-0.6-1.4
       c-0.4-0.4-0.9-0.6-1.4-0.6h-1.1h-1.1c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.7,0.7c-1.5-0.4-2.9-1.2-3.9-2.2c-1-0.9-1.7-2-2.2-3.2
       h0.5V13.9v-0.1v-1.6c0-2.8,0.8-5,2.6-6.6C11,2.2,16.9,2.2,17,2.2c0.1,0,6-0.1,9.5,3.3c1.7,1.7,2.6,3.9,2.6,6.7v1.7c0,0,0,0,0,0.1
       v10.4H30c2.2,0,4-1.8,4-4v-3.9C34,14.8,32.8,13.3,31.2,12.8z"
                    />
                </svg>
            </div>
        );
    }
}

export default IconHeadset;
