import React from "react";
import { connect } from "react-redux";
import Participant from "../../components/Participant";
import DropDownMenu from "./DropDownMenu";
import DropDownMenuEntryRename from "./DropDownMenuEntryRename";
import DropDownMenuEntryMakePresenter from "./DropDownMenuEntryMakePresenter";
import DropDownMenuEntryRevokePresenter from "./DropDownMenuEntryRevokePresenter";
import DropDownMenuEntryRemove from "./DropDownMenuEntryRemove";
import DropDownMenuEntryMute from "./DropDownMenuEntryMute";
import DropDownMenuEntryHold from "./DropDownMenuEntryHold";

const makePresenter = (ownProps, allowScreenshare) => {
    let ret = null;
    if (allowScreenshare && !ownProps.p.presenting && ownProps.p.canScreenshare) {
        ret = (
            <DropDownMenuEntryMakePresenter
                key="makepresenter"
                selectedUserName={ownProps.p.name}
                selectedUID={ownProps.p.uid}
            />
        );
    }
    if (allowScreenshare && ownProps.p.presenting && !ownProps.p.host) {
        ret = (
            <DropDownMenuEntryRevokePresenter
                key="revokepresenter"
                p={ownProps.p}
                selectedUserName={ownProps.p.name}
                selectedUID={ownProps.p.uid}
            />
        );
    }
    return ret;
};

const mapStateToProps = (state, ownProps) => ({
    host: ownProps.p.host,
    presenting: ownProps.p.presenting,
    onHold: ownProps.p.onHold,
    mute: ownProps.p.mute,
    talking: ownProps.p.talking,
    titleText: ownProps.p.name,
    showPresenterSymbol: state.login.allowScreenshare && ownProps.p.canScreenshare,
    presentationActive:
        state.webRtc.remoteStream === "ready" || (state.webRtc.localStream === "ready" && state.webRtc.captureStarted),
    dropdownmenu: (
        <DropDownMenu
            children={[
                <DropDownMenuEntryRename
                    key="rename"
                    selectedUserName={ownProps.p.name}
                    selectedUID={ownProps.p.uid}
                />,
                makePresenter(ownProps, state.login.allowScreenshare),
                <DropDownMenuEntryMute key="muteBridgeparticipant" id={ownProps.p.id} mute={ownProps.p.mute} />,
                <DropDownMenuEntryHold key="holdBridgeparticipant" id={ownProps.p.id} onHold={ownProps.p.onHold} />,
                !ownProps.p.host ? (
                    <DropDownMenuEntryRemove
                        key="delete"
                        selectedUserName={ownProps.p.name}
                        selectedUID={ownProps.p.uid}
                    />
                ) : null,
            ]}
            direction="down"
        />
    ),
});

const ModeratorMatched = connect(mapStateToProps)(Participant);

export default ModeratorMatched;
