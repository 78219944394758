import localizedStrings from "../localizations/localizedStrings.json";
import links from "../links/links.json";

const formatUserLanguage = (userLanguage) => {
    let formattedLanguage = userLanguage;
    const languages = formattedLanguage.split("-");
    if (languages.length > 1) {
        [formattedLanguage] = languages;
    }
    return formattedLanguage;
};

const getLanguage = () => formatUserLanguage(window.navigator.userLanguage || window.navigator.language);

class Translator {
    constructor() {
        this._userLanguage = getLanguage();
    }

    _msg(t, args, strings) {
        const obj = /** @type {string} */ strings[t];
        if (obj === undefined) {
            return t;
        }
        let s = obj[this._userLanguage];
        s = s || strings[t].en;
        if (s === undefined) {
            return t;
        }
        if (args !== undefined) {
            for (let i = 0; i < args.length; i += 1) {
                s = s.replace(`{${i}}`, args[i]);
            }
        }
        return s;
    }

    setUserLanguage(userLanguage) {
        this._userLanguage = userLanguage;
    }

    translate(t, args) {
        return this._msg(t, args, localizedStrings);
    }

    link(t, args) {
        return this._msg(t, args, links);
    }
}

const translator = new Translator();
export default translator;
export const setLanguage = translator.setUserLanguage.bind(translator);
export const translate = translator.translate.bind(translator);
export const link = translator.link.bind(translator);
