import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { sortParticipants } from "../../lib/utils";

import HeaderWeb from "./HeaderWeb";
import ModeratorWeb from "../dropdown/ModeratorWeb";
import ParticipantWeb from "../dropdown/ParticipantWeb";

const ParticipantListWebComponent = ({ isOperator, showWebParticipants, sortedParticipants }) => {
    const pOperator = sortedParticipants.filter((p) => p.host === true)[0];
    return (
        <div>
            <HeaderWeb />
            {/* <SlideDown transitionOnAppear closed={false}> */}
            {showWebParticipants
                ? sortedParticipants.map((p, i) => {
                      const containerRef = (
                          <div key={p.uid}>
                              {isOperator ? <ModeratorWeb p={p} pOp={pOperator} /> : <ParticipantWeb p={p} />}
                              {i < sortedParticipants.length - 1 ? (
                                  <div className="sidebar-entry-container-divider" />
                              ) : null}
                          </div>
                      );
                      return containerRef;
                  })
                : null}
            {/* </SlideDown> */}
        </div>
    );
};

ParticipantListWebComponent.propTypes = {
    isOperator: PropTypes.bool.isRequired,
    showWebParticipants: PropTypes.bool.isRequired,
    sortedParticipants: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    isOperator: state.login.isOperator,
    showWebParticipants: state.modal.showWebParticipants,
    sortedParticipants: sortParticipants(state.participants.web, state.login.uid),
});

const ParticipantListWeb = connect(mapStateToProps)(ParticipantListWebComponent);

export default ParticipantListWeb;
