import { connect } from "react-redux";

import SidebarButton from "../../components/SidebarButton";
import { SETTINGS_LIST } from "../../constants/sidebarConstants";
import { translate } from "../../lib/Translator";
import { setSidebarContentId, setSidebarContentVisibility } from "../../actions";

const mapStateToProps = (state) => ({
    show: true, // not evaluated
    active: state.modal.sidebarContentId === SETTINGS_LIST && state.modal.showSidebarContent,
    liClassname: "settings",
    actionClassname: "action-settings",
    tooltipText: translate("Settings.Title"),
});

const mapDispatchToProps = (dispatch) => ({
    onClick: (active) => {
        dispatch(setSidebarContentId(SETTINGS_LIST));
        setTimeout(() => {
            dispatch(setSidebarContentVisibility(active));
        }, 1); // Hack to preserve transition animation
    },
});

const SidebarButtonSettings = connect(mapStateToProps, mapDispatchToProps)(SidebarButton);

export default SidebarButtonSettings;
