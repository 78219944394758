import React, { Component } from "react";

import "../styles/sidebar.css";

class IconSidebarEntryOnHold extends Component {
    render() {
        return (
            <span className="sidebar-entry-content-symbols__on-hold-inline">
                <svg
                    version="1.1"
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 16 16"
                    xmlSpace="preserve"
                >
                    <path
                        d="M4,10.9v3.5H2.9V16H13v-1.6h-0.8v-3.5c0-0.8-1.4-1.8-3.6-3.3c2.3-1.5,3.6-2.5,3.6-3.3V0.5H13V0H2.9v0.5H4v3.7
            C4,5,5.4,6,7.6,7.5C5.4,9.1,4,10.1,4,10.9z M4.5,4.3V0.5h7.2v3.7c0,0.6-2,1.9-3.6,3C6.5,6.2,4.5,4.9,4.5,4.3z M8.1,7.9
            c1.6,1.1,3.6,2.4,3.6,3v3.5H4.5v-3.5C4.5,10.3,6.5,9,8.1,7.9z"
                    />
                    <rect x="3" y="0" width="10" height="1" />
                    <rect x="3" y="15" width="10" height="1" />
                    <g>
                        <path d="M8,8C6.9,8,4,6.3,4,4.9V1h8v3.9C12,6.3,9.1,8,8,8z M4.9,1.9V5c0,0.6,2.2,2.2,3.1,2.2s3.1-1.6,3.1-2.2V1.9H4.9z" />
                    </g>
                    <g>
                        <path d="M12,15H4v-3.9C4,9.7,6.9,8,8,8s4,1.7,4,3.1V15z M4.9,14.1h6.2V11c0-0.6-2.2-2.2-3.1-2.2S4.9,10.4,4.9,11V14.1z" />
                    </g>
                </svg>
            </span>
        );
    }
}

export default IconSidebarEntryOnHold;
