import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { SHOW_SPINNER } from "../../constants/modalConstants";

import "./spinner.css";

const SpinnerComponent = ({ show, text }) =>
    show ? (
        <div className="l-tab-col">
            <div key="spinner" className="spinner">
                <div className="spinner__circle spinner__circle--grey" />
            </div>
            {text === null ? null : (
                <p className="spinner-text" key="message">
                    {text}
                    ...
                </p>
            )}
        </div>
    ) : null;

SpinnerComponent.defaultProps = {
    text: null,
};

SpinnerComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    text: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => ({
    show: state.modal.modalContent === SHOW_SPINNER,
    text: ownProps.text,
});

const Spinner = connect(mapStateToProps)(SpinnerComponent);

export default Spinner;
