import { connect } from "react-redux";

import ListHeader from "../../components/ListHeader";
import { setWebParticipantVisibility } from "../../actions";
import { translate } from "../../lib/Translator";

const onClick = () => (dispatch, getState) => {
    dispatch(setWebParticipantVisibility(!getState().modal.showWebParticipants));
};

const mapStateToProps = (state) => ({
    show: state.participants.web.length !== 0,
    showSidebarContent: state.modal.showWebParticipants,
    title: translate("ParticipantList.OnlyWeb"),
});

const mapDispatchToProps = {
    click: onClick,
};

const HeaderWeb = connect(mapStateToProps, mapDispatchToProps)(ListHeader);

export default HeaderWeb;
