import { combineReducers } from "redux";
import { ActionTypes } from "redux-mw-ws";

import { PRESENTATION_OVER_BOX } from "../constants/modalConstants";
import modal, { modalInitialState } from "./modal";
import login, { loginInitialState } from "./login";
import webRtc, { webrtcInitialState } from "./webRtc";
import participants, { participantsInitialState } from "./participants";
import conference, { conferenceInitialState } from "./conference";
import telco, { telcoInitialState } from "./telco";
import settings, { settingsInitialState } from "./settings";
import chat, { chatInitialState } from "./chat";

const appReducer = combineReducers({
    modal,
    login,
    webRtc,
    participants,
    conference,
    telco,
    settings,
    chat,
});

const resetState = (state) => {
    state.login = loginInitialState;
    state.modal = modalInitialState;
    state.participants = participantsInitialState;
    state.webRtc = webrtcInitialState;
    state.conference = conferenceInitialState;
    state.telco = telcoInitialState;
    state.settings = settingsInitialState;
    state.chat = chatInitialState;
    return state;
};

const handlePresentationOver = (state, data, isOperator) => {
    const reason = data.reason !== null && data.reason !== undefined ? data.reason : "";

    // TODO: remove
    if (reason === "wasKicked") {
        state.login.isOver = true;
        state.login.wasKicked = true;
        state.modal.modalId = PRESENTATION_OVER_BOX;
    }

    if (reason === "presStoppedByMod") {
        if (!isOperator) {
            state.login.isOver = true;
            state.modal.modalId = PRESENTATION_OVER_BOX;
        }
    }
    if (reason === "overTime") {
        state.login.isOver = true;
        state.modal.modalId = PRESENTATION_OVER_BOX;
    }
    return state;
};

const rootReducer = (state, action) => {
    let modifiedState = state;
    if (action.type === ActionTypes.RECEIVED_WEBSOCKET_DATA && action.payload.cmd === "presentationOver") {
        window.onbeforeunload = null;
        const { isOperator } = state.login;
        modifiedState = resetState({ ...state });
        modifiedState = handlePresentationOver(modifiedState, action.payload, isOperator);
    }

    return appReducer(modifiedState, action);
};

export default rootReducer;
