import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Clipboard from "clipboard";

import { INVITATION_MAIL } from "../../constants/modalConstants";

import { translate } from "../../lib/Translator";

import "../../styles/layout.css";
import "../basics/buttons/button.css";
import styles from "./invitationMail.module.css";

const InvitationMailComponent = ({ show, labelText, btnText, copiedText, invitationMail }) => {
    new Clipboard(".clipboard"); // eslint-disable-line no-new
    return show ? (
        <div className="l-tab-col">
            <div className={styles["c-label"]}>{labelText}</div>
            <textarea className={styles["c-textarea-input"]} type="text" defaultValue={invitationMail} rows="10" />
            <div className={styles["c-button"]}>
                <div
                    className="c-btn c-btn--max c-btn--branded clipboard"
                    data-clipboard-text={invitationMail}
                    onClick={(e) => {
                        const target = e.currentTarget;
                        target.innerHTML = copiedText;
                        setTimeout(() => {
                            target.innerHTML = btnText;
                        }, 2000);
                    }}
                    role="button"
                    tabIndex="0"
                >
                    {btnText}
                </div>
            </div>
        </div>
    ) : null;
};

InvitationMailComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    labelText: PropTypes.string.isRequired,
    btnText: PropTypes.string.isRequired,
    invitationMail: PropTypes.string.isRequired,
    copiedText: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    show: state.modal.modalContent === INVITATION_MAIL,
    invitationMail: state.telco.invitationMail,
    labelText: translate("Invite.Email"),
    btnText: translate("Invite.CopyEmail"),
    copiedText: translate("Invite.Copied"),
});

const InvitationMail = connect(mapStateToProps)(InvitationMailComponent);

export default InvitationMail;
