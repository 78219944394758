import { connect } from "react-redux";

import SidebarButton from "../../components/SidebarButton";
import {
    setSidebarContentVisibility,
    setSidebarContentId,
    setNumberOfLastReadMessage,
    setNumberOfUnreadMessages,
} from "../../actions";
import { CHAT_LIST } from "../../constants/sidebarConstants";

const mapStateToProps = (state) => ({
    show: true, // not evaluated
    active: state.modal.sidebarContentId === CHAT_LIST && state.modal.showSidebarContent,
    liClassname: "",
    actionClassname: "action-chat",
    tooltipText: "Chat",
    showFullscreenMode: state.modal.showFullscreenMode,
    showBadge: !state.modal.showSidebarContent || state.modal.sidebarContentId !== CHAT_LIST,
    badgeValue: state.chat.numberOfUnreadMessages.toString(),
});

const mapDispatchToProps = (dispatch) => ({
    onClick: (active) => {
        dispatch(setSidebarContentId(CHAT_LIST));
        setTimeout(() => {
            dispatch(setSidebarContentVisibility(active));
        }, 1); // Hack to preserve transition animation
        dispatch(setNumberOfLastReadMessage("MAX"));
        dispatch(setNumberOfUnreadMessages(0));
    },
});

const SidebarButtonFullscreen = connect(mapStateToProps, mapDispatchToProps)(SidebarButton);

export default SidebarButtonFullscreen;
