class StreamContainer {
    constructor() {
        this._localStream = null;
        this._remoteStream = null;
    }

    get localStream() {
        return this._localStream;
    }

    set localStream(localStream) {
        this._localStream = localStream;
    }

    get remoteStream() {
        return this._remoteStream;
    }

    set remoteStream(remoteStream) {
        this._remoteStream = remoteStream;
    }
}

const streamContainer = new StreamContainer();
export default streamContainer;
