import { ActionTypes } from "redux-mw-ws";

export const conferenceInitialState = {
    globalMute: false,
    globalLock: false,
    globalRecording: false,
};

const conference = (state = conferenceInitialState, action) => {
    switch (action.type) {
        case ActionTypes.RECEIVED_WEBSOCKET_DATA:
            if (action.payload.cmd === "conferenceState") {
                const a = { ...action };
                return {
                    ...state,
                    globalMute: a.payload.value.globalMute,
                    globalLock: a.payload.value.globalLock,
                    globalRecording: a.payload.value.globalRecording,
                };
            }
            return state;
        default:
            return state;
    }
};

export default conference;
