import React from "react";
import { connect } from "react-redux";

import { REMOVE_PARTICIPANT_BOX } from "../../constants/modalConstants";
import { setModalVisibility, writeToSocket } from "../../actions";
import { translate } from "../../lib/Translator";
import { modalText } from "../../lib/utils";
import Modal from "../../components/modal/Modal";
import SimpleDecissionButtons from "../basics/buttons/SimpleDecissionButtons";

const abortClick = () => setModalVisibility("");
const acceptClick = (dispatch, state) => {
    dispatch(setModalVisibility(""));
    dispatch(
        writeToSocket({
            cmd: "removeParticipant",
            uid: state.participants.selectedUID,
        })
    );
};

const mapStateToProps = (state) => ({
    show: state.modal.modalId === REMOVE_PARTICIPANT_BOX,
    title: translate("RemoveParticipant.Title").toUpperCase(),
    contentChildren: <span>{modalText("RemoveParticipant.Message", state.participants.selectedUserName)}</span>,
    buttonChildren: (
        <SimpleDecissionButtons
            acceptClick={(dispatch) => {
                acceptClick(dispatch, state);
            }}
        />
    ),
});

const mapDispatchToProps = {
    onClose: abortClick,
};

const RemoveParticipantChildren = connect(mapStateToProps, mapDispatchToProps)(Modal);

export default RemoveParticipantChildren;
