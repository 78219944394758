import React, { Component } from "react";
import "../containers/start.css";
import "../styles/main.css";

class IconStartParticipant extends Component {
    render() {
        return (
            <div className="starticon">
                <svg
                    version="1.1"
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="-10 -10 140 140"
                    width="140"
                    height="150"
                    xmlSpace="preserve"
                >
                    <defs>
                        <filter id="dropshadow" x="-20%" y="-20%" width="130%" height="130%">
                            <feGaussianBlur in="SourceAlpha" stdDeviation="5" />
                            <feOffset dx="0" dy="3" result="offsetblur" />
                            <feComponentTransfer>
                                <feFuncA type="linear" slope="0.6" />
                            </feComponentTransfer>
                            <feMerge>
                                <feMergeNode />
                                <feMergeNode in="SourceGraphic" />
                            </feMerge>
                        </filter>
                    </defs>
                    <g>
                        <circle className="st0" cx="60" cy="60" r="60" style={{ filter: "url(#dropshadow)" }} />

                        <path
                            className="st1"
                            d="M86.6,73.6l-12.1-5.8c1.6-0.8,7.9-1.1,8.5-2.5c0,0-2.5-3.8-3.1-8.8c-0.2-1.9-0.6-4.5-1-8
            c-0.7-5.8-5.5-9.9-11.7-9.9c0,0,0,0,0,0c0,0,0,0,0,0c-0.4,0-0.7,0-1.1,0c1.7,2.2,2.5,4.8,2.5,7.8c0,1.9-0.1,3.7-0.3,5.6
            c0.8,0.9,1.2,2,1.2,3.2c0,1.2-0.5,2.4-1.3,3.2c-0.3,0.3-0.6,0.5-0.9,0.8c-0.6,2.3-1.7,4.3-3.2,6c2.5,1.6,8.4,4.8,13.6,7.4l0,0l0,0
            c0.3,0.2,3.1,1.9,3.1,4.6v4.6c0,0.5,0,0.9-0.1,1.3h5.9c1.5,0,2-1.1,2-2.7v-3.7C88.6,74.5,86.6,73.6,86.6,73.6z"
                        />

                        <path
                            className="st1"
                            d="M76.7,74.2c0,0-16.1-8.1-15.4-9c2.2-1.9,3.6-4.3,4.2-7.2c0.4-0.1,0.8-0.4,1.2-0.8c0.5-0.5,0.8-1.2,0.8-2
            c0-0.8-0.3-1.5-0.8-2.1c-0.3-0.3-0.4-0.5-0.5-0.8c0.3-2,0.4-4,0.4-5.9c0-3.2-1.1-5.9-3.4-8c-2-2-4.4-3.1-7.1-3.3c-0.3,0-0.6,0-0.9,0
            c-0.1,0-0.2,0-0.3,0c-0.3,0-0.6,0-0.9,0c-2.8,0.2-5.1,1.3-7.1,3.3c-2.2,2.2-3.4,4.9-3.3,8c0,2.1,0.1,4,0.4,5.8c0,0.4-0.2,0.7-0.5,1
            c-0.6,0.6-0.8,1.3-0.8,2.1c0,0.8,0.2,1.4,0.8,2c0.3,0.4,0.7,0.6,1.2,0.8c0.7,2.9,2.1,5.3,4.3,7.2c0.9,1-15.4,9-15.4,9
            s-2.2,1.4-2.2,3.1v4.6c0,1.7,0.5,3.1,2.2,3.1h43.1c1.7,0,2.2-1.4,2.2-3.1v-4.6C79,75.5,76.7,74.2,76.7,74.2z"
                        />
                    </g>
                </svg>
            </div>
        );
    }
}

export default IconStartParticipant;
