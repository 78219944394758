import React, { Component } from "react";
import "../containers/basics/buttons/roundImageButton.css";

class IconDialOut extends Component {
    render() {
        return (
            <div className="c-image__icon">
                <svg
                    version="1.1"
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 34 34"
                    style={{ background: "0 0 34 34" }}
                    xmlSpace="preserve"
                >
                    <g id="Page-1_1_">
                        <g id="Icons-Communication_1_" transform="translate(-85.000000, -126.000000)">
                            <g id="phone_1_" transform="translate(85.000000, 126.000000)">
                                <path
                                    id="Shape_1_"
                                    className="st-conn-0"
                                    d="M6.8,14.8C9.4,20,14,24.4,19.2,27.2l4.2-4.2c0.6-0.6,1.3-0.8,1.9-0.4c2.1,0.8,4.3,1.1,6.8,1.1
                   c1.1,0,1.9,0.8,1.9,1.9V32c0,1.1-0.8,1.9-1.9,1.9C14.4,34,0,19.6,0,2c0-1.1,0.8-1.9,1.9-1.9h6.6c1.1,0,1.9,0.8,1.9,1.9
                   c0,2.3,0.4,4.5,1.1,6.8c0.2,0.6,0,1.3-0.4,1.9L6.8,14.8L6.8,14.8z"
                                />
                            </g>
                        </g>
                    </g>
                    <g id="gN5x4a.tif" className="st-conn-1">
                        <image
                            style={{ display: "inline", overflow: "visible" }}
                            width="34"
                            height="33"
                            id="Ebene_1_2_"
                            xlinkHref="674DB5D12CFCFE24.png"
                            transform="matrix(1 0 0 1 0 1)"
                        />
                    </g>
                    <polygon className="st-conn-0" points="20,10 27,15 27,12 34,12 34,8 27,8 27,5 " />
                </svg>
            </div>
        );
    }
}

export default IconDialOut;
