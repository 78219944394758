import React from "react";
import { connect } from "react-redux";

import { MAKE_PRESENTER_BOX } from "../../constants/modalConstants";
import { writeToSocket, setModalVisibility, setDidRevokePresenter, setWasPresenter } from "../../actions";
import { translate } from "../../lib/Translator";
import { modalText } from "../../lib/utils";
import Modal from "../../components/modal/Modal";
import SimpleDecissionButtons from "../basics/buttons/SimpleDecissionButtons";

const abortClick = () => setModalVisibility("");
const acceptClick = (dispatch, state) => {
    dispatch(
        writeToSocket({
            cmd: "makePresenter",
            uid: state.participants.selectedUID,
            initiatorUid: state.login.uid,
        })
    );
    dispatch(setModalVisibility(""));
    dispatch(setDidRevokePresenter(false));
    dispatch(setWasPresenter(true));
};

const mapStateToProps = (state) => ({
    show: state.modal.modalId === MAKE_PRESENTER_BOX,
    title: translate("MakePresenter.Title").toUpperCase(),
    contentChildren:
        state.login.uid === state.participants.selectedUID ? (
            <span>{translate("MakePresenter.MessageMod")}</span>
        ) : (
            <span>{modalText("MakePresenter.MessagePart", state.participants.selectedUserName)}</span>
        ),
    buttonChildren: (
        <SimpleDecissionButtons
            acceptClick={(dispatch) => {
                acceptClick(dispatch, state);
            }}
        />
    ),
});

const mapDispatchToProps = {
    onClose: abortClick,
};

const MakePresenter = connect(mapStateToProps, mapDispatchToProps)(Modal);

export default MakePresenter;
