import { connect } from "react-redux";

import ListHeader from "../../components/ListHeader";
import { setAudioParticipantVisibility } from "../../actions";
import { translate } from "../../lib/Translator";

const onClick = () => (dispatch, getState) => {
    dispatch(setAudioParticipantVisibility(!getState().modal.showAudioParticipants));
};

const mapStateToProps = (state) => ({
    show: state.participants.audio.length !== 0,
    showSidebarContent: state.modal.showAudioParticipants,
    title: translate("ParticipantList.OnlyAudio"),
});

const mapDispatchToProps = {
    click: onClick,
};

const HeaderWeb = connect(mapStateToProps, mapDispatchToProps)(ListHeader);

export default HeaderWeb;
