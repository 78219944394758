import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "../../../styles/layout.css";

import { translate } from "../../../lib/Translator";
import { INVITATION_LINK, INVITATION_MAIL, INVITATION_DIALOUT } from "../../../constants/modalConstants";
import { setSelectedButton, setModalContent } from "../../../actions";

import RoundImageButton from "./RoundImageButton";
import IconCopyLink from "../../../inlineSvgs/IconCopyLink";
import IconCopyTemplate from "../../../inlineSvgs/IconCopyTemplate";
import IconDialOut from "../../../inlineSvgs/IconDialOut";

const InvitationBarComponent = ({
    showDialout,
    invitationLinkText,
    invitationMailText,
    invitationDialoutText,
    inviteLinkFn,
    inviteMailFn,
    inviteDialoutFn,
}) => (
    <div className="l-tab-row">
        <div className="l-tab__item-grow l-center-x">
            <RoundImageButton
                key="welcome-box-dialin"
                buttonText={invitationLinkText}
                buttonImageComp={<IconCopyLink />}
                buttonType={INVITATION_LINK}
                buttonClick={inviteLinkFn}
            />
        </div>
        <div className="l-tab__item-grow l-center-x">
            <RoundImageButton
                key="welcome-box-headset"
                buttonText={invitationMailText}
                buttonImageComp={<IconCopyTemplate />}
                buttonType={INVITATION_MAIL}
                buttonClick={inviteMailFn}
            />
        </div>
        {showDialout ? (
            <div className="l-tab__item-grow l-center-x">
                <RoundImageButton
                    key="welcome-box-dialout"
                    buttonText={invitationDialoutText}
                    buttonImageComp={<IconDialOut />}
                    buttonType={INVITATION_DIALOUT}
                    buttonClick={inviteDialoutFn}
                />
            </div>
        ) : null}
    </div>
);

InvitationBarComponent.propTypes = {
    showDialout: PropTypes.bool.isRequired,
    invitationLinkText: PropTypes.string.isRequired,
    invitationMailText: PropTypes.string.isRequired,
    invitationDialoutText: PropTypes.string.isRequired,
    inviteLinkFn: PropTypes.func.isRequired,
    inviteMailFn: PropTypes.func.isRequired,
    inviteDialoutFn: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    showDialout: state.modal.dialOutAllowed,
    invitationLinkText: translate("Invitation.Link").toUpperCase(),
    invitationMailText: translate("Invitation.Mail").toUpperCase(),
    invitationDialoutText: translate("Invitation.Dialout").toUpperCase(),
});

const mapDispatchToProps = (dispatch) => ({
    inviteLinkFn: () => {
        dispatch(setSelectedButton(INVITATION_LINK));
        dispatch(setModalContent(INVITATION_LINK));
    },
    inviteMailFn: () => {
        dispatch(setSelectedButton(INVITATION_MAIL));
        dispatch(setModalContent(INVITATION_MAIL));
    },
    inviteDialoutFn: () => {
        dispatch(setSelectedButton(INVITATION_DIALOUT));
        dispatch(setModalContent(INVITATION_DIALOUT));
    },
});

const InvitationBar = connect(mapStateToProps, mapDispatchToProps)(InvitationBarComponent);

export default InvitationBar;
