import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import "../../styles/sidebar.css";
import "../../styles/dropdownmenu.css";
import "../../styles/format.css";
import "../../styles/main.css";
import "../../styles/fullscreen.css";

import ErrorBoundary from "../errorHandling/ErrorBoundary";

import Presentation from "../presentation/Presentation";
import SidebarButtonParticipantList from "./SidebarButtonParticipantList";
import SidebarButtonInvitation from "./SidebarButtonInvitation";
import SidebarButtonConnectAudio from "./SidebarButtonConnectAudio";
import SidebarButtonChat from "./SidebarButtonChat";
import SidebarButtonFullscreen from "./SidebarButtonFullscreen";
import SidebarButtonLogout from "./SidebarButtonLogout";
import SidebarButtonSettings from "./SidebarButtonSettings";
import SettingsList from "./SettingsList";
import ChatList from "./ChatList";
import ParticipantList from "./ParticipantList";

const SidebarComponent = ({ login, isOperator, showFullscreenMode }) =>
    !login || isOperator === null ? null : (
        <div className={`centerer ${showFullscreenMode ? "welcomescreen-fullscreen" : "welcomescreen"}`}>
            <ErrorBoundary>
                <ParticipantList />
            </ErrorBoundary>
            <ErrorBoundary>
                <SettingsList />
            </ErrorBoundary>
            <ErrorBoundary>
                <ChatList />
            </ErrorBoundary>
            <ErrorBoundary>
                <Presentation />
            </ErrorBoundary>
            <ErrorBoundary>
                <div className={`${showFullscreenMode ? "fullscreen-controls" : "sidebar"}`}>
                    <ul>
                        <SidebarButtonParticipantList />
                        <SidebarButtonInvitation />
                        <SidebarButtonConnectAudio />
                        <SidebarButtonChat />
                        <SidebarButtonFullscreen />
                        <SidebarButtonSettings />
                        <SidebarButtonLogout />
                    </ul>
                </div>
            </ErrorBoundary>
        </div>
    );

SidebarComponent.propTypes = {
    login: PropTypes.bool.isRequired,
    isOperator: PropTypes.bool.isRequired,
    showFullscreenMode: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    login: state.login.login,
    isOperator: state.login.isOperator,
    showFullscreenMode: state.modal.showFullscreenMode,
});

const Sidebar = connect(mapStateToProps)(SidebarComponent);

export default Sidebar;
