import React from "react";
import { connect } from "react-redux";

import { ERROR_BOX } from "../../constants/modalConstants";
import Modal from "../../components/modal/Modal";
import SimpleAcceptButton from "../basics/buttons/SimpleAcceptButton";
import { setModalVisibility, setErrorTitle, setErrorMessage } from "../../actions";
import { translate } from "../../lib/Translator";

const onClose = (dispatch) => {
    dispatch(setErrorTitle(""));
    dispatch(setModalVisibility(""));
    dispatch(setErrorMessage(""));
};

const mapStateToProps = (state) => ({
    show: state.modal.modalId === ERROR_BOX,
    title:
        state.modal.errorTitle !== ""
            ? translate(state.modal.errorTitle).toUpperCase()
            : translate("Error.Title").toUpperCase(),
    contentChildren: (
        <div>
            <div className={`messagebox ${state.modal.isInfo ? "info" : "error"} pre-line`}>
                {translate(state.modal.errorMsg)}
            </div>
            <div className={`imagebox ${state.modal.errorImgCls ? state.modal.errorImgCls : "hidden"}`} />
        </div>
    ),
    buttonChildren: (
        <SimpleAcceptButton
            acceptText="OK"
            additionalFunc={(dispatch) => {
                dispatch(setErrorTitle(""));
                dispatch(setModalVisibility(""));
                dispatch(setErrorMessage(""));
                const { errorAction } = state.modal;
                if (errorAction) {
                    dispatch(errorAction);
                }
            }}
        />
    ),
});

const mapDispatchToProps = (dispatch) => ({
    onClose: () => onClose(dispatch),
});

const BecomePresenter = connect(mapStateToProps, mapDispatchToProps)(Modal);

export default BecomePresenter;
