import React from "react";
import { connect } from "react-redux";

import ErrorBoundary from "../errorHandling/ErrorBoundary";

import EndPresentation from "./EndPresentation";
import BecomePresenter from "./BecomePresenter";
import DownloadApp from "./DownloadApp";
import Error from "./Error";
import KickBridgeParticipant from "./KickBridgeParticipant";
import MakePresenter from "./MakePresenter";
import PresentationOver from "./PresentationOver";
import PresenterRevoked from "./PresenterRevoked";
import RemoveParticipant from "./RemoveParticipant";
import RevokePresenter from "./RevokePresenter";
import LoginBox from "./LoginBox";
import RenameParticipantBox from "./RenameParticipantBox";
import ElectronSelectBox from "./ElectronSelectBox";
import FeedbackBox from "./feedback/FeedbackBox";
import WelcomeScreen from "./WelcomeScreen";
import Connections from "./Connections";
import Invitations from "./Invitations";

const ModalBoxComponent = () => (
    <ErrorBoundary>
        <EndPresentation />
        <BecomePresenter />
        <DownloadApp />
        <Error />
        <KickBridgeParticipant />
        <MakePresenter />
        <PresentationOver />
        <PresenterRevoked />
        <RemoveParticipant />
        <RevokePresenter />
        <LoginBox />
        <RenameParticipantBox />
        <FeedbackBox />
        <ElectronSelectBox />
        <WelcomeScreen />
        <Connections />
        <Invitations />
    </ErrorBoundary>
);

const mapStateToProps = () => ({});

const ModalBox = connect(mapStateToProps)(ModalBoxComponent);

export default ModalBox;
