import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../styles/dropdownmenu.css";
import "../../styles/sidebar.css";
import "../../styles/format.css";

import { hasArrayAllNullEntries } from "../../lib/utils";

class DropDownMenu extends Component {
    constructor() {
        super();
        this.state = {
            show: false,
            clicked: false,
        };

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleMousUp = this.handleMousUp.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mouseup", this.handleMousUp);
    }

    componentWillUnmount() {
        document.removeEventListener("mouseup", this.handleMousUp);
    }

    setWrapperRef(node) {
        const { parentNodeDepth } = this.props;
        let newNode = node;
        if (newNode !== null) {
            let i;
            for (i = 0; i < parentNodeDepth; i += 1) {
                newNode = newNode.parentNode;
            }
        }
        this.wrapperRef = newNode;
    }

    _setVisible(b) {
        const { show } = this.state;
        let newB = b;
        // console.log("start props: %o", b);
        if (newB === undefined) newB = !show;
        this.setState({ show: newB });
    }

    handleMousUp = (event) => {
        const { show, clicked } = this.state;
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            // console.log("in click outside %o", event.target);
            this.setState({ clicked: false });
            this._setVisible(false);
        } else if (clicked && show) {
            this.setState({ clicked: false });
            this._setVisible(false);
        } else {
            this._setVisible();
            this.setState({ clicked: true });
        }
    };

    render() {
        const { show } = this.state;
        const { children, showSymbol, direction } = this.props;
        if (children.length === 0 || hasArrayAllNullEntries(children)) return null;

        return (
            <div
                className={`${showSymbol ? "dropdown-button" : "dropdown-button--no-symbol"}`}
                ref={this.setWrapperRef}
            >
                {show ? (
                    <div
                        id="myDropdown"
                        className={
                            direction === "up"
                                ? "dropdown-content dropdown-content-up"
                                : "dropdown-content dropdown-content-down"
                        }
                    >
                        {children}
                    </div>
                ) : null}
            </div>
        );
    }
}

DropDownMenu.defaultProps = {
    showSymbol: true,
    parentNodeDepth: 0,
};

DropDownMenu.propTypes = {
    children: PropTypes.node.isRequired,
    direction: PropTypes.string.isRequired,
    parentNodeDepth: PropTypes.node,
    showSymbol: PropTypes.bool,
};

export default DropDownMenu;
