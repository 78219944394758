export const LOGIN_BOX = "login-box";
export const RENAME_PARTICIPANT_BOX = "rename-participant-box";
export const INVITE_BOX = "invite-box";
export const CONNECT_AUDIO_BOX = "connect-audio-box";

export const FEEDBACK_BOX = "feedback-box";
export const ELECTRON_SELECT_BOX = "electron-select-box";
export const END_PRESENTATION_BOX = "end-presentation-box";
export const BECOME_PRESENTER_BOX = "become-presenter-box";

export const MAKE_PRESENTER_BOX = "make-presenter-box";
export const REVOKE_PRESENTER_BOX = "revoke-presenter-box";
export const PRESENTATION_OVER_BOX = "presentation-over-box";
export const PRESENTER_REVOKED_BOX = "presenter-revoked-box";

export const REMOVE_PARTICIPANT_BOX = "remove-participant-box";
export const KICK_BRIDGE_PARTICIPANT_BOX = "kick-bridge-participant-box";
export const PARTICIPANT_LIST_SETTINGS_BOX = "participants-list-settings-box";
export const ERROR_BOX = "error-box";

export const DOWNLOAD_BOX = "download-box";
export const PHONE_DIALIN_BOX = "phone-dialin-box";
export const PHONE_DIALOUT_BOX = "phone-dialout-box";

export const WELCOME_SCREEN = "welcome-screen";
export const CONNECT_VIA_PC = "pc-connect-box";
export const SIDEBAR_USERMENU = "sidebar-usermenu";
export const CONNECTIONS_SCREEN = "connections-screen";

export const SIDEBAR_CONNECT_AUDIO_MENU = "sidebar-connect-audio-menu";
export const MAX_TOUR_INDEX = 6;
export const SHOW_SPINNER = "show-spinner";
export const VOIP_FAILED = "voip-failed";

export const INVITATION_SCREEN = "invitation-screen";
export const INVITATION_LINK = "invitation-link";
export const INVITATION_MAIL = "invitation-mail";
export const INVITATION_DIALOUT = "invitation-dialout";
