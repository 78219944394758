import { connect } from "react-redux";

import { PHONE_DIALIN_BOX, SIDEBAR_CONNECT_AUDIO_MENU, CONNECTIONS_SCREEN } from "../../constants/modalConstants";
import SidebarButton from "../../components/SidebarButton";

import { setModalVisibility, setSelectedButton, setModalContent } from "../../actions";
import { translate } from "../../lib/Translator";

const mapStateToProps = (state) => ({
    show: true,
    active: state.modal.modalId === SIDEBAR_CONNECT_AUDIO_MENU,
    liClassname: "audiofullscreen",
    actionClassname: "action-audio",
    tooltipText: translate("Sidebar.ConnectViaAudio"),
});

const onClick = (dispatch) => {
    dispatch(setModalVisibility(CONNECTIONS_SCREEN));
    dispatch(setSelectedButton(PHONE_DIALIN_BOX));
    dispatch(setModalContent(PHONE_DIALIN_BOX));
};

const mapDispatchToProps = (dispatch) => ({
    onClick: (active) => onClick(dispatch, active),
});

const SidebarButtonConnectAudio = connect(mapStateToProps, mapDispatchToProps)(SidebarButton);

export default SidebarButtonConnectAudio;
