import { ActionTypes } from "redux-mw-ws";
import {
    BECOME_PRESENTER_BOX,
    PRESENTER_REVOKED_BOX,
    ERROR_BOX,
    MAX_TOUR_INDEX,
    PHONE_DIALIN_BOX,
} from "../constants/modalConstants";
import {
    LOGIN_ERROR_PIN,
    LOGIN_ERROR_CREATE,
    LOGIN_ERROR_NAME,
    LOGIN_ERROR_HOST,
    LOGIN_ERROR_FULL,
    LOGIN_ERROR_LOCK,
    LOGIN_ERROR_HOSTNAME,
} from "../constants/commonConstants";
import { PARTICIPANT_LIST } from "../constants/sidebarConstants";

import { translate } from "../lib/Translator";

export const modalInitialState = {
    isUserActive: true,
    errorMsg: "",
    modalId: "",
    showSidebarContent: false,
    showWebParticipants: true,
    showAudioParticipants: true,
    showMatchedParticipants: true,
    showFullscreenMode: false,
    didRevokePresenter: false,
    wasPresenter: false,
    errorImgCls: null,
    electronIndexToCapture: 0,
    connectionQuality: "ok",
    sourceModalId: "",
    dialOutAllowed: false,
    isInfo: false,
    errorTitle: "",
    sidebarContentId: PARTICIPANT_LIST,
    canReceiveWebRtc: false,
    canScreenshare: false,
    errorCode: "",
    tourIndex: 0,
    errorAction: null,
    selectedButton: PHONE_DIALIN_BOX,
    modalContent: PHONE_DIALIN_BOX,
    showTour: true,
    feedbackSent: false,
    welcomeTourFromLogin: false,
    featureTextIndex: 0,
    showCountries: false,
};

const handleLogin = (state, payload) => {
    let ret = { ...state };
    const data = { ...payload };
    if (data.success === false) {
        if (data.error === undefined || data.error === LOGIN_ERROR_PIN || data.error === LOGIN_ERROR_CREATE)
            ret = {
                ...ret,
                errorCode: data.error,
                errorMsg: translate("Error.Pin"),
            };
        if (data.error === LOGIN_ERROR_NAME)
            ret = {
                ...ret,
                errorCode: data.error,
                errorMsg: translate("Error.Name"),
            };
        if (data.error === LOGIN_ERROR_HOST)
            ret = {
                ...ret,
                errorCode: data.error,
                errorMsg: translate("Error.Host"),
            };
        if (data.error === LOGIN_ERROR_FULL)
            ret = {
                ...ret,
                errorCode: data.error,
                errorMsg: translate("Error.Full"),
            };
        if (data.error === LOGIN_ERROR_LOCK)
            ret = {
                ...ret,
                errorCode: data.error,
                errorMsg: translate("Error.Lock"),
                isInfo: true,
            };
        if (data.error === LOGIN_ERROR_HOSTNAME)
            ret = {
                ...ret,
                errorCode: data.error,
                errorMsg: translate("Error.Hostname"),
                isInfo: true,
            };
    } else {
        ret = {
            ...ret,
            dialOutAllowed: data.dialOutAllowed,
            isInfo: false,
            canReceiveWebRtc: data.canReceiveWebRtc,
            canScreenshare: data.canScreenshare,
            showNewFeatures: data.showNewFeatures,
            newFeatures: data.newFeatures,
        };
    }
    return ret;
};

const handleBecomePresenter = (state, payload) => {
    const tmp = {
        ...state,
        wasPresenter: false,
    };
    return {
        ...tmp,
        formerPresenter: payload.formerPresenter,
        becomePresenterReason: payload.reason,
        modalId:
            !tmp.didRevokePresenter && !tmp.wasPresenter && payload.becomePresenterUid !== payload.initiatorUid
                ? BECOME_PRESENTER_BOX
                : "",
    };
};

const handleRename = (state, payload) => {
    let ret = { ...state };
    const data = { ...payload };
    if (!data.success) {
        ret = {
            ...ret,
            errorMsg: `Dieser Name ist bereits vergeben: ${data.value}`,
        };
    } else {
        ret = {
            ...ret,
            modalId: "",
            errorMsg: "",
        };
    }
    return ret;
};

const handleDialoutStatus = (state, payload) => {
    let ret = { ...state };
    const data = { ...payload };
    if (!data.success && data.value !== null && data.value !== undefined && data.value.length > 0) {
        let error = "Aus folgenden Gründen kann kein Dialout durchgeführt werden: \n\n";
        for (let i = 0; i < data.value.length; i += 1) {
            error += `${data.value[i]}\n`;
        }
        ret = {
            ...ret,
            errorMsg: error,
            modalId: ERROR_BOX,
        };
    } else {
        ret = {
            ...ret,
            errorMsg: "",
        };
    }
    return ret;
};

const modal = (state = modalInitialState, action) => {
    switch (action.type) {
        case ActionTypes.RECEIVED_WEBSOCKET_DATA:
            if (action.payload.cmd === "becomePresenter") {
                return handleBecomePresenter(state, action.payload);
            }
            if (action.payload.cmd === "presenterRevoked") {
                return {
                    ...state,
                    modalId: !state.wasPresenter ? PRESENTER_REVOKED_BOX : "",
                };
            }
            if (action.payload.cmd === "login") {
                return handleLogin(state, action.payload);
            }
            if (action.payload.cmd === "dialoutStatus") {
                return handleDialoutStatus(state, action.payload);
            }
            if (action.payload.cmd === "rename") {
                return handleRename(state, action.payload);
            }
            return state;
        case "START_LOGIN":
            return {
                ...state,
                showSidebarContent: action.isOperator,
            };
        case "LOGIN_ERROR":
            return {
                ...state,
                errorMsg: action.errorMsg,
            };
        case "SET_SIDEBAR_CONTENT_VISIBILITY":
            return {
                ...state,
                showSidebarContent: action.showSidebarContent,
            };
        case "SET_WEB_PARTICIPANT_VISIBILITY":
            return {
                ...state,
                showWebParticipants: action.showWebParticipants,
            };
        case "SET_BRIDGE_PARTICIPANT_VISIBILITY":
            return {
                ...state,
                showAudioParticipants: action.showAudioParticipants,
            };
        case "SET_MATCHED_PARTICIPANT_VISIBILITY":
            return {
                ...state,
                showMatchedParticipants: action.showMatchedParticipants,
            };
        case "SET_MODAL_VISIBILITY":
            return {
                ...state,
                modalId: action.modalId,
                errorMsg: action.modalId !== "" ? state.errorMsg : "",
            };
        case "SET_FULLSCREEN_MODE":
            return {
                ...state,
                showFullscreenMode: action.showFullscreenMode,
            };
        case "SET_DID_REVOKE_PRESENTER":
            return {
                ...state,
                didRevokePresenter: action.didRevokePresenter,
            };
        case "SET_WAS_PRESENTER":
            return {
                ...state,
                wasPresenter: action.wasPresenter,
            };
        case "SET_ELECTRON_INDEX_TO_CAPTURE":
            return {
                ...state,
                electronIndexToCapture: action.electronIndexToCapture,
            };
        case "SET_CONNECTION_QUALITY":
            return {
                ...state,
                connectionQuality: action.connectionQuality,
            };
        case "SET_SOURCE_MODAL_ID":
            return {
                ...state,
                sourceModalId: action.sourceModalId,
            };
        case "SET_ERROR_IMAGE_CLS":
            return {
                ...state,
                errorImgCls: action.errorImgCls,
            };
        case "SET_ERROR_TITLE":
            return {
                ...state,
                errorTitle: action.errorTitle,
            };
        case "SET_ERROR_IS_INFO":
            return {
                ...state,
                isInfo: action.isInfo,
            };
        case "SET_ERROR_MESSAGE":
            return {
                ...state,
                errorMsg: action.errorMsg,
            };
        case "SET_SIDEBAR_CONTENT_ID":
            return {
                ...state,
                sidebarContentId: action.sidebarContentId,
            };
        case "INCREMENT_TOUR_INDEX":
            if (state.tourIndex < MAX_TOUR_INDEX)
                return {
                    ...state,
                    tourIndex: state.tourIndex + 1,
                };
            return state;
        case "DECREMENT_TOUR_INDEX":
            if (state.tourIndex > 0)
                return {
                    ...state,
                    tourIndex: state.tourIndex - 1,
                };
            return state;
        case "SET_TOUR_INDEX":
            if (action.tourIndex > -1 && action.tourIndex <= MAX_TOUR_INDEX)
                return {
                    ...state,
                    tourIndex: action.tourIndex,
                };
            return state;
        case "SET_ADDITIONAL_ERROR_ACCEPT_ACTION":
            return {
                ...state,
                errorAction: action.actn,
            };
        case "SET_SELECTED_BUTTON":
            return {
                ...state,
                selectedButton: action.selectedButton,
            };
        case "SET_MODAL_CONTENT":
            return {
                ...state,
                modalContent: action.content,
            };
        case "SHOW_WELCOME_TOUR":
            return {
                ...state,
                showTour: action.show,
            };
        case "SET_FEEDBACK_SENT":
            return {
                ...state,
                feedbackSent: action.feedbackSent,
            };
        case "SET_WELCOME_TOUR_FROM_LOGIN":
            return {
                ...state,
                welcomeTourFromLogin: action.fromLogin,
            };
        case "SET_FEATURE_TEXT_INDEX":
            return {
                ...state,
                featureTextIndex: action.featureTextIndex,
            };
        case "SET_SHOW_COUNTRIES":
            return {
                ...state,
                showCountries: action.showCountries,
            };
        default:
            return state;
    }
};

export default modal;
