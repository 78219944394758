import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "../../../styles/layout.css";

import { translate } from "../../../lib/Translator";
import { canVoip } from "../../../lib/utils";
import { PHONE_DIALIN_BOX, PHONE_DIALOUT_BOX, CONNECT_VIA_PC, SHOW_SPINNER } from "../../../constants/modalConstants";
import { writeToSocket, setSelectedButton, setModalContent } from "../../../actions";

import RoundImageButton from "./RoundImageButton";
import IconDialIn from "../../../inlineSvgs/IconDialIn";
import IconDialOut from "../../../inlineSvgs/IconDialOut";
import IconHeadset from "../../../inlineSvgs/IconHeadset";

const ConnectionBarComponent = ({
    showDialout,
    showVoip,
    dialinText,
    dialoutText,
    voipText,
    phoneDialinFn,
    phoneDialoutFn,
    voipFn,
}) => (
    <div className="l-tab-row">
        <div className="l-tab__item-grow l-center-x">
            <RoundImageButton
                key="welcome-box-dialin"
                buttonText={dialinText}
                buttonImageComp={<IconDialIn />}
                buttonType={PHONE_DIALIN_BOX}
                buttonClick={phoneDialinFn}
            />
        </div>
        {showDialout ? (
            <div className="l-tab__item-grow l-center-x">
                <RoundImageButton
                    key="welcome-box-dialout"
                    buttonText={dialoutText}
                    buttonImageComp={<IconDialOut />}
                    buttonType={PHONE_DIALOUT_BOX}
                    buttonClick={phoneDialoutFn}
                />
            </div>
        ) : null}
        {showVoip ? (
            <div className="l-tab__item-grow l-center-x">
                <RoundImageButton
                    key="welcome-box-headset"
                    buttonText={voipText}
                    buttonImageComp={<IconHeadset />}
                    buttonType={CONNECT_VIA_PC}
                    buttonClick={voipFn}
                />
            </div>
        ) : null}
    </div>
);

ConnectionBarComponent.propTypes = {
    showDialout: PropTypes.bool.isRequired,
    showVoip: PropTypes.bool.isRequired,
    dialinText: PropTypes.string.isRequired,
    dialoutText: PropTypes.string.isRequired,
    voipText: PropTypes.string.isRequired,
    phoneDialinFn: PropTypes.func.isRequired,
    phoneDialoutFn: PropTypes.func.isRequired,
    voipFn: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    showDialout: state.modal.dialOutAllowed && !state.login.isOperator && state.settings.dialOutGuestAllowed,
    showVoip: canVoip(state.telco.product),
    dialinText: translate("PhoneDialin.Title").toUpperCase(),
    dialoutText: translate("PhoneDialout.Title").toUpperCase(),
    voipText: translate("VoipConnect.Title").toUpperCase(),
});

const mapDispatchToProps = (dispatch) => ({
    phoneDialinFn: () => {
        dispatch(setSelectedButton(PHONE_DIALIN_BOX));
        dispatch(setModalContent(PHONE_DIALIN_BOX));
    },
    phoneDialoutFn: () => {
        dispatch(setSelectedButton(PHONE_DIALOUT_BOX));
        dispatch(setModalContent(PHONE_DIALOUT_BOX));
    },
    voipFn: () => {
        dispatch(
            writeToSocket({
                cmd: "sipCredentials",
            })
        );
        dispatch(setSelectedButton(CONNECT_VIA_PC));
        dispatch(setModalContent(SHOW_SPINNER));
    },
});

const ConnectionBar = connect(mapStateToProps, mapDispatchToProps)(ConnectionBarComponent);

export default ConnectionBar;
