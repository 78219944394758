import React from "react";
import { connect } from "react-redux";

import { INVITATION_SCREEN } from "../../constants/modalConstants";
import { setModalVisibility } from "../../actions";
import { translate } from "../../lib/Translator";

import MultiModalComponent from "../../components/modal/MultiModal";

import InvitationBar from "../basics/buttons/InvitationBar";
import InvitationLink from "./InvitationLink";
import InvitationMail from "./InvitationMail";
import InvitationDialout from "./InvitationDialout";

const mapStateToProps = (state) => ({
    show: state.modal.modalId === INVITATION_SCREEN,
    title: translate("Invitation.Title"),
    categories: <InvitationBar />,
    content: [<InvitationLink key="link" />, <InvitationMail key="mail" />, <InvitationDialout key="dialout" />],
});

const mapDispatchToProps = {
    onClose: () => setModalVisibility(""),
};

const Invitations = connect(mapStateToProps, mapDispatchToProps)(MultiModalComponent);

export default Invitations;
