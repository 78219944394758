import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { translate, link } from "../lib/Translator";
import { getBrandingType, toTitleCase } from "../lib/utils";
import "./footer.css";

const FooterComponent = ({
    show,
    helpLink,
    helpText,
    imprintLink,
    imprintText,
    termsLink,
    termsText,
    privacyLink,
    privacyText,
    productLink,
    productText,
}) =>
    show ? (
        <div className="footer-block">
            <div className="footer-info">
                <a className="footer-info__link" href={helpLink} target="_blank" rel="noopener noreferrer">
                    {helpText}
                </a>
                <a className="footer-info__link" href={imprintLink} target="_blank" rel="noopener noreferrer">
                    {imprintText}
                </a>
                <a className="footer-info__link" href={termsLink} target="_blank" rel="noopener noreferrer">
                    {termsText}
                </a>
                <a className="footer-info__link" href={privacyLink} target="_blank" rel="noopener noreferrer">
                    {privacyText}
                </a>
                <a className="footer-info__link" href={productLink} target="_blank" rel="noopener noreferrer">
                    {productText}
                </a>
            </div>
        </div>
    ) : null;

FooterComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    helpLink: PropTypes.string.isRequired,
    helpText: PropTypes.string.isRequired,
    imprintLink: PropTypes.string.isRequired,
    imprintText: PropTypes.string.isRequired,
    termsLink: PropTypes.string.isRequired,
    termsText: PropTypes.string.isRequired,
    privacyLink: PropTypes.string.isRequired,
    privacyText: PropTypes.string.isRequired,
    productLink: PropTypes.string.isRequired,
    productText: PropTypes.string.isRequired,
};

const product = toTitleCase(getBrandingType());

const mapStateToProps = (state) => ({
    show: !state.login.login,
    helpLink: link("Help." + product),
    helpText: translate("Link.Help"),
    imprintLink: link("Imprint." + product),
    imprintText: translate("Link.Imprint"),
    termsLink: link("Terms." + product),
    termsText: translate("Link.Terms"),
    privacyLink: link("Privacy." + product),
    privacyText: translate("Link.Privacy"),
    productLink: link("Product." + product),
    productText: translate("Link." + product),
});

export default connect(mapStateToProps)(FooterComponent);
