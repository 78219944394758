import { ActionTypes } from "redux-mw-ws";
import DialoutHandler from "../lib/DialoutHandler";
import { addDialout } from "../actions";

export default function createTelcoMiddleware() {
    return (store) => {
        const dialoutHandler = new DialoutHandler(store);
        return (next) => async (action) => {
            if (action.type === "SET_START_DIALOUT" && action.startDialout) {
                dialoutHandler.startDialout();
            }
            if (action.type === ActionTypes.RECEIVED_WEBSOCKET_DATA) {
                if (
                    action.payload.cmd === "login" &&
                    action.payload.success === true &&
                    action.payload.dialOutAllowed &&
                    action.payload.countryNumbers.length > 0 &&
                    !store.getState().login.reconnecting
                ) {
                    store.dispatch(addDialout(action.payload.countryNumbers));
                }
                if (action.payload.cmd === "participants") {
                    dialoutHandler.setParticipantsDialoutStatus(action.payload.value);
                }
            }
            next(action);
        };
    };
}
