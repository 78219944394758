import { ActionTypes } from "redux-mw-ws";

import { canVoip } from "../lib/utils";
import Voip from "../lib/Voip";

export default function createVoipMiddleware() {
    return (store) => {
        const voip = new Voip(store);
        return (next) => (action) => {
            if (canVoip(store.getState().telco.product)) {
                if (action.type === "VOIP_HANGUP") {
                    voip.hangup();
                }
                if (action.type === "VOIP_TOGGLE_MUTE") {
                    voip.toggleMute();
                }
                if (action.type === ActionTypes.RECEIVED_WEBSOCKET_DATA) {
                    if (action.payload.cmd === "sipCredentials") {
                        voip.connect(action.payload.value);
                    }
                }
            }
            next(action);
        };
    };
}
