import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import HeaderBridge from "./HeaderBridge";
import ModeratorBridge from "../dropdown/ModeratorBridge";
import ParticipantBridge from "../dropdown/ParticipantBridge";

const ParticipantListBridgeComponent = ({ isOperator, showAudioParticipants, participants }) => (
    <div>
        <HeaderBridge />
        {/* <SlideDown transitionOnAppear closed={false}> */}
        {showAudioParticipants
            ? participants.map((p, i) => {
                  const containerRef = (
                      <div key={p.id}>
                          {isOperator ? <ModeratorBridge p={p} /> : <ParticipantBridge p={p} />}
                          {i < participants.length - 1 ? <div className="sidebar-entry-container-divider" /> : null}
                      </div>
                  );
                  return containerRef;
              })
            : null}
        {/* </SlideDown> */}
    </div>
);
ParticipantListBridgeComponent.propTypes = {
    showAudioParticipants: PropTypes.bool.isRequired,
    participants: PropTypes.array.isRequired,
    isOperator: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    showAudioParticipants: state.modal.showAudioParticipants,
    participants: state.participants.audio,
    isOperator: state.login.isOperator,
});

const ParticipantListBridge = connect(mapStateToProps)(ParticipantListBridgeComponent);

export default ParticipantListBridge;
