export const webrtcInitialState = {
    captureStarted: false,
    localStream: "stop",
    remoteStream: "stop",
    turn: null,
    captureType: null,
    electronSources: [],
};

const webRtc = (state = webrtcInitialState, action) => {
    let a = {};
    switch (action.type) {
        case "STOP_CAPTURE":
            a = { ...action };
            delete a.type;
            return {
                ...state,
                ...a,
            };
        case "SET_CAPTURE_STATE":
            a = { ...action };
            delete a.type;
            return {
                ...state,
                ...a,
            };
        case "SET_STREAM_STATE":
            a = { ...action };
            delete a.type;
            return {
                ...state,
                ...a,
            };
        case "SET_CAPTURE_TYPE":
            a = { ...action };
            delete a.type;
            return {
                ...state,
                ...a,
            };
        case "SET_ELECTRON_SOURCES":
            a = { ...action };
            delete a.type;
            return {
                ...state,
                electronSources: a.electronSources,
            };
        case "SET_TURN_SERVER":
            a = { ...action };
            return {
                ...state,
                turn: a.turnServer,
            };
        default:
            return state;
    }
};

export default webRtc;
