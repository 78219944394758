import React from "react";
import PropTypes from "prop-types";

const StartSharingButton = ({ greyOut, onClick, title, sessionId, type }) => (
    <div
        className={`action ${greyOut ? "disabled" : ""}`}
        onClick={() => {
            onClick(sessionId, type);
        }}
        role="button"
        tabIndex="0"
    >
        <div className={`button-share ${greyOut ? "action-button-disabled" : "action-button"} `}>
            <p>{title}</p>
        </div>
    </div>
);

StartSharingButton.defaultProps = {
    type: "window",
};

StartSharingButton.propTypes = {
    greyOut: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    sessionId: PropTypes.string.isRequired,
    type: PropTypes.string,
};

export default StartSharingButton;
