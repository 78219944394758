/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import PropTypes from "prop-types";

import "../../styles/layout.css";
import "../../styles/common.css";
import styles from "./multiModal.module.css";

const MultiModalComponent = ({ show, title, categories, content, onClose }) =>
    show ? (
        <div>
            <div className="l-layer-1">
                <div className="c-darken" />
            </div>
            <div className="l-layer-2 l-center-xy">
                <div className={styles["c-multi-modal"]}>
                    <div className={styles["c-multi-modal__header"]}>
                        <div className="l-tab-row">
                            <div className="l-tab__item-grow">
                                <div className={styles["c-title-text"]}>{title}</div>
                            </div>
                            <div className="c-window-action-cross" onClick={onClose} role="button" tabIndex="0" />
                        </div>
                    </div>
                    <div className={styles["c-multi-modal__categories"]}>{categories}</div>
                    <div className={styles["c-multi-modal__content"]}>{content}</div>
                </div>
            </div>
        </div>
    ) : null;

MultiModalComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    categories: PropTypes.object.isRequired,
    content: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default MultiModalComponent;
