import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import StopSharingDefault from "./StopSharingDefault";
import StartSharingDefault from "./StartSharingDefault";
import { isElectron } from "../../lib/utils";
import { translate } from "../../lib/Translator";

const ActionButtonsComponent = ({
    isPresenter,
    captureStarted,
    startCaptureText,
    stopCaptureText,
    shareDesktopText,
    shareScreenText,
}) => {
    const isFFOrElectron = window.navigator.userAgent.indexOf("Firefox/") > -1 || isElectron();
    return (
        <div className="actionbuttons">
            {isPresenter && captureStarted ? <StopSharingDefault title={stopCaptureText} /> : null}
            {isPresenter && !captureStarted && isFFOrElectron ? (
                <div>
                    <StartSharingDefault title={shareDesktopText} type="screen" greyOut={false} />
                    <StartSharingDefault title={shareScreenText} type="window" greyOut={false} />
                </div>
            ) : null}
            {isPresenter && !captureStarted && !isFFOrElectron ? (
                <StartSharingDefault title={startCaptureText} greyOut={false} />
            ) : null}
        </div>
    );
};

ActionButtonsComponent.propTypes = {
    isPresenter: PropTypes.bool.isRequired,
    captureStarted: PropTypes.bool.isRequired,
    startCaptureText: PropTypes.string.isRequired,
    stopCaptureText: PropTypes.string.isRequired,
    shareDesktopText: PropTypes.string.isRequired,
    shareScreenText: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    isPresenter: state.login.isPresenter,
    captureStarted: state.webRtc.captureStarted,
    startCaptureText: translate("Presentation.StartCapture"),
    stopCaptureText: translate("Presentation.StopCapture"),
    shareDesktopText: translate("Presentation.ShareDesktop"),
    shareScreenText: translate("Presentation.ShareScreen"),
});

const ActionButtons = connect(mapStateToProps)(ActionButtonsComponent);

export default ActionButtons;
