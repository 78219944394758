import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { translate } from "../../lib/Translator";

import ErrorBoundary from "../errorHandling/ErrorBoundary";

import BadConnectionIndicator from "./BadConnectionIndicator";
import RemoteVideoView from "./RemoteVideoView";
import LocalVideoView from "./LocalVideoView";
import ActionButtons from "./ActionButtons";
import VoipControls from "./VoipControls";
import PresentationWelcomeScreen from "./PresentationWelcomeScreen";
import RecordingInformation from "./RecordingInformation";
import HelpButton from "./HelpButton";

import "../../styles/presentation.css";
import "../../styles/format.css";
import "../../styles/main.css";

const PresentationComponent = ({
    isPresenter,
    captureStarted,
    remoteStream,
    sharingStartedText,
    allowScreenshare,
    canScreenshare,
}) => (
    <ErrorBoundary>
        <RemoteVideoView />
        <VoipControls />
        <div className="welcomebox">
            {remoteStream !== "ready" && isPresenter && captureStarted && allowScreenshare && canScreenshare ? (
                <div>
                    <div>
                        <h1>{sharingStartedText}</h1>
                        <LocalVideoView />
                        <BadConnectionIndicator />
                        <ActionButtons />
                    </div>
                </div>
            ) : null}
            <PresentationWelcomeScreen />
        </div>
        <RecordingInformation />
        {/* <HelpButton /> */}
    </ErrorBoundary>
);

PresentationComponent.propTypes = {
    isPresenter: PropTypes.bool.isRequired,
    captureStarted: PropTypes.bool.isRequired,
    remoteStream: PropTypes.string.isRequired,
    sharingStartedText: PropTypes.string.isRequired,
    allowScreenshare: PropTypes.bool.isRequired,
    canScreenshare: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    isPresenter: state.login.isPresenter,
    captureStarted: state.webRtc.captureStarted,
    remoteStream: state.webRtc.remoteStream,
    sharingStartedText: translate("Presentation.SharingStarted"),
    allowScreenshare: state.login.allowScreenshare,
    canScreenshare: state.modal.canScreenshare,
});

const Presentation = connect(mapStateToProps)(PresentationComponent);

export default Presentation;
