import React from "react";
import { connect } from "react-redux";

import { PRESENTATION_OVER_BOX } from "../../constants/modalConstants";
import { setModalVisibility } from "../../actions";
import { translate } from "../../lib/Translator";
import Modal from "../../components/modal/Modal";
import SimpleAcceptButton from "../basics/buttons/SimpleAcceptButton";

const onClose = () => setModalVisibility("");

const getPresentationOverMessage = (presentationIsOverReason) => {
    if (presentationIsOverReason === "presStoppedByMod") return translate("PresentationOver.Message");
    if (presentationIsOverReason === "wasKicked") return translate("PresentationOver.KickMessage");
    if (presentationIsOverReason === "overTime") return translate("PresentationOver.OverTimeMessage");
    return "";
};

const mapStateToProps = (state) => ({
    show: state.modal.modalId === PRESENTATION_OVER_BOX && !state.login.isOperator,
    title: translate("PresentationOver.Title"),
    contentChildren: <p>{getPresentationOverMessage(state.login.presentationIsOverReason)}</p>,
    buttonChildren: <SimpleAcceptButton acceptText="OK" modalId={PRESENTATION_OVER_BOX} />,
});

const mapDispatchToProps = {
    onClose,
};

const PresentationOver = connect(mapStateToProps, mapDispatchToProps)(Modal);

export default PresentationOver;
