export const MYWEB_BACKEND = process.env.REACT_APP_WS_HOST || "manama:8443";
export const FEEDBACK_HOST = process.env.REACT_APP_BRANDING_HOST || "manama:8181";
export const MATCH_SEQUENCE_START = "123";
export const MAX_CHAT_MESSAGE_LENGTH = 1000;

export const MAX_CHAT_MESSAGE_LENGTH_HINT_BORDER = 50;

export const LOGIN_ERROR_PIN = "pin";
export const LOGIN_ERROR_CREATE = "create";
export const LOGIN_ERROR_NAME = "name";
export const LOGIN_ERROR_HOST = "host";
export const LOGIN_ERROR_FULL = "full";
export const LOGIN_ERROR_LOCK = "lock";
export const LOGIN_ERROR_HOSTNAME = "hostname";

// Everytime a new error could occur add it also to this array
export const LOGIN_ERROR_TYPES = [
    LOGIN_ERROR_PIN,
    LOGIN_ERROR_CREATE,
    LOGIN_ERROR_NAME,
    LOGIN_ERROR_HOST,
    LOGIN_ERROR_FULL,
    LOGIN_ERROR_LOCK,
    LOGIN_ERROR_HOSTNAME,
];

export const dialoutStates = {
    INITIAL: "initial",
    IN_PROGRESS: "inProgress",
    SUCCESS: "success",
    FAIL: "fail",
};
