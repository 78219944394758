import { ActionTypes } from "redux-mw-ws";

export const settingsInitialState = {
    participantListEnabled: false,
    participantListEnabledTmp: false,
    dialOutGuestAllowed: false,
    dialOutGuestAllowedTmp: false,
};

const setSettings = (state, action) => ({
    ...state,
    participantListEnabled: action.payload.participantListEnabled,
    participantListEnabledTmp: action.payload.participantListEnabled,
    dialOutGuestAllowed: action.payload.dialOutGuestAllowed,
    dialOutGuestAllowedTmp: action.payload.dialOutGuestAllowed,
});

const resetSettings = (state) => ({
    ...state,
    participantListEnabled: state.participantListEnabledTmp,
    dialOutGuestAllowed: state.dialOutGuestAllowedTmp,
});

const settings = (state = settingsInitialState, action) => {
    switch (action.type) {
        case ActionTypes.RECEIVED_WEBSOCKET_DATA:
            if (action.payload.cmd === "login" && action.payload.success === true) {
                return setSettings(state, action);
            }
            if (action.payload.cmd === "updateSettings") {
                return setSettings(state, action);
            }
            return state;
        case "RESET_SETTINGS":
            return resetSettings(state);
        default:
            return state;
    }
};

export default settings;
