import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Clipboard from "clipboard";

import { INVITATION_LINK } from "../../constants/modalConstants";

import { translate } from "../../lib/Translator";

import "../../styles/layout.css";
import "../basics/buttons/button.css";
import styles from "./invitationLink.module.css";

const InvitationLinkComponent = ({ show, labelText, btnText, copiedText, invitationLink }) => {
    new Clipboard(".clipboard"); // eslint-disable-line no-new
    return show ? (
        <div className="l-tab-col">
            <div className={styles["c-label"]}>{labelText}</div>
            <input className="input-field input-field--text" type="text" defaultValue={invitationLink} />
            <div className={styles["c-button"]}>
                <div
                    className="c-btn c-btn--max c-btn--branded clipboard"
                    data-clipboard-text={invitationLink}
                    onClick={(e) => {
                        const target = e.currentTarget;
                        target.innerHTML = copiedText;
                        setTimeout(() => {
                            target.innerHTML = btnText;
                        }, 2000);
                    }}
                    role="button"
                    tabIndex="0"
                >
                    {btnText}
                </div>
            </div>
        </div>
    ) : null;
};

InvitationLinkComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    labelText: PropTypes.string.isRequired,
    btnText: PropTypes.string.isRequired,
    invitationLink: PropTypes.string.isRequired,
    copiedText: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    show: state.modal.modalContent === INVITATION_LINK,
    invitationLink: "https://" + window.location.host + window.location.pathname + "#guest/" + state.login.guestPin,
    labelText: translate("Invite.Link"),
    btnText: translate("Invite.CopyLink"),
    copiedText: translate("Invite.Copied"),
});

const InvitationLink = connect(mapStateToProps)(InvitationLinkComponent);

export default InvitationLink;
