/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import PropTypes from "prop-types";

import "../../styles/main.css";
import "../../styles/modal.css";
import "../../styles/forms.css";

const Modal = ({ show, bigBox, title, errorMsg, contentChildren, buttonChildren, onClose }) => {
    if (!show) return null;
    return (
        <div className="modal-container">
            <div className={`modal-box ${bigBox ? "modal-box--big" : "modal-box--small"}`}>
                <span className="icon-close" onClick={onClose} role="button" tabIndex="0" />
                {title ? <div className="modal-top">{title}</div> : null}
                <div className={`modal-bottom ${bigBox ? "modal-bottom--big" : "modal-bottom--small"}`}>
                    <div className={"messagebox error" + (errorMsg ? "" : " hidden")}>{errorMsg}</div>
                    {contentChildren ? (
                        <div className={`inputblock ${bigBox ? "inputblock--big" : null}`}>{contentChildren}</div>
                    ) : null}
                    {buttonChildren || null}
                </div>
            </div>
        </div>
    );
};

Modal.defaultProps = {
    bigBox: true,
    errorMsg: "",
    title: null,
    contentChildren: null,
    buttonChildren: null,
};

Modal.propTypes = {
    show: PropTypes.bool.isRequired,
    bigBox: PropTypes.bool,
    title: PropTypes.string,
    errorMsg: PropTypes.string,
    contentChildren: PropTypes.node,
    buttonChildren: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default Modal;
