import React from "react";
import { connect } from "react-redux";

import { BECOME_PRESENTER_BOX } from "../../constants/modalConstants";
import { setModalVisibility } from "../../actions";
import { translate } from "../../lib/Translator";
import { modalText } from "../../lib/utils";
import Modal from "../../components/modal/Modal";
import SimpleAcceptButton from "../basics/buttons/SimpleAcceptButton";

const onClose = () => setModalVisibility("");

const mapStateToProps = (state) => ({
    show:
        state.modal.modalId === BECOME_PRESENTER_BOX &&
        !state.modal.didRevokePresenter &&
        state.login.uid !== state.login.makePresenterInitiatorId,
    title: translate("BecomePresenter.Title"),
    contentChildren:
        state.modal.becomePresenterReason === "leaving" ? (
            <span>
                {modalText("BecomePresenter.LeavingMessageI", state.modal.formerPresenter)}
                <div>{translate("BecomePresenter.LeavingMessageII")}</div>
            </span>
        ) : (
            <span>{modalText("BecomePresenter.Message", state.modal.formerPresenter)}</span>
        ),
    buttonChildren: <SimpleAcceptButton acceptText="OK" modalId={BECOME_PRESENTER_BOX} />,
});

const mapDispatchToProps = {
    onClose,
};

const BecomePresenter = connect(mapStateToProps, mapDispatchToProps)(Modal);

export default BecomePresenter;
