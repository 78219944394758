import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import "./styles/format.css";
import "./styles/modal.css";
import "./styles/fullscreen.css";
import "./styles/presentation.css";

import "./App.css";

import { applyBrandingStyles, getBrandingType, checkBrowser, makeLoginJson, isIE11 } from "./lib/utils";
import { DOWNLOAD_BOX, LOGIN_BOX } from "./constants/modalConstants";

import "./constants/stylesConstants.css";

import Start from "./containers/Start";
import Header from "./containers/Header";
import Footer from "./containers/Footer";
import Sidebar from "./containers/sidebar/Sidebar";

import {
    setFullscreenMode,
    setSidebarContentVisibility,
    setModalVisibility,
    startLogin,
    setErrorMessage,
    writeToSocket,
    setPIN,
} from "./actions";

class App extends Component {
    constructor(props) {
        super(props);

        this.browserOK = false;
        this._startLogin = this._startLogin.bind(this);
        this._dlApp = this._dlApp.bind(this);
        this._appStarted = this._appStarted.bind(this);
    }

    componentDidMount() {
        this._setNotificationBoxVisibility();
        this._setDocumentTitle();
        this._requestBranding();

        // Eventlistener for fullscreen
        if (window.navigator.userAgent.indexOf("Chrome/") > -1) {
            document.addEventListener("webkitfullscreenchange", () => {
                this._setFullscreen(document.webkitFullscreenElement !== null);
            });
        }
        if (window.navigator.userAgent.indexOf("Firefox/") > -1) {
            document.addEventListener("mozfullscreenchange", () => {
                this._setFullscreen(document.mozFullScreenElement !== null);
            });
        }
        if (isIE11()) {
            document.addEventListener("MSFullscreenChange", () => {
                this._setFullscreen(document.msFullscreenElement !== null);
            });
        }
        const b = checkBrowser();
        this.browserOK = false;
        if (window && window.process && window.process.type) {
            this.browserOK = true; // electron
        } else if (b.isChrome || b.isFirefox) {
            if (
                window.RTCPeerConnection !== undefined &&
                navigator.mediaDevices !== undefined &&
                navigator.mediaDevices.getUserMedia !== undefined
            ) {
                if (b.isChrome && window.visualViewport !== undefined) {
                    // chrome 61 or newer
                    this.browserOK = true;
                } else if (b.isFirefox) {
                    const evt = new Event("x");
                    if (evt.cancelBubble !== undefined) this.browserOK = true; // firefox 52 or newer
                }
            }
        } else if (b.isEdge && navigator.getDisplayMedia !== undefined && window.RTCPeerConnection !== undefined) {
            this.browserOK = true;
        } else if (b.isSafari) {
            this.browserOK = true;
        } else if (b.isIE && b.ieVersion === 11) {
            this.browserOK = true;
        }

        this._checkAutoLogin(this.browserOK);

        if (isIE11()) {
            const main = document.getElementsByClassName("main-background")[0];
            if (main) {
                main.style.cssText =
                    "background-repeat: no-repeat; background-size: cover; background-position: center;";
            }
        }
    }

    componentWillUnmount() {
        if (window.navigator.userAgent.indexOf("Chrome/") > -1) {
            document.removeEventListener("webkitfullscreenchange", () => {
                this._setFullscreen(document.webkitFullscreenElement !== null);
            });
        }

        if (window.navigator.userAgent.indexOf("Firefox/") > -1) {
            document.removeEventListener("mozfullscreenchange", () => {
                this._setFullscreen(document.mozFullScreenElement !== null);
            });
        }
        if (isIE11()) {
            document.addEventListener("MSFullscreenChange", () => {
                this._setFullscreen(document.msFullscreenElement !== null);
            });
        }
    }

    _startLogin(isOp) {
        const { dispatch } = this.props;
        if (this.browserOK) {
            dispatch(startLogin(isOp));
            dispatch(setModalVisibility(LOGIN_BOX));
        } else {
            this._startApp();
        }
    }

    _dlApp() {
        const { dispatch } = this.props;
        dispatch(setErrorMessage("Error.DownloadInstaller"));
        dispatch(setModalVisibility(DOWNLOAD_BOX));
    }

    _appStarted() {
        const { dispatch } = this.props;
        dispatch(setErrorMessage("Error.UseApp"));
        dispatch(setModalVisibility(DOWNLOAD_BOX));
    }

    _startApp() {
        const b = checkBrowser();
        let uri = "myweb:///" + getBrandingType() + "/";
        const that = this;
        // TODO use state.pin?
        if (window.location.hash.length > 2) uri += window.location.hash.substr(1);
        if (navigator.msLaunchUri) {
            navigator.msLaunchUri(uri, this._appStarted, this._dlApp);
            return;
        }
        if (b.isFirefox) {
            const iframe = document.querySelector("#dummyiframe");

            try {
                iframe.contentWindow.location.href = uri;
                that._appStarted();
            } catch (e) {
                if (e.name === "NS_ERROR_UNKNOWN_PROTOCOL") {
                    that._dlApp();
                }
            }
        } else if (b.isChrome) {
            let timeout = null;
            const onBlur = () => {
                clearTimeout(timeout);
                window.removeEventListener("blur", onBlur);
                that._appStarted();
            };
            timeout = setTimeout(() => {
                that._dlApp();
                window.removeEventListener("blur", onBlur);
            }, 1000);
            window.addEventListener("blur", onBlur);

            window.location = uri;
        } else if (b.isIE) {
            if (parseInt(b.ieVersion, 10) === 10) {
                let timeout = null;
                const onBlur = () => {
                    clearTimeout(timeout);
                    that._appStarted();
                };
                timeout = setTimeout(() => {
                    window.removeEventListener("blur", onBlur);
                    that._dlApp();
                }, 1000);
                window.addEventListener("blur", onBlur);

                const iframe = document.querySelector("#dummyiframe");

                try {
                    iframe.contentWindow.location.href = uri;
                } catch (e) {
                    that._dlApp();
                    clearTimeout(timeout);
                }
            } else if (b.ieVersion === 9 || b.ieVersion === 11) {
                let timeout = null;
                const onBlur = function onBlur() {
                    clearTimeout(timeout);
                    window.removeEventListener("blur", onBlur);
                    that._appStarted();
                };
                timeout = setTimeout(() => {
                    that._dlApp();
                    window.removeEventListener("blur", onBlur);
                }, 1000);

                const iframe = document.querySelector("#dummyiframe");

                window.addEventListener(window, "blur", onBlur);

                iframe.contentWindow.location.href = uri;
            }
        } else {
            const myWindow = window.open("", "", "width=0,height=0");

            myWindow.document.write(`<iframe src='${uri}'></iframe>`);

            setTimeout(() => {
                try {
                    myWindow.location.href = uri;
                    myWindow.setTimeout("window.close()", 1000);
                    that._appStarted();
                } catch (e) {
                    myWindow.close();
                    that._dlApp();
                }
            }, 1000);
        }
    }

    _checkAutoLogin = () => {
        const { dispatch } = this.props;
        // workaround for serviceworker and redirect
        if (window.location.hostname.toLocaleLowerCase() === "start.meetgreen.de") {
            window.location.href = "https://webmeeting.online/meetgreen";
        }

        // check for autologin
        let autoLogin = false;
        let { hash } = window.location;
        if (hash === undefined || hash === null) return;
        if (hash.substr(0, 1) === "#") hash = hash.substring(1);
        if (hash.substr(0, 1) === "-") {
            hash = hash.substring(1);
            if (this.browserOK) autoLogin = true;
        }
        if (hash === "") return;
        if (hash.substr(0, 5) === "host/") {
            if (this.browserOK) {
                console.log(`autologin with pin ${hash.substr(5)}`);
                dispatch(setPIN(hash.substr(5)));
                dispatch(writeToSocket(makeLoginJson("start", hash.substr(5), "", "not-registered")));
                dispatch(startLogin(true));
            }
            // never store host code in url
            window.location.hash = "";
        } else if (hash.substr(0, 6) === "guest/") {
            let code = hash.substr(6);
            let name = "";
            if (code.indexOf("/") > -1) {
                name = code.substr(code.indexOf("/") + 1);
                code = code.substr(0, code.indexOf("/"));
            }
            dispatch(setPIN(code));
            if (this.browserOK) dispatch(setModalVisibility(LOGIN_BOX));
            if (autoLogin) {
                dispatch(writeToSocket(makeLoginJson("join", code, "", "not-registered")));
            }
            // remove name
            if (name !== "") {
                window.location.hash = `#guest/${code}`;
            }
            dispatch(startLogin(false));
        }
    };

    _setNotificationBoxVisibility = () => {
        if ("Notification" in window) {
            if (window.Notification.permission !== undefined && Notification.permission !== "granted") {
                Notification.requestPermission();
            }
        }
    };

    _setDocumentTitle = () => {
        document.title = `${getBrandingType()} Webkonferenz`;
    };

    /**
     * Branding
     *
     */
    _requestBranding = () => {
        applyBrandingStyles(getBrandingType());
    };

    _setFullscreen(enabled) {
        const { dispatch } = this.props;
        dispatch(setFullscreenMode(enabled));
        dispatch(setSidebarContentVisibility(!enabled));

        if (enabled) {
            document.body.classList.add("fullscreen");
        } else {
            document.body.classList.remove("fullscreen");
        }
    }

    render() {
        const { state } = this.props;
        // console.log("App state: %o", this.props.state);
        const style = `main ${
            state.modal.modalId !== "" && !state.login.reconnecting
                ? "bg-blur main-background-blured"
                : "main-background"
        } ${isIE11() ? "main--ie11-fix" : ""}`;
        return (
            <div className={style}>
                <iframe className="hiddeniframe" title="app detection" src="about:blank" id="dummyiframe" />
                <Header />
                <Start startLogin={this._startLogin} />
                <Sidebar />
                <Footer />
            </div>
        );
    }
}

App.propTypes = {
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
};

export default connect((state) => ({ state }))(App);
