import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "./sidebar.css";
import { translate } from "../../lib/Translator";
import { getSidebarViewStyle } from "../../lib/utils";

import SidebarListHeader from "./SidebarListHeader";
import SettingsListContent from "./SettingsListContent";

import { SETTINGS_LIST } from "../../constants/sidebarConstants";

export const SettingsListComponent = ({ showFullscreenMode, sidebarContentId, showSidebarContent, headerTitle }) => {
    const style = getSidebarViewStyle(showFullscreenMode, showSidebarContent);
    return sidebarContentId === SETTINGS_LIST ? (
        <div className={`sidebar-list ${style.sidebarListView} ${style.showSidebar}`}>
            <SidebarListHeader title={headerTitle} />
            <div className="sidebar-list__main">
                <SettingsListContent />
            </div>
        </div>
    ) : null;
};

SettingsListComponent.propTypes = {
    showFullscreenMode: PropTypes.bool.isRequired,
    sidebarContentId: PropTypes.string.isRequired,
    showSidebarContent: PropTypes.bool.isRequired,
    headerTitle: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    showFullscreenMode: state.modal.showFullscreenMode,
    showSidebarContent: state.modal.showSidebarContent,
    sidebarContentId: state.modal.sidebarContentId,
    isOperator: state.login.isOperator,
    headerTitle: translate("Settings.Title"),
});

const SettingsList = connect(mapStateToProps)(SettingsListComponent);

export default SettingsList;
