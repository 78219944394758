import React from "react";
import PropTypes from "prop-types";

import "../../styles/layout.css";
import "../../styles/forms.css";
import "./inputNumberContent.css";

const InputNumberContent = ({ inputLabel, prefix, placeholder, inputOnChange }) => (
    <div className="inputblock__child">
        <div className="label">{inputLabel}</div>
        <div className="l-tab-row">
            {prefix !== null ? <div className="prefix">{prefix}</div> : null}
            <input
                className={`input ${prefix === null ? "input--border-left" : ""}`}
                type="number"
                onChange={inputOnChange}
                placeholder={placeholder}
            />
        </div>
    </div>
);

InputNumberContent.defaultProps = {
    prefix: null,
    placeholder: "",
};

InputNumberContent.propTypes = {
    inputLabel: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    placeholder: PropTypes.string,
    inputOnChange: PropTypes.func.isRequired,
};

export default InputNumberContent;
