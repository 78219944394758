import React, { Component } from "react";

import "../styles/sidebar.css";

class IconSidebarEntryMute extends Component {
    render() {
        return (
            <span className="sidebar-entry-content-symbols__mute-inline">
                <svg
                    version="1.1"
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 16 16"
                    xmlSpace="preserve"
                >
                    <path d="M10,3c0-1.1-0.9-2-2-2S6,1.9,6,3v4.9l4-4V3z" />
                    <path d="M10,8.1L8.1,10C9.1,9.9,9.9,9.1,10,8.1z" />
                    <path
                        d="M7.6,13.5v1.7H6.3c-0.2,0-0.4,0.2-0.4,0.4S6.1,16,6.3,16h3.4c0.2,0,0.4-0.2,0.4-0.4s-0.2-0.4-0.4-0.4H8.4v-1.7
            c2.8-0.3,5.1-2.6,5.1-5.5c0-0.2-0.2-0.4-0.4-0.4S12.6,7.8,12.6,8c0,2.6-2.1,4.6-4.6,4.6c-0.7,0-1.4-0.2-2-0.5l-0.7,0.7
            C6,13.2,6.8,13.4,7.6,13.5z"
                    />
                    <path d="M3.4,8c0-0.2-0.2-0.4-0.4-0.4S2.5,7.8,2.5,8c0,1,0.3,1.9,0.7,2.7L3.9,10C3.6,9.4,3.4,8.7,3.4,8z" />
                    <path d="M7.2,10.9C7.5,11,7.7,11,8,11c1.7,0,3-1.4,3-3V7.1l-1,1" />
                    <path d="M10,3.9l1-1C10.9,1.2,9.7,0,8,0S5,1.3,5,3v5c0,0.3,0,0.5,0.1,0.8L6,7.9" />
                    <path
                        d="M15.1,0.9c-0.1-0.1-0.2-0.1-0.4-0.1s-0.3,0-0.4,0.1L11,4.3l-1,1L6.3,9L5.6,9.7l-1.1,1.1l-0.6,0.6L1,14.3
            c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l2.9-2.9l0.6-0.6l1.1-1.1L7,9.7l3-3l1-1l4.1-4.1
            C15.3,1.4,15.3,1.1,15.1,0.9z"
                    />
                </svg>
            </span>
        );
    }
}

export default IconSidebarEntryMute;
