import React from "react";
import { connect } from "react-redux";
import Participant from "../../components/Participant";
import DropDownMenu from "./DropDownMenu";
import DropDownMenuEntryMute from "./DropDownMenuEntryMute";
import DropDownMenuEntryHold from "./DropDownMenuEntryHold";
import DropDownMenuEntryKick from "./DropDownMenuEntryKick";

const mapStateToProps = (state, ownProps) => ({
    host: false,
    presenting: false,
    onHold: ownProps.p.onHold,
    mute: ownProps.p.mute,
    talking: ownProps.p.talking,
    titleText: ownProps.p.phone,
    presentationActive: false,
    dropdownmenu: (
        <DropDownMenu
            children={[
                <DropDownMenuEntryMute key="muteBridgeparticipant" id={ownProps.p.id} mute={ownProps.p.mute} />,
                <DropDownMenuEntryHold key="holdBridgeparticipant" id={ownProps.p.id} onHold={ownProps.p.onHold} />,
                <DropDownMenuEntryKick key="kickBridgeparticipant" phone={ownProps.p.phone} id={ownProps.p.id} />,
            ]}
            direction="down"
        />
    ),
});

const ModeratorBridge = connect(mapStateToProps)(Participant);

export default ModeratorBridge;
