import { ActionTypes } from "redux-mw-ws";
import Cookies from "universal-cookie";

import { ERROR_BOX, CONNECTIONS_SCREEN, WELCOME_SCREEN } from "../constants/modalConstants";
import { LOGIN_ERROR_TYPES } from "../constants/commonConstants";
import { CHAT_LIST } from "../constants/sidebarConstants";
import {
    setModalVisibility,
    setNumberOfLastReadMessage,
    setNumberOfUnreadMessages,
    setErrorTitle,
    setErrorIsInfo,
    setLoginError,
    setAdditionalErrorAcceptAction,
    writeToSocket,
    showWelcomeTour,
    setWelcomeTourFromLogin,
    setReconnecting,
} from "../actions";
import { translate } from "../lib/Translator";
import NotificationHandler from "../lib/NotificationHandler";

export default function createUiMiddleware() {
    return (store) => {
        const cookies = new Cookies();
        const notificationHandler = new NotificationHandler(store);
        return (next) => async (action) => {
            if (action.type === ActionTypes.RECEIVED_WEBSOCKET_DATA) {
                if (action.payload.cmd === "login" && !store.getState().login.reconnecting) {
                    const showTour =
                        cookies.get(`${action.payload.participantName}:showTour`) === "true" ||
                        cookies.get(`${action.payload.participantName}:showTour`) === undefined;
                    store.dispatch(setReconnecting(false));
                    store.dispatch(showWelcomeTour(showTour, action.payload.participantName));
                    if (!LOGIN_ERROR_TYPES.includes(action.payload.error)) {
                        if (showTour) {
                            store.dispatch(setWelcomeTourFromLogin(true));
                            store.dispatch(setModalVisibility(WELCOME_SCREEN));
                        } else {
                            store.dispatch(setModalVisibility(CONNECTIONS_SCREEN));
                        }
                    }
                }
                if (action.payload.cmd === "participants") {
                    notificationHandler.triggerNotification(action.payload.value);
                }
                if (action.payload.cmd === "participantMatched") {
                    if (store.getState().modal.modalId === CONNECTIONS_SCREEN) {
                        store.dispatch(setModalVisibility(""));
                    }
                }
                if (action.payload.cmd === "sendChatMessage") {
                    if (
                        // chat active
                        store.getState().modal.sidebarContentId === CHAT_LIST &&
                        store.getState().modal.showSidebarContent
                    )
                        store.dispatch(setNumberOfLastReadMessage(store.getState().chat.chatMessages.length + 1));
                    else
                        store.dispatch(
                            setNumberOfUnreadMessages(
                                store.getState().chat.chatMessages.length -
                                    store.getState().chat.numberOfLastReadMessage +
                                    1
                            )
                        );
                }
                if (action.payload.cmd === "askForActivity") {
                    store.dispatch(setErrorTitle(translate("Notification.StillActive")));
                    store.dispatch(setErrorIsInfo(true));
                    store.dispatch(setLoginError(translate("Notification.ActivityMessage")));
                    store.dispatch(
                        setAdditionalErrorAcceptAction(
                            writeToSocket({
                                cmd: "resetActive",
                            })
                        )
                    );
                    store.dispatch(setModalVisibility(ERROR_BOX));
                }
            }
            if (action.type === "SET_SIDEBAR_CONTENT_VISIBILITY") {
                if (action.showSidebarContent && store.getState().modal.sidebarContentId === CHAT_LIST) {
                    store.dispatch(setNumberOfLastReadMessage("MAX"));
                    store.dispatch(setNumberOfUnreadMessages(0));
                }
            }
            if (action.type === "SHOW_WELCOME_TOUR") {
                cookies.set(`${action.participantName}:showTour`, action.show, {
                    path: "/",
                });
            }
            next(action);
        };
    };
}
