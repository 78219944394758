/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Flag from "react-flagkit";
import { connect } from "react-redux";

import {
    setSelectedCountryIndex,
    setSelectedDialoutId,
    setShowCountries,
    addDialout,
    deleteDialout,
    modifiyDialoutPhoneNumber,
} from "../../../actions";
import { dialoutStates } from "../../../constants/commonConstants";
import { isClassNotIncluded } from "../../../lib/utils";

import "../../../styles/layout.css";
import "../../modals/spinner.css";
import styles from "./dialoutEntry.module.css";

const getCountrySelect = (countryNumber, id, index, isBtn, setSelectedCountryIndexFn) => (
    <div
        key={`${countryNumber.countryCode}-${index}`}
        className="l-tab-row"
        onClick={() => setSelectedCountryIndexFn(index, id)}
        role="button"
        tabIndex="0"
    >
        <Flag country={countryNumber.countryCode} />
        <div className="l-center-xy">
            <div
                className={`${styles["c-country-number"]} ${
                    isBtn ? styles["c-country-number--btn"] : styles["c-country-number--content"]
                }`}
            >{`${!isBtn ? countryNumber.country : ""} + ${countryNumber.countryNumber}`}</div>
        </div>
        {isBtn ? (
            <div className="l-center-xy">
                <div className={styles["c-arrow-down"]} />
            </div>
        ) : null}
    </div>
);

const DialoutEntryComponent = ({
    show,
    countryNumbers,
    dialout,
    showCountries,
    showAdd,
    showDelete,
    setSelectedCountryIndexFn,
    setSelectedDialoutIdFn,
    setShowCountriesFn,
    addDialoutFn,
    deleteDialoutFn,
    modifyDialoutPhoneNumberFn,
    scrollDownFn,
}) => {
    const [event, setEvent] = useState(undefined);
    
    useEffect(() => {
        const handleClickOutside = (e) => {
            const className = e.target.getAttribute("class");
            if (
                className !== null &&
                isClassNotIncluded(className, "c-country-number--content") &&
                isClassNotIncluded(className, "c-country-number--btn") &&
                isClassNotIncluded(className, "c-arrow-down")
            )
                setShowCountriesFn(false);
        };
        if(event === undefined) setEvent(document.addEventListener("mouseup", (e) => handleClickOutside(e)))
        return () => {
            setShowCountriesFn(false);
            document.removeEventListener("mouseup", (e) => handleClickOutside(e));
            setEvent(undefined);
        };
    }, [setShowCountriesFn, event, setEvent]);

    let phoneInput = null;
    return show ? (
        <div
            className={styles["c-dialout-entry"]}
            key={dialout.dialoutId}
            onClick={() => setSelectedDialoutIdFn(dialout.dialoutId)}
            role="button"
            tabIndex="0"
        >
            <div className="l-tab-row">
                {/* dropdown btn */}
                <div
                    className={`${styles["c-dropdown"]} ${
                        dialout.callState === dialoutStates.FAIL
                            ? styles["c-dropdown--fail-border"]
                            : styles["c-dropdown--normal-border"]
                    }`}
                    onClick={() => setShowCountriesFn(!showCountries)}
                    role="button"
                    tabIndex="0"
                >
                    {getCountrySelect(
                        countryNumbers[dialout.countryIndex],
                        dialout.dialoutId,
                        dialout.countryIndex,
                        true,
                        setSelectedCountryIndexFn
                    )}
                    {showCountries ? (
                        <div className={styles["c-dropdown__content"]}>
                            {countryNumbers.map((cN, i) =>
                                getCountrySelect(cN, dialout.dialoutId, i, false, setSelectedCountryIndexFn)
                            )}
                        </div>
                    ) : null}
                </div>
                {/* input field */}
                <div className="l-tab__item-grow">
                    <input
                        ref={(node) => {
                            phoneInput = node;
                        }}
                        className={`${styles["c-input"]} ${
                            dialout.callState === dialoutStates.FAIL
                                ? styles["c-input--fail-border"]
                                : styles["c-input--normal-border"]
                        }`}
                        type="number"
                        defaultValue={dialout.phoneNumber}
                        onChange={() => modifyDialoutPhoneNumberFn(dialout.dialoutId, phoneInput.value)}
                    />
                </div>
                {/* dialout status */}
                <div
                    className={`${styles["c-dialout-status"]} ${
                        dialout.callState === dialoutStates.FAIL
                            ? styles["c-dialout-status--fail-border"]
                            : styles["c-dialout-status--normal-border"]
                    }`}
                >
                    <div className="l-center-xy l-max-size">
                        {dialout.callState === dialoutStates.SUCCESS ? (
                            <div
                                className={`${styles["c-dialout-status__state"]} ${styles["c-dialout-status__state--success"]}`}
                            />
                        ) : null}
                        {dialout.callState === dialoutStates.FAIL ? (
                            <div
                                className={`${styles["c-dialout-status__state"]} ${styles["c-dialout-status__state--fail"]}`}
                            />
                        ) : null}
                    </div>
                </div>
                {/* dialout actions */}
                {dialout.callState === dialoutStates.IN_PROGRESS ? (
                    <div className="l-center-xy">
                        <div className={styles["c-spinner"]}>
                            <div className={`${styles["c-spinner__circle"]} ${styles["c-spinner__circle--grey"]}`} />
                        </div>
                        <div className={styles["c-dialout-btn"]} />
                    </div>
                ) : (
                    <div className="l-center-xy">
                        {showDelete ? (
                            <div
                                className={`${styles["c-dialout-btn"]} ${styles["c-dialout-btn--delete"]}`}
                                onClick={() => deleteDialoutFn(dialout.dialoutId)}
                                role="button"
                                tabIndex="0"
                            />
                        ) : (
                            <div className={styles["c-dialout-btn"]} />
                        )}
                        {showAdd ? (
                            <div
                                className={`${styles["c-dialout-btn"]} ${styles["c-dialout-btn--add"]}`}
                                onClick={() => {
                                    addDialoutFn(countryNumbers);
                                    scrollDownFn();
                                }}
                                role="button"
                                tabIndex="0"
                            />
                        ) : (
                            <div className={styles["c-dialout-btn"]} />
                        )}
                    </div>
                )}
            </div>
        </div>
    ) : null;
};

DialoutEntryComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    countryNumbers: PropTypes.array.isRequired,
    dialout: PropTypes.object.isRequired,
    showCountries: PropTypes.bool.isRequired,
    showAdd: PropTypes.bool.isRequired,
    showDelete: PropTypes.bool.isRequired,
    setSelectedCountryIndexFn: PropTypes.func.isRequired,
    setSelectedDialoutIdFn: PropTypes.func.isRequired,
    setShowCountriesFn: PropTypes.func.isRequired,
    addDialoutFn: PropTypes.func.isRequired,
    deleteDialoutFn: PropTypes.func.isRequired,
    modifyDialoutPhoneNumberFn: PropTypes.func.isRequired,
    scrollDownFn: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    show: true,
    countryNumbers: state.telco.countryNumbers,
    showAdd: ownProps.showAdd,
    showDelete: ownProps.showDelete,
    dialout: ownProps.dialout,
    showCountries: state.modal.showCountries && ownProps.dialout.dialoutId === state.telco.selectedDialoutId,
});

const mapDispatchToProps = {
    setSelectedCountryIndexFn: (index, id) => (dispatch) => dispatch(setSelectedCountryIndex(index, id)),
    setSelectedDialoutIdFn: (index) => (dispatch) => dispatch(setSelectedDialoutId(index)),
    setShowCountriesFn: (show) => (dispatch) => dispatch(setShowCountries(show)),
    addDialoutFn: (dialoutNumbers) => (dispatch) => dispatch(addDialout(dialoutNumbers)),
    deleteDialoutFn: (dialoutId) => (dispatch) => dispatch(deleteDialout(dialoutId)),
    modifyDialoutPhoneNumberFn: (dialoutId, phoneNumber) => (dispatch) =>
        dispatch(modifiyDialoutPhoneNumber(dialoutId, phoneNumber)),
    scrollDownFn: () => () => {
        // c-dialouts is located in InvitationDialout
        const dialoutList = document.querySelector("[class*='c-dialouts']");
        if (dialoutList)
            setTimeout(() => {
                dialoutList.scrollTo(0, dialoutList.scrollHeight);
            }, 0);
    },
};

export default connect(mapStateToProps, mapDispatchToProps)(DialoutEntryComponent);
