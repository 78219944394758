import React, { Component } from "react";
import PropTypes from "prop-types";

import "../styles/sidebar.css";

class IconConferenceLock extends Component {
    render() {
        const { globalMute, onClick, label } = this.props;
        return (
            <span
                className={`conference-control-action__mute-inline ${
                    globalMute
                        ? "conference-control-action__mute-inline--on"
                        : "conference-control-action__mute-inline--off"
                }`}
                onClick={onClick}
                aria-label={label}
                role="button"
                tabIndex="0"
            >
                <svg
                    version="1.1"
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 16 16"
                    xmlSpace="preserve"
                >
                    <path
                        d="M8,10.9c1.6,0,2.9-1.3,2.9-2.9V2.9C10.9,1.3,9.6,0,8,0S5.1,1.3,5.1,2.9V8C5.1,9.6,6.4,10.9,8,10.9z M5.9,2.9
            c0-1.2,0.9-2.1,2.1-2.1s2.1,0.9,2.1,2.1V8c0,1.2-0.9,2.1-2.1,2.1S5.9,9.2,5.9,8V2.9z"
                    />
                    <path
                        d="M13.5,8c0-0.2-0.2-0.4-0.4-0.4S12.6,7.8,12.6,8c0,2.6-2.1,4.6-4.6,4.6s-4.6-2-4.6-4.6c0-0.2-0.2-0.4-0.4-0.4
            S2.5,7.8,2.5,8c0,2.9,2.2,5.2,5.1,5.5v1.7H6.3c-0.2,0-0.4,0.2-0.4,0.4S6.1,16,6.3,16h3.4c0.2,0,0.4-0.2,0.4-0.4s-0.2-0.4-0.4-0.4
            H8.4v-1.7C11.2,13.2,13.5,10.9,13.5,8z"
                    />
                    <path d="M8,0C6.3,0,5,1.3,5,3v5c0,1.6,1.3,3,3,3s3-1.4,3-3V3C11,1.3,9.7,0,8,0z" />
                </svg>
            </span>
        );
    }
}

IconConferenceLock.propTypes = {
    globalMute: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
};

export default IconConferenceLock;
