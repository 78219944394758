import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "./chatMessage.css";

const ChatMessageComponent = ({ outgoing, from, message, time }) => (
    <div className="chat-message-container">
        {outgoing ? <div className="chat-message-spacer" /> : null}
        <div className={`chat-message ${outgoing ? "chat-message--outgoing" : "chat-message--incoming"}`}>
            {outgoing ? null : <div className="chat-message__from">{from}</div>}
            <div className="chat-message__message">{message}</div>
            <div className="chat-message__time">{time}</div>
        </div>
        {outgoing ? null : <div className="chat-message-spacer" />}
    </div>
);

ChatMessageComponent.defaultProps = {
    from: null,
};

ChatMessageComponent.propTypes = {
    outgoing: PropTypes.bool.isRequired,
    from: PropTypes.string,
    message: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    outgoing: ownProps.c.from === state.login.participantName,
    from: ownProps.c.from === state.login.participantName ? null : ownProps.c.from,
    message: ownProps.c.chatMessage,
    time: ownProps.c.time,
});

const ChatMessage = connect(mapStateToProps)(ChatMessageComponent);

export default ChatMessage;
