import { MYWEB_BACKEND } from "../constants/commonConstants";

const url = "wss://" + MYWEB_BACKEND + "/myweb";
export const writeToSocket = (data, enqueueOnce = false) => ({
    type: "WRITE_DATA",
    payload: data,
    meta: { socket: url, enqueueOnce },
});

export const startLogin = (isOperator) => ({
    type: "START_LOGIN",
    isOperator,
    showSidebarContent: isOperator,
});

export const setPIN = (data) => ({
    type: "SET_PIN",
    pin: data,
});

export const resetLogin = () => ({
    type: "RESET_LOGIN",
    errorMsg: null,
    pin: "",
    participantName: "",
});

// TODO: rename to setErrorMessage
export const setLoginError = (errorMsg) => ({
    type: "LOGIN_ERROR",
    errorMsg,
});

export const setSidebarContentVisibility = (visible) => ({
    type: "SET_SIDEBAR_CONTENT_VISIBILITY",
    showSidebarContent: visible,
});

export const setParticipantName = (name) => ({
    type: "SET_PARTICIPANT_NAME",
    participantName: name,
});

export const setSelectedUser = (selectedUserName, selectedUID) => ({
    type: "SET_SELECTED_PARTICIPANT",
    selectedUserName,
    selectedUID,
});

export const setModalVisibility = (modalId) => ({
    type: "SET_MODAL_VISIBILITY",
    modalId: modalId === undefined ? "" : modalId,
});

export const setWebParticipantVisibility = (visible) => ({
    type: "SET_WEB_PARTICIPANT_VISIBILITY",
    showWebParticipants: visible,
});

export const setAudioParticipantVisibility = (visible) => ({
    type: "SET_BRIDGE_PARTICIPANT_VISIBILITY",
    showAudioParticipants: visible,
});

export const setMatchedParticipantVisibility = (visible) => ({
    type: "SET_MATCHED_PARTICIPANT_VISIBILITY",
    showMatchedParticipants: visible,
});

export const setWasPresenter = (wasPresenter) => ({
    type: "SET_WAS_PRESENTER",
    wasPresenter,
});

export const setDidRevokePresenter = (didRevokePresenter) => ({
    type: "SET_DID_REVOKE_PRESENTER",
    didRevokePresenter,
});

export const setMuteParticipant = (id, mute) => ({
    type: "SET_MUTE_PARTICIPANT",
    mute,
});

export const setPresenterRevoked = (presenterRevoked) => ({
    type: "SET_PRESENTER_REVOKED",
    presenterRevoked,
});

export const setFullscreenMode = (visible) => ({
    type: "SET_FULLSCREEN_MODE",
    showFullscreenMode: visible,
});

export const stopCapture = (streamName) => ({
    type: "STOP_CAPTURE",
    captureStarted: false,
    [streamName]: "stop",
});

export const setCaptureState = (
    streamName /* localStream || remoteStream */,
    streamState /* ready || stop */,
    captureStarted /* true || false */
) => ({
    type: "SET_CAPTURE_STATE",
    captureStarted,
    [streamName]: streamState,
});

export const setStreamState = (streamName /* localStream || remoteStream */, state /* ready || stop */) => ({
    type: "SET_STREAM_STATE",
    [streamName]: state,
});

export const setErrorTitle = (errorTitle) => ({
    type: "SET_ERROR_TITLE",
    errorTitle,
});

export const setErrorMessage = (errorMsg) => ({
    type: "SET_ERROR_MESSAGE",
    errorMsg,
});

export const setErrorImgCls = (errorImgCls) => ({
    type: "SET_ERROR_IMAGE_CLS",
    errorImgCls,
});

export const setErrorIsInfo = (isInfo) => ({
    type: "SET_ERROR_IS_INFO",
    isInfo,
});

export const setCaptureType = (captureType) => ({
    type: "SET_CAPTURE_TYPE",
    captureType,
});

export const setElectronIndexToCapture = (electronIndexToCapture) => ({
    type: "SET_ELECTRON_INDEX_TO_CAPTURE",
    electronIndexToCapture,
});

export const setConnectionQuality = (connectionQuality) => ({
    type: "SET_CONNECTION_QUALITY",
    connectionQuality,
});

export const setSelectedDialinNumber = (dialinNumber) => ({
    type: "SET_SELECTED_DIALIN_NUMBER",
    dialinNumber,
});

export const setSelectedDialoutNumber = (dialoutNumber) => ({
    type: "SET_SELECTED_DIALOUT_NUMBER",
    dialoutNumber,
});

export const setSelectedCountryNumber = (countryNumber) => ({
    type: "SET_SELECTED_COUNTRY_NUMBER",
    countryNumber,
});

export const setSourceModalId = (sourceModalId) => ({
    type: "SET_SOURCE_MODAL_ID",
    sourceModalId,
});

export const setVoipConnectionStatus = (connected) => ({
    type: "SET_VOIP_CONNECTION_STATUS",
    connected,
});

export const setVoipMuteStatus = (mute) => ({
    type: "SET_VOIP_MUTE_STATUS",
    mute,
});

export const setSidebarContentId = (sidebarContentId) => ({
    type: "SET_SIDEBAR_CONTENT_ID",
    sidebarContentId,
});

// screenShareMiddleware
export const startSharing = (sessionId, mediaSource) => ({
    type: "START_SHARING",
    sessionId,
    mediaSource,
});

export const stopSharing = (sessionId) => ({
    type: "STOP_SHARING",
    sessionId,
});

export const getElectronuserMedia = (electronIndexToCapture) => ({
    type: "GET_ELECTRON_USER_MEDIA",
    electronIndexToCapture,
});

export const setElectronSources = (electronSources) => ({
    type: "SET_ELECTRON_SOURCES",
    electronSources,
});

// voipMiddleware
export const voipHangup = () => ({
    type: "VOIP_HANGUP",
});

export const voipToggleMute = () => ({
    type: "VOIP_TOGGLE_MUTE",
});

export const resetSettings = () => ({
    type: "RESET_SETTINGS",
});

export const setNumberOfLastReadMessage = (numberOfLastReadMessage) => ({
    type: "SET_NUMBER_OF_LAST_READ_MESSAGE",
    numberOfLastReadMessage,
});

export const setNumberOfUnreadMessages = (numberOfUnreadMessages) => ({
    type: "SET_NUMBER_OF_UNREAD_MESSAGES",
    numberOfUnreadMessages,
});

export const setChatMessageCharsLeft = (charsLeft) => ({
    type: "SET_CHAT_MESSAGE_CHARS_LEFT",
    charsLeft,
});

export const incrementTourIndex = () => ({
    type: "INCREMENT_TOUR_INDEX",
});

export const decrementTourIndex = () => ({
    type: "DECREMENT_TOUR_INDEX",
});

export const setTourIndex = (tourIndex) => ({
    type: "SET_TOUR_INDEX",
    tourIndex,
});

export const setAdditionalErrorAcceptAction = (actn) => ({
    type: "SET_ADDITIONAL_ERROR_ACCEPT_ACTION",
    actn,
});
export const setSelectedButton = (selectedButton) => ({
    type: "SET_SELECTED_BUTTON",
    selectedButton,
});

export const setModalContent = (content) => ({
    type: "SET_MODAL_CONTENT",
    content,
});

export const showWelcomeTour = (show, participantName) => ({
    type: "SHOW_WELCOME_TOUR",
    show,
    participantName,
});

export const setFeedbackSent = (feedbackSent) => ({
    type: "SET_FEEDBACK_SENT",
    feedbackSent,
});

export const setWelcomeTourFromLogin = (fromLogin) => ({
    type: "SET_WELCOME_TOUR_FROM_LOGIN",
    fromLogin,
});

export const setFeatureTextIndex = (featureTextIndex) => ({
    type: "SET_FEATURE_TEXT_INDEX",
    featureTextIndex,
});

export const setInvitationMail = (invitationMail) => ({
    type: "SET_INVITATION_MAIL",
    invitationMail,
});

export const setSelectedCountryIndex = (selectedCountryIndex, dialoutId) => ({
    type: "SET_SELECTED_COUNTRY_INDEX",
    selectedCountryIndex,
    dialoutId,
});

export const setSelectedDialoutId = (selectedDialoutId) => ({
    type: "SET_SELECTED_DIALOUT_ID",
    selectedDialoutId,
});

export const setShowCountries = (showCountries) => ({
    type: "SET_SHOW_COUNTRIES",
    showCountries,
});

export const addDialout = (countryNumbers) => ({
    type: "ADD_DIALOUT",
    countryNumbers,
});

export const deleteDialout = (dialoutId) => ({
    type: "DELETE_DIALOUT",
    dialoutId,
});

export const modifiyDialoutPhoneNumber = (dialoutId, phoneNumber) => ({
    type: "MODIFY_DIALOUT_PHONENUMBER",
    dialoutId,
    phoneNumber,
});

export const setStartDialout = (startDialout) => ({
    type: "SET_START_DIALOUT",
    startDialout,
});

export const setDialoutCallState = (dialoutId, callState) => ({
    type: "SET_DIALOUT_CALL_STATE",
    dialoutId,
    callState,
});

export const setPinVisibility = (pinShown) => ({
    type: "SHOW_PIN",
    pinShown,
});

export const setTurnServer = (turnServer) => ({
    type: "SET_TURN_SERVER",
    turnServer,
});

export const setReconnecting = (reconnecting) => ({
    type: "SET_RECONNECTING",
    reconnecting,
});
