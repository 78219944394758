import { connect } from "react-redux";

import MessageWithCheckbox from "../../components/modal/MessageWithCheckbox";

const mapStateToProps = (state, ownProps) => ({
    switchboxId: ownProps.switchboxId,
    message: ownProps.message,
    isChecked: ownProps.isChecked,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    toggleChecked: () => {
        ownProps.toggleChecked(dispatch);
    },
});

const SimpleMessageWithCheckbox = connect(mapStateToProps, mapDispatchToProps)(MessageWithCheckbox);

export default SimpleMessageWithCheckbox;
