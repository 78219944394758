import React from "react";
import { connect } from "react-redux";

import { setSelectedDialinNumber } from "../../../actions";
import SelectContent from "../../../components/modal/SelectContent";

const selectOnChange = (e) => (dispatch, getState) => {
    const { options } = e.target;
    for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
            dispatch(setSelectedDialinNumber(getState().telco.dialinNumbers[i].number));
            break;
        }
    }
};

const mapStateToProps = (state, ownProps) => ({
    show: ownProps.show,
    selectLabel: ownProps.selectLabel,
    selectContent: state.telco.dialinNumbers.map((part) => (
        <option key={part.location + "-" + part.number}>{part.location + " (" + part.language + ")"}</option>
    )),
});

const mapDispatchToProps = {
    selectOnChange,
};

const SelectNumbers = connect(mapStateToProps, mapDispatchToProps)(SelectContent);

export default SelectNumbers;
