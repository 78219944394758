import { ActionTypes } from "redux-mw-ws";

export const participantsInitialState = {
    selectedUserName: "",
    selectedUID: "",
    presenter: "",
    participants: [],
    web: [],
    audio: [],
    matched: [],
};

const _handleParticipants = (state, payload) => {
    const ret = { ...state };
    const data = { ...payload };

    const web = data.value.filter((w) => w.type === "web").map((w) => w);
    const bridge = data.value.filter((b) => b.type === "bridge").map((b) => b);
    const matched = data.value.filter((m) => m.type === "matched").map((m) => m);

    if (web) ret.web = web;
    if (bridge) ret.audio = bridge;
    if (matched) ret.matched = matched;
    ret.participants = data.value;
    return ret;
};

const participants = (state = participantsInitialState, action) => {
    let a = null;
    switch (action.type) {
        case ActionTypes.RECEIVED_WEBSOCKET_DATA:
            if (action.payload.cmd === "newPresenter") {
                return {
                    ...state,
                    presenter: action.payload.presenter,
                };
            }
            if (action.payload.cmd === "participants") {
                return _handleParticipants(state, action.payload);
            }
            return state;
        case "SET_SELECTED_PARTICIPANT":
            a = { ...action };
            delete a.type;
            return { ...state, ...a };
        default:
            return state;
    }
};

export default participants;
