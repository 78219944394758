import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { translate, link } from "../../lib/Translator";
import { getBrandingType, toTitleCase } from "../../lib/utils";

import "./helpButton.css";
import "../../styles/main.css";

const HelpButtonComponent = ({ href, tooltiptext }) => (
    <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onMouseDown={(e) => {
            const eventObject = document.createEvent("MouseEvents");
            eventObject.initEvent("click", true, false);
            e.target.dispatchEvent(eventObject);
        }}
    >
        <div className="c-help tooltip">
            <div className="c-help-symbol" />
            <div className="tooltiptext tooltiptext--left tooltip__text--help-button-adjustment">{tooltiptext}</div>
        </div>
    </a>
);

HelpButtonComponent.propTypes = {
    href: PropTypes.string.isRequired,
    tooltiptext: PropTypes.string.isRequired,
};

const mapStateToProps = () => ({
    href: link("Help." + toTitleCase(getBrandingType())),
    tooltiptext: translate("Link.Help"),
});

const HelpButton = connect(mapStateToProps)(HelpButtonComponent);

export default HelpButton;
