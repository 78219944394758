import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "./chatListContent.css";
import ChatMessage from "./ChatMessage";

const updateScroll = () => {
    const chatList = document.getElementsByClassName("chat-list-content")[0];
    if (chatList) chatList.scrollTop = chatList.scrollHeight;
};

const ChatListContentComponent = ({ chatMessages }) => {
    useEffect(() => {
        updateScroll();
    });
    return (
        <div className="chat-list-content">
            {chatMessages.map((c) => (
                <ChatMessage key={c.id} c={c} />
            ))}
        </div>
    );
};

ChatListContentComponent.propTypes = {
    chatMessages: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    chatMessages: state.chat.chatMessages,
});

export default connect(mapStateToProps)(ChatListContentComponent);
